import React, { Component } from "react";
import ReactFlexyTable from "react-flexy-table";
import "react-flexy-table/dist/index.css";
import { getFileStatus } from "../../utils/fileStatus";
import { callApi } from "../../utils/api";
interface AllProps {
    reports: any;
    getBranch: any;
    redirectToDashboard?: any;
}
export default class RectificationReportTable extends Component<AllProps> {
    state = {
        users: [],
    }

    componentDidMount() {
        this.getUsers();

    }
    getUsers = () => {
        callApi("GET", "user_get.php")
            .then((res) => res.data)
            .then((response) => {
                this.setState({ users: response.data });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    //   getNameById(id) {
    //     console.log("get retification id and name",id);

    //     const { users }: any = this.state;
    //     for (let i = 0; i < users.length; i++) {
    //       if (users[i].id === id) {
    //         return users[i].name;
    //       }
    //     }
    //     return null;
    //   }

    getNameById(id) {
        console.log("get rectification id:", id);
        console.log("reportsreports--->", this.props.reports);
        const { users }: any = this.state;
        // console.log("Current users state:", users);
        for (let i = 0; i < users.length; i++) {
            if (users[i].id === id) {
                return users[i].name;
            }
        }
        console.warn("No user found with ID:", id);
        return "";
    }

    render() {
        const { reports = [], redirectToDashboard = () => { } } = this.props;
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1;
        const day = currentDate.getDate();
        const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
        return (
            <div className="">
                <div className="dt-responsive table-responsive" style={{ overflow: 'auto' }}>
                    <table style={{ overflowX: "scroll", width: "140%" }} id="processeodTable" className="table table-striped table-bordered nowrap">
                        <thead>
                            <tr>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>Sl.No</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>File No</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>Property Owner Name</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>Borrower Name</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>File Status</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>Bank Name</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>Bank Branch Name</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>Law Hands Branch</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>Rectification Officer Name</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>Rectification Status</th>

                                {/* first rectification */}
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>1st Rectification Status</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>1st Rectification Reason</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>1st Rectification Received Date</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>1st Rectification Picked Date</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>1st Rectification Querie Raised Date</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>1st Rectification Completed Date</th>
                                {/* secont rectification */}
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>2nd Rectification Status</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>2nd Rectification Reason</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>2nd Rectification Received Date</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>2nd Rectification Picked Date</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>2nd Rectification Querie Raised Date</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>2nd Rectification Completed Date</th>
                                {/* third rectification */}
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>3rd Rectification Status</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>3rd Rectification Reason</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>3rd Rectification Received Date</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>3rd Rectification Picked Date</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>3rd Rectification Querie Raised Date</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>3rd Rectification Completed Date</th>
                                {/* fouth rectification */}
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>4th Rectification Status</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>4th Rectification Reason</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>4th Rectification Received Date</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>4th Rectification Picked Date</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>4th Rectification Querie Raised Date</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>4th Rectification Completed Date</th>
                                {/* fifth rectification */}
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>5th Rectification Status</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>5th Rectification Reason</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>5th Rectification Received Date</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>5th Rectification Picked Date</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>5th Rectification Querie Raised Date</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>5th Rectification Completed Date</th>
                                {/* sixth rectification */}
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>6th Rectification Status</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>6th Rectification Reason</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>6th Rectification Received Date</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>6th Rectification Picked Date</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>6th Rectification Querie Raised Date</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>6th Rectification Completed Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reports && reports.length
                                ? reports.map(
                                    (query: any, index: number) => {
                                        const rectifyData = query.rectify_data;
                                        let rectifyById = "";

                                        if (rectifyData && rectifyData.length > 0) {
                                            rectifyById = rectifyData[0].rectify_by;
                                        }
                                        return (
                                            <tr className="query-table-row cursor-pointer" key={index}>
                                                <td className="text-align-center">{index + 1}</td>
                                                <td className="click">
                                                    {query.file_name ? query.file_name : ""}
                                                </td>
                                                <td className="click">
                                                    {query.owner_name}
                                                </td>
                                                <td className="click">
                                                    {query.borrower_name}
                                                </td>
                                                <td className="click">
                                                    {getFileStatus(query.file_status)}
                                                </td>
                                                <td className="click">
                                                    {query.bank_name}
                                                </td>


                                                <td className="click">
                                                    {query.bank_branch_name}
                                                </td>
                                                <td className="click">
                                                    {query.lawhands_branch_name}
                                                </td>
                                                <td className="click">
                                                    {/* {this.getNameById(rectifyById)} */}
                                                    {query.rectify_by}
                                                </td>
                                                <td className="click">
                                                    {query.rectify_status}
                                                </td>
                                                {/* first rectification */}
                                                <td className="click" style={{ width: "25%" }}>
                                                    {query.first_rectify_data && query.first_rectify_data.length > 0 ? (query.first_rectify_data.map((list: any, index: any) => {
                                                        return (
                                                            <p key={index}>{index + 1 + "] "}{list.rectification_status}</p>
                                                        );
                                                    })) : (
                                                        <p>-</p>
                                                    )}
                                                </td>
                                                <td className="click" style={{ width: "25%" }}>
                                                    {query.first_rectify_data && query.first_rectify_data.length > 0 ? (query.first_rectify_data.map((list: any, index: any) => {
                                                        return (
                                                            <p style={{ width: "330px" }} key={index}>{index + 1 + "] "}{list.reason}</p>
                                                        );
                                                    })) : (
                                                        <p>-</p>
                                                    )}
                                                </td>
                                                <td className="click" style={{ width: "25%" }}>
                                                    {query.first_rectify_data && query.first_rectify_data.length > 0 ? (query.first_rectify_data.map((list: any, index: any) => {
                                                        return (
                                                            <p key={index}>{list.rectify_received_dt ? <>{index + 1 + "] "}{list.rectify_received_dt}</> : "-"} </p>
                                                        );
                                                    })) : (
                                                        <p>-</p>
                                                    )}
                                                </td>
                                                <td className="click" style={{ width: "25%" }}>
                                                    {query.first_rectify_data && query.first_rectify_data.length > 0 ? (query.first_rectify_data.map((list: any, index: any) => {
                                                        return (
                                                            <p key={index}>{list.rectify_picked_dt ? <>{index + 1 + "] "}{list.rectify_picked_dt}</> : "-"} </p>
                                                        );
                                                    })) : (
                                                        <p>-</p>
                                                    )}
                                                </td>
                                                <td className="click" style={{ width: "25%" }}>
                                                    {query.first_rectify_data && query.first_rectify_data.length > 0 ? (query.first_rectify_data.map((list: any, index: any) => {
                                                        return (
                                                            <p key={index}>{list.rectify_querie_raised_dt ? <>{index + 1 + "] "}{list.rectify_querie_raised_dt}</> : "-"} </p>
                                                        );
                                                    })) : (
                                                        <p>-</p>
                                                    )}
                                                </td>
                                                <td className="click" style={{ width: "25%" }}>
                                                    {query.first_rectify_data && query.first_rectify_data.length > 0 ? (query.first_rectify_data.map((list: any, index: any) => {
                                                        return (
                                                            <p key={index}> {list.rectify_completed_dt ? <>{index + 1 + "] "}{list.rectify_completed_dt}</> : "-"} </p>
                                                        );
                                                    })) : (
                                                        <p>-</p>
                                                    )}
                                                </td>
                                                {/* second rectification */}
                                                <td className="click" style={{ width: "25%" }}>
                                                    {query.second_rectify_data && query.second_rectify_data.length > 0 ? (query.second_rectify_data.map((list: any, index: any) => {
                                                        return (
                                                            <p key={index}>{index + 1 + "] "}{list.rectification_status}</p>
                                                        );
                                                    })) : (
                                                        <p>-</p>
                                                    )}
                                                </td>
                                                <td className="click" style={{ width: "25%" }}>
                                                    {query.second_rectify_data && query.second_rectify_data.length > 0 ? (query.second_rectify_data.map((list: any, index: any) => {
                                                        return (
                                                            <p key={index} style={{ width: "330px" }}>{index + 1 + "] "}{list.reason}</p>
                                                        );
                                                    })) : (
                                                        <p>-</p>
                                                    )}
                                                </td>
                                                <td className="click" style={{ width: "25%" }}>
                                                    {query.second_rectify_data && query.second_rectify_data.length > 0 ? (query.second_rectify_data.map((list: any, index: any) => {
                                                        return (
                                                            <p key={index}>{list.rectify_received_dt ? <>{index + 1 + "] "}{list.rectify_received_dt}</> : "-"} </p>
                                                        );
                                                    })) : (
                                                        <p>-</p>
                                                    )}
                                                </td>
                                                <td className="click" style={{ width: "25%" }}>
                                                    {query.second_rectify_data && query.second_rectify_data.length > 0 ? (query.second_rectify_data.map((list: any, index: any) => {
                                                        return (
                                                            <p key={index}>{list.rectify_picked_dt ? <>{index + 1 + "] "}{list.rectify_picked_dt}</> : "-"} </p>
                                                        );
                                                    })) : (
                                                        <p>-</p>
                                                    )}
                                                </td>
                                                <td className="click" style={{ width: "25%" }}>
                                                    {query.second_rectify_data && query.second_rectify_data.length > 0 ? (query.second_rectify_data.map((list: any, index: any) => {
                                                        return (
                                                            <p key={index}>{list.rectify_querie_raised_dt ? <>{index + 1 + "] "}{list.rectify_querie_raised_dt}</> : "-"} </p>
                                                        );
                                                    })) : (
                                                        <p>-</p>
                                                    )}
                                                </td>
                                                <td className="click" style={{ width: "25%" }}>
                                                    {query.second_rectify_data && query.second_rectify_data.length > 0 ? (query.second_rectify_data.map((list: any, index: any) => {
                                                        return (
                                                            <p key={index}> {list.rectify_completed_dt ? <>{index + 1 + "] "}{list.rectify_completed_dt}</> : "-"} </p>
                                                        );
                                                    })) : (
                                                        <p>-</p>
                                                    )}
                                                </td>
                                                {/* third rectification */}
                                                <td className="click" style={{ width: "25%" }}>
                                                    {query.third_rectify_data && query.third_rectify_data.length > 0 ? (query.third_rectify_data.map((list: any, index: any) => {
                                                        return (
                                                            <p key={index}>{index + 1 + "] "}{list.rectification_status}</p>
                                                        );
                                                    })) : (
                                                        <p>-</p>
                                                    )}
                                                </td>
                                                <td className="click" style={{ width: "25%" }}>
                                                    {query.third_rectify_data && query.third_rectify_data.length > 0 ? (query.third_rectify_data.map((list: any, index: any) => {
                                                        return (
                                                            <p key={index} style={{ width: "330px" }}>{index + 1 + "] "}{list.reason}</p>
                                                        );
                                                    })) : (
                                                        <p>-</p>
                                                    )}
                                                </td>
                                                <td className="click" style={{ width: "25%" }}>
                                                    {query.third_rectify_data && query.third_rectify_data.length > 0 ? (query.third_rectify_data.map((list: any, index: any) => {
                                                        return (
                                                            <p key={index}>{list.rectify_received_dt ? <>{index + 1 + "] "}{list.rectify_received_dt}</> : "-"} </p>
                                                        );
                                                    })) : (
                                                        <p>-</p>
                                                    )}
                                                </td>
                                                <td className="click" style={{ width: "25%" }}>
                                                    {query.third_rectify_data && query.third_rectify_data.length > 0 ? (query.third_rectify_data.map((list: any, index: any) => {
                                                        return (
                                                            <p key={index}>{list.rectify_picked_dt ? <>{index + 1 + "] "}{list.rectify_picked_dt}</> : "-"} </p>
                                                        );
                                                    })) : (
                                                        <p>-</p>
                                                    )}
                                                </td>
                                                <td className="click" style={{ width: "25%" }}>
                                                    {query.third_rectify_data && query.third_rectify_data.length > 0 ? (query.third_rectify_data.map((list: any, index: any) => {
                                                        return (
                                                            <p key={index}>{list.rectify_querie_raised_dt ? <>{index + 1 + "] "}{list.rectify_querie_raised_dt}</> : "-"} </p>
                                                        );
                                                    })) : (
                                                        <p>-</p>
                                                    )}
                                                </td>
                                                <td className="click" style={{ width: "25%" }}>
                                                    {query.third_rectify_data && query.third_rectify_data.length > 0 ? (query.third_rectify_data.map((list: any, index: any) => {
                                                        return (
                                                            <p key={index}> {list.rectify_completed_dt ? <>{index + 1 + "] "}{list.rectify_completed_dt}</> : "-"} </p>
                                                        );
                                                    })) : (
                                                        <p>-</p>
                                                    )}
                                                </td>
                                                {/* fourth rectification */}
                                                <td className="click" style={{ width: "25%" }}>
                                                    {query.fourth_rectify_data && query.fourth_rectify_data.length > 0 ? (query.fourth_rectify_data.map((list: any, index: any) => {
                                                        return (
                                                            <p key={index}>{index + 1 + "] "}{list.rectification_status}</p>
                                                        );
                                                    })) : (
                                                        <p>-</p>
                                                    )}
                                                </td>
                                                <td className="click" style={{ width: "25%" }}>
                                                    {query.fourth_rectify_data && query.fourth_rectify_data.length > 0 ? (query.fourth_rectify_data.map((list: any, index: any) => {
                                                        return (
                                                            <p key={index} style={{ width: "330px" }}>{index + 1 + "] "}{list.reason}</p>
                                                        );
                                                    })) : (
                                                        <p>-</p>
                                                    )}
                                                </td>
                                                <td className="click" style={{ width: "25%" }}>
                                                    {query.fourth_rectify_data && query.fourth_rectify_data.length > 0 ? (query.fourth_rectify_data.map((list: any, index: any) => {
                                                        return (
                                                            <p key={index}>{list.rectify_received_dt ? <>{index + 1 + "] "}{list.rectify_received_dt}</> : "-"} </p>
                                                        );
                                                    })) : (
                                                        <p>-</p>
                                                    )}
                                                </td>
                                                <td className="click" style={{ width: "25%" }}>
                                                    {query.fourth_rectify_data && query.fourth_rectify_data.length > 0 ? (query.fourth_rectify_data.map((list: any, index: any) => {
                                                        return (
                                                            <p key={index}>{list.rectify_picked_dt ? <>{index + 1 + "] "}{list.rectify_picked_dt}</> : "-"} </p>
                                                        );
                                                    })) : (
                                                        <p>-</p>
                                                    )}
                                                </td>
                                                <td className="click" style={{ width: "25%" }}>
                                                    {query.fourth_rectify_data && query.fourth_rectify_data.length > 0 ? (query.fourth_rectify_data.map((list: any, index: any) => {
                                                        return (
                                                            <p key={index}>{list.rectify_querie_raised_dt ? <>{index + 1 + "] "}{list.rectify_querie_raised_dt}</> : "-"} </p>
                                                        );
                                                    })) : (
                                                        <p>-</p>
                                                    )}
                                                </td>
                                                <td className="click" style={{ width: "25%" }}>
                                                    {query.fourth_rectify_data && query.fourth_rectify_data.length > 0 ? (query.fourth_rectify_data.map((list: any, index: any) => {
                                                        return (
                                                            <p key={index}> {list.rectify_completed_dt ? <>{index + 1 + "] "}{list.rectify_completed_dt}</> : "-"} </p>
                                                        );
                                                    })) : (
                                                        <p>-</p>
                                                    )}
                                                </td>
                                                {/* fifth rectification */}
                                                <td className="click" style={{ width: "25%" }}>
                                                    {query.fifth_rectify_data && query.fifth_rectify_data.length > 0 ? (query.fifth_rectify_data.map((list: any, index: any) => {
                                                        return (
                                                            <p key={index}>{index + 1 + "] "}{list.rectification_status}</p>
                                                        );
                                                    })) : (
                                                        <p>-</p>
                                                    )}
                                                </td>
                                                <td className="click" style={{ width: "25%" }}>
                                                    {query.fifth_rectify_data && query.fifth_rectify_data.length > 0 ? (query.fifth_rectify_data.map((list: any, index: any) => {
                                                        return (
                                                            <p key={index} style={{ width: "330px" }}>{index + 1 + "] "}{list.reason}</p>
                                                        );
                                                    })) : (
                                                        <p>-</p>
                                                    )}
                                                </td>
                                                <td className="click" style={{ width: "25%" }}>
                                                    {query.fifth_rectify_data && query.fifth_rectify_data.length > 0 ? (query.fifth_rectify_data.map((list: any, index: any) => {
                                                        return (
                                                            <p key={index}>{list.rectify_received_dt ? <>{index + 1 + "] "}{list.rectify_received_dt}</> : "-"} </p>
                                                        );
                                                    })) : (
                                                        <p>-</p>
                                                    )}
                                                </td>
                                                <td className="click" style={{ width: "25%" }}>
                                                    {query.fifth_rectify_data && query.fifth_rectify_data.length > 0 ? (query.fifth_rectify_data.map((list: any, index: any) => {
                                                        return (
                                                            <p key={index}>{list.rectify_picked_dt ? <>{index + 1 + "] "}{list.rectify_picked_dt}</> : "-"} </p>
                                                        );
                                                    })) : (
                                                        <p>-</p>
                                                    )}
                                                </td>
                                                <td className="click" style={{ width: "25%" }}>
                                                    {query.fifth_rectify_data && query.fifth_rectify_data.length > 0 ? (query.fifth_rectify_data.map((list: any, index: any) => {
                                                        return (
                                                            <p key={index}>{list.rectify_querie_raised_dt ? <>{index + 1 + "] "}{list.rectify_querie_raised_dt}</> : "-"} </p>
                                                        );
                                                    })) : (
                                                        <p>-</p>
                                                    )}
                                                </td>
                                                <td className="click" style={{ width: "25%" }}>
                                                    {query.fifth_rectify_data && query.fifth_rectify_data.length > 0 ? (query.fifth_rectify_data.map((list: any, index: any) => {
                                                        return (
                                                            <p key={index}> {list.rectify_completed_dt ? <>{index + 1 + "] "}{list.rectify_completed_dt}</> : "-"} </p>
                                                        );
                                                    })) : (
                                                        <p>-</p>
                                                    )}
                                                </td>
                                                {/* sixth rectification */}
                                                <td className="click" style={{ width: "25%" }}>
                                                    {query.sixth_rectify_data && query.sixth_rectify_data.length > 0 ? (query.sixth_rectify_data.map((list: any, index: any) => {
                                                        return (
                                                            <p key={index}>{index + 1 + "] "}{list.rectification_status}</p>
                                                        );
                                                    })) : (
                                                        <p>-</p>
                                                    )}
                                                </td>
                                                <td className="click" style={{ width: "25%" }}>
                                                    {query.sixth_rectify_data && query.sixth_rectify_data.length > 0 ? (query.sixth_rectify_data.map((list: any, index: any) => {
                                                        return (
                                                            <p key={index} style={{ width: "330px" }}>{index + 1 + "] "}{list.reason}</p>
                                                        );
                                                    })) : (
                                                        <p>-</p>
                                                    )}
                                                </td>
                                                <td className="click" style={{ width: "25%" }}>
                                                    {query.sixth_rectify_data && query.sixth_rectify_data.length > 0 ? (query.sixth_rectify_data.map((list: any, index: any) => {
                                                        return (
                                                            <p key={index}>{list.rectify_received_dt ? <>{index + 1 + "] "}{list.rectify_received_dt}</> : "-"} </p>
                                                        );
                                                    })) : (
                                                        <p>-</p>
                                                    )}
                                                </td>
                                                <td className="click" style={{ width: "25%" }}>
                                                    {query.sixth_rectify_data && query.sixth_rectify_data.length > 0 ? (query.sixth_rectify_data.map((list: any, index: any) => {
                                                        return (
                                                            <p key={index}>{list.rectify_picked_dt ? <>{index + 1 + "] "}{list.rectify_picked_dt}</> : "-"} </p>
                                                        );
                                                    })) : (
                                                        <p>-</p>
                                                    )}
                                                </td>
                                                <td className="click" style={{ width: "25%" }}>
                                                    {query.sixth_rectify_data && query.sixth_rectify_data.length > 0 ? (query.sixth_rectify_data.map((list: any, index: any) => {
                                                        return (
                                                            <p key={index}>{list.rectify_querie_raised_dt ? <>{index + 1 + "] "}{list.rectify_querie_raised_dt}</> : "-"} </p>
                                                        );
                                                    })) : (
                                                        <p>-</p>
                                                    )}
                                                </td>
                                                <td className="click" style={{ width: "25%" }}>
                                                    {query.sixth_rectify_data && query.sixth_rectify_data.length > 0 ? (query.sixth_rectify_data.map((list: any, index: any) => {
                                                        return (
                                                            <p key={index}> {list.rectify_completed_dt ? <>{index + 1 + "] "}{list.rectify_completed_dt}</> : "-"} </p>
                                                        );
                                                    })) : (
                                                        <p>-</p>
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    }
                                ) : ""}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}