import * as React from "react";
import ReactFlexyTable from "react-flexy-table";
import "react-flexy-table/dist/index.css";
import "../file/ReactFlexyTable.css";
import { callApi } from "../../utils/api";
import VerifyFileModal from "../file/VerifyFileModal";
import {
    ToastsContainer,
    ToastsContainerPosition,
    ToastsStore,
} from "react-toasts";
import moment from "moment";
import { isEmpty, isNaN, isNumber } from "lodash";
import {
    Select,
    MenuItem
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import ExcelJS from "exceljs";
interface AllProps {
    history: any;
}
declare var $: any;
class UnHoldReport extends React.Component<AllProps> {
    limitList = [20, 40, 60]
    state = {
        selectVal: false,
        outreason: "",
        files: [],
        page: 1,
        limit: 20,
        totalDataCount: 0,
        rowCount: this.limitList[0],
        currentPage: 1,
        fileToBeVerified: 0,
        isFileRefresh: false,
        autoAssign: [
            {
                id: "1",
                module_name: "Auto Assign",
                status: true,
            },
        ],
        isLoading: false,
        unholdFile: "",
        unholdVal: false,
        unholdreason: "",
        file_Id: "",
        holdDetails: [],
        isDownloading: false,
    };
    componentDidMount() {
        this.getFiles();
        this.getOutTime();
        this.ouTime();
        // this.getAutoAssignStatus();

        const status: any = sessionStorage.getItem("dashboard_status");
        if (status == 2) {
            this.sendBalanceMin();
            console.log("status 2");
        } else {
            console.log("not status 2");
        }
    }

    sendBalanceMin() {
        const fileid1: any = sessionStorage.getItem("file_Id");
        let updatedRemainingTime: any = sessionStorage.getItem(
            "updatedRemainingTime"
        );
        let updatedtime = Math.round(updatedRemainingTime);
        const status: any = sessionStorage.getItem("dashboard_status");
        let obj = {
            file_id: JSON.parse(fileid1),
            balance_min: updatedtime,
            dashboard_status: status,
        };
        callApi("POST", "process_eod_insert.php", obj)
            .then((res) => res.data)
            .then((response) => {
                sessionStorage.removeItem("updatedRemainingTime");
            })
            .catch((err) => {
                console.log(err);
            });
    }

    fileout = (fileid1) => {
        let fileId = JSON.parse(fileid1);
        const loginuser: any = sessionStorage.getItem("user1");
        let req = {
            id: fileId,
            reason: "File Out",
            created_by: JSON.parse(loginuser),
        };
        callApi("POST", "time_update.php", req)
            .then((res) => res.data)
            .then((response) => {
                sessionStorage.removeItem("currentfileId");
                localStorage.removeItem("query_raised_status");
                localStorage.removeItem("file_completed_status");
                // sessionStorage.removeItem("takenfile_id");
            });
    };

    getAutoAssignStatus = () => {
        callApi("GET", "module_option_select.php", "")
            .then((res) => res.data)
            .then((result) => {
                this.setState({ autoAssign: result.responseJson.data });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    previousPop = () => {
        $("#out_reason").modal("show");
        $("#another-reason").modal("hide");
    };
    BacktoFile = () => {
        $("#out_reason").modal("hide");
        const fileid1: any = sessionStorage.getItem("currentfileId");

        this.props.history.push("/file-dashboard/" + JSON.parse(fileid1));
    };
    getOutTime = () => {
        let date = new Date();
        let stratTime = date.getTime();
        localStorage.setItem("startTime", JSON.stringify(stratTime));
        let outDate = date.toLocaleDateString();
        localStorage.setItem("OutDateGet", JSON.stringify(outDate));
        var Railway = date.getMinutes();
        if (Railway < 10) {
            var TotalMinutes = "0" + Railway;
            let ouTtime = date.getHours() + ":" + TotalMinutes;
            localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
        } else {
            let ouTtime: any = date.getHours() + ":" + date.getMinutes();
            localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
        }
    };
    ouTime = () => {
        const getfileId: any = sessionStorage.getItem("currentfileId");
        console.log("54", JSON.parse(getfileId));
        console.log("32", getfileId);
        if (sessionStorage.getItem("currentfileId")) {
            $("#out_reason").modal("show");
        }
    };

    outimeGet = () => {
        this.setState({ isLoading: true });
        const fileid1: any = sessionStorage.getItem("currentfileId");
        const userid1: any = sessionStorage.getItem("user1");
        const inreason: any = localStorage.getItem("in_reason");
        const intime: any = localStorage.getItem("IntimeGet");
        console.log("70", intime);
        const outtime: any = localStorage.getItem("outtimeGet");
        console.log("73", outtime);
        const outdate: any = localStorage.getItem("OutDateGet");
        const secondsSpent = moment(outtime, "HH:mm").diff(
            moment(intime, "HH:mm"),
            "seconds"
        );
        console.log("Second Spn", secondsSpent);
        let updatedRemainingTime: any = sessionStorage.getItem(
            "updatedRemainingTime"
        );
        let updatedtime = Math.round(updatedRemainingTime);
        const status: any = sessionStorage.getItem("dashboard_status");
        if (this.state.outreason == "") {
            this.setState({ selectVal: true });
        } else {
            let obj = {
                file_id: JSON.parse(fileid1),
                user_id: JSON.parse(userid1),
                in_reason: JSON.parse(inreason),
                out_reason: this.state.outreason,
                intime: JSON.parse(intime),
                outtime: JSON.parse(outtime),
                insert_date: JSON.parse(outdate),
                total_seconds: secondsSpent,
                balance_min: updatedtime,
                dashboard_status: status,
            };

            console.log("72", obj);
            callApi("POST", "process_eod_insert.php", obj)
                .then((res) => res.data)
                .then((response) => {
                    this.setState({ isLoading: false });
                    sessionStorage.removeItem("updatedRemainingTime");
                    console.log("76", response);
                    sessionStorage.removeItem("updatedRemainingTime");
                    sessionStorage.removeItem("currentfileId");
                    localStorage.removeItem("in_reason");
                    localStorage.removeItem("outreason");
                    localStorage.removeItem("IntimeGet");
                    localStorage.removeItem("OutDateGet");
                    $("#out_reason").modal("hide");
                    $("#another-reason").modal("hide");
                    ToastsStore.success("Your Reason Saved Successfully");

                    let process_officerId: any =
                        sessionStorage.getItem("process_office_id");
                    let process_officer_id = JSON.parse(process_officerId);
                    const userid1: any = sessionStorage.getItem("user1");
                    let user_id = JSON.parse(userid1);
                    const take_file: any = sessionStorage.getItem("takenfile_id");
                    const user: any = sessionStorage.getItem("user");
                    const query_status: any = localStorage.getItem("query_raised_status");
                    const file_completed = localStorage.getItem("file_completed_status");
                    if (
                        process_officer_id == user_id &&
                        take_file != "" &&
                        take_file === fileid1 &&
                        query_status == "false" &&
                        file_completed == "false"
                    ) {
                        this.fileout(fileid1);
                    } else {
                        sessionStorage.removeItem("currentfileId");
                        localStorage.removeItem("query_raised_status");
                        localStorage.removeItem("file_completed_status");
                    }
                })
                .catch((err) => {
                    this.setState({ isLoading: false });
                    console.log(err);
                    ToastsStore.error("Your Reason Not Stored");
                });
        }
    };
    onchangeReason = (e: any) => {
        if (e.target.value == "Other") {
            this.setState({ in_reason: "", selectVal: false });
            $("#out_reason").modal("hide");
            $("#another-reason").modal("show");
        } else if (e.target.value == "select") {
            this.setState({ setResponse: false });
        } else {
            // this.setState({})
            this.setState({ [e.target.name]: e.target.value, selectVal: false });
        }
    };
    onchange = (e: any) => {
        this.setState({
            [e.target.name]: e.target.value.replace(/\s+/g, " ").trim(),
            selectVal: false,
        });
    };
    // getFiles = () => {
    //     const userid1: any = sessionStorage.getItem("user1");
    //     let user_id = JSON.parse(userid1);
    //     let req = {
    //         userId: user_id,
    //     };
    //     callApi("POST", "get_unhold_files.php", req)
    //         .then((res) => res.data)
    //         .then((response) => {
    //             const files = response.data;
    //             if (files && files.length > 0) {
    //                 files.forEach((file, index) => {
    //                     file.index = index + 1;
    //                 });
    //             }
    //             this.setState({ files, isFileRefresh: true });
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    // };
    getFiles = () => {
        const { currentPage, limit, rowCount } = this.state;
        const userid1: any = sessionStorage.getItem("user1");
        let user_id = JSON.parse(userid1);
        let req = {
            userId: user_id,
            page: currentPage,
            limit: limit,
        };

        this.setState({ isLoading: true });

        callApi("POST", "get_unhold_files.php", req)
            .then((res) => res.data)
            .then((response) => {
                const { data: files, totalRecords, currentPage: apiCurrentPage } = response;
                if (files && files.length > 0) {
                    files.forEach((file, index) => {
                        file.index = index + 1 + (currentPage - 1) * limit;
                    });
                }
                this.setState({
                    files,
                    totalDataCount: Number(totalRecords), // Should set to 493
                    currentPage: Number(apiCurrentPage), // Sync with API
                    isLoading: false,
                    isFileRefresh: true,
                });
                console.log("State after update:", this.state);
            })
            .catch((err) => {
                console.log(err);
                this.setState({ isLoading: false });
            });
    };

    downloadExcel = () => {
        const { currentPage, limit, rowCount } = this.state;
        const userid1: any = sessionStorage.getItem("user1");
        let user_id = JSON.parse(userid1);
        let req = {
            userId: user_id,
            page: currentPage,
            limit: limit,
        };

        this.setState({ isLoading: true, isDownloading: true });

        callApi("POST", "get_unhold_files_excel.php", req)
            .then((res) => res.data)
            .then(async (response) => {
                const { data: files, totalRecords, currentPage: apiCurrentPage } = response;
                if (files && files.length > 0) {
                    if (!Array.isArray(files)) {
                        console.error("Data is not an array:", files);
                        return;
                    }

                    const workbook = new ExcelJS.Workbook();
                    const worksheet = workbook.addWorksheet('Sheet1');

                    // Define columns with headers
                    worksheet.columns = [
                        { header: 'S.No', key: 's_no', width: 10 },
                        { header: 'File No', key: 'file_id', width: 10 },
                        { header: 'Bank Name', key: 'bank_name', width: 30 },
                        { header: 'Product', key: 'product_name', width: 15 },
                        { header: 'Law Hands Branch Name', key: 'lh_branch', width: 15 },
                        { header: 'Bank Branch Name', key: 'bank_branch', width: 15 },
                        { header: 'Property Owner Name', key: 'owner_name', width: 15 },
                        { header: 'Borrower Name', key: 'borrower_name', width: 15 },
                        { header: 'Hold By', key: 'hold_by', width: 15 },
                        { header: 'Hold Date', key: 'hold_dt', width: 15 },
                        { header: 'Hold Reason', key: 'hold_reason', width: 15 },
                        { header: 'UnHold By', key: 'unhold_by', width: 15 },
                        { header: 'UnHold Date', key: 'unhold_dt', width: 15 },
                        { header: 'UnHold Reason', key: 'unhold_reason', width: 15 },
                    ];

                    // Apply alignment and color to header cells
                    worksheet.getRow(1).eachCell((cell) => {
                        cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'FFFF00' }, // Yellow color
                        };
                        cell.font = { bold: true, size: 14 };
                    });

                    const flattenedData = files.map((file, index) => {
                        const hold_by = file.all_hold
                            .map(
                                (doc, docIndex) => `${doc.hold_by}`
                            )
                            .join("\n");
                        const hold_dt =
                            file.all_hold && file.all_hold.length > 0
                                ? file.all_hold
                                    .map((query, queryIndex) => `${query.hold_dt}`)
                                    .join("\n")
                                : "Nil";
                        const hold_reason =
                            file.all_hold && file.all_hold.length > 0
                                ? file.all_hold
                                    .map((query, queryIndex) => `${queryIndex + 1}. ${query.hold_reason}`)
                                    .join("\n")
                                : "Nil";
                        const unhold_by = file.all_hold
                            .map(
                                (doc, docIndex) => `${doc.unhold_by}`
                            )
                            .join("\n");
                        const unhold_dt =
                            file.all_hold && file.all_hold.length > 0
                                ? file.all_hold
                                    .map((query, docIndex) => `${query.unhold_dt}`)
                                    .join("\n")
                                : "Nil";
                        const unhold_reason =
                            file.all_hold && file.all_hold.length > 0
                                ? file.all_hold
                                    .map((query, docIndex) => `${docIndex + 1}. ${query.unhold_reason}`)
                                    .join("\n")
                                : "Nil";
                        return {
                            s_no: index + 1,
                            file_id: file.file_id,
                            bank_name: file.bank_name,
                            product_name: file.product_name,
                            lh_branch: file.lh_branch,
                            bank_branch: file.bank_branch,
                            owner_name: file.owner_name,
                            borrower_name: file.borrower_name,
                            hold_by: hold_by,
                            hold_dt: hold_dt,
                            hold_reason: hold_reason,
                            unhold_by: unhold_by,
                            unhold_dt: unhold_dt,
                            unhold_reason: unhold_reason,
                        };
                    });

                    // Add data rows with custom cell alignment and wrap text
                    flattenedData.forEach(item => {
                        const row = worksheet.addRow(item);
                        row.eachCell((cell) => {
                            cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
                        });
                    });
                    const currentDate = new Date();
                    const formattedDate = `${currentDate.getDate().toString().padStart(2, '0')}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getFullYear()}`;
                    // Generate Excel file buffer
                    const buffer = await workbook.xlsx.writeBuffer();
                    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    const url = window.URL.createObjectURL(blob);
                    const anchor = document.createElement('a');
                    anchor.href = url;
                    anchor.download = `Unhold Report ${formattedDate}.xlsx`;
                    anchor.click();
                    window.URL.revokeObjectURL(url);
                    this.setState({ isDownloading: false });
                }

            })
            .catch((err) => {
                console.log(err);
                this.setState({ isLoading: false, isDownloading: false });
            });
    }

    handlePageChange = (event, value) => {
        this.setState({ currentPage: value }, () => {
            this.getFiles();
        });
    };

    handlePageSizeChange = (event) => {
        this.setState({ limit: event.target.value, currentPage: 1 }, () => {
            this.getFiles();
        });
    };

    assignFile = (modal: any) => {
        this.setState({ fileToBeVerified: modal.id });
    };
    goToFileDashboard = (id) => {
        const url = "/unhold-file";
        localStorage.setItem("url", url);
        let obj = { file_id: id };
        localStorage.setItem("thisPopup", JSON.stringify(obj));
        sessionStorage.setItem("currentfileId", JSON.stringify(id));
        this.props.history.push("/file-dashboard/" + id);
    };

    timeUpdate = (fileid, type) => {
        const loginuser: any = sessionStorage.getItem("user1");
        let req;
        if (type == "verification") {
            req = {
                id: fileid,
                reason: "Verification Picked",
                created_by: JSON.parse(loginuser),
                color: "#faad4c",
            };
        } else if (type == "unhold") {
            req = {
                id: fileid,
                reason: "Unhold",
                created_by: JSON.parse(loginuser),
                color: "gray",
            };
        }
        callApi("POST", "time_update.php", req)
            .then((res) => res.data)
            .then((response) => {
                if (type == "verification") {
                    window.location.href = "/work-file-verification-list/" + fileid;
                }
            });
    };

    getSelectedFile = (id) => {
        this.setState({ unholdFile: id });
    };
    unholdFile = () => {
        this.setState({ unholdVal: true });
        const user: any = sessionStorage.getItem("user");
        const userId = user ? JSON.parse(user)["userId"] : "";
        const { unholdreason, unholdVal } = this.state;
        if (unholdreason != "" && unholdVal) {
            this.setState({ isLoading: true });
            const obj = {
                file_id: this.state.unholdFile,
                unhold_by: userId,
                reason: unholdreason,
                user_id: userId,
            };
            callApi("POST", "unhold_file.php", obj)
                .then((res) => res.data)
                .then(async (response) => {
                    if (response.statuscode == 200 && response.file_taken_status == 0) {
                        ToastsStore.success(response.message);
                        this.setState({
                            unholdVal: false,
                            unholdreason: "",
                            isLoading: false,
                        });
                        $("#holdModal").modal("hide");
                        await this.timeUpdate(this.state.unholdFile, "unhold");
                        await this.timeUpdate(this.state.unholdFile, "verification");
                        this.getFiles();
                    } else if (response.file_taken_status == 1) {
                        console.log("response.file_taken_status == 1");
                        $("#holdModal").modal("hide");
                        $("#file-name").modal("show");
                    } else {
                        ToastsStore.error(response.message);
                        this.setState({ isLoading: false });
                    }
                });
        } else {
            ToastsStore.error("Please type reason");
        }
    };
    viewReason = (val: any) => {
        const { files } = this.state;
        if (files && files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                const element: any = files[i];
                if (element.id == val.id) {
                    this.setState({ holdDetails: element.all_hold }, () => {
                        $("#view-reasson").modal("show");
                    });
                }
            }
        }
    };
    render() {
        const columns = [
            {
                header: "S.No",
                key: "serial",
                td: (file) => (
                    <td onClick={() => this.goToFileDashboard(file.id)}>{file.serial}</td>
                ),
            },
            {
                header: "File No",
                key: "file_id",
                td: (file) => (
                    <td onClick={() => this.goToFileDashboard(file.id)}>
                        {file.file_id}
                    </td>
                ),
            },
            {
                header: "Bank Name",
                key: "bank_name",
                td: (file) => (
                    <td onClick={() => this.goToFileDashboard(file.id)}>
                        {file.bank_name}{" "}
                        {file.product_name ? <>({file.product_name})</> : ""}
                    </td>
                ),
            },
            {
                header: "Bank Branch Name",
                key: "bank_branch",
                td: (file) => (
                    <td onClick={() => this.goToFileDashboard(file.id)}>
                        {file.bank_branch}
                    </td>
                ),
            },
            {
                header: "Property Owner Name",
                key: "owner_name",
                td: (file) => (
                    <td onClick={() => this.goToFileDashboard(file.id)}>
                        {file.owner_name}
                    </td>
                ),
            },
            {
                header: "View reason",
                // key: "hold_reason",
                td: (file) => (
                    <td>
                        <button
                            className="btn btn-outline-primary"
                            onClick={() => this.viewReason(file)}
                        >
                            View
                        </button>
                    </td>
                ),
            },
        ];
        const data = this.state.files;
        const processedData = data
            ? data.map((report: any, index) => ({
                serial: index + 1,
                id: report.id,
                file_id: report.file_id ? report.file_id : "",
                bank_name: report.bank_name ? report.bank_name : "",
                product_name: report.product_name,
                bank_branch: report.bank_branch ? report.bank_branch : "",
                lh_branch: report.lh_branch ? report.lh_branch : "",
                owner_name: report.owner_name ? report.owner_name : "",
                hold_by: report.hold_by ? report.hold_by : "",
                hold_reason: report.hold_reason ? report.hold_reason : "",
                hold_dt: report.hold_dt ? report.hold_dt : "",
                unhold_by: report.unhold_by ? report.unhold_by : "",
                unhold_reason: report.unhold_reason ? report.unhold_reason : "",
                unhold_dt: report.unhold_dt ? report.unhold_dt : "",
            }))
            : "";
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1; // Months are zero-indexed, so add 1
        const day = currentDate.getDate();
        const formattedDate = `${year}-${month < 10 ? "0" : ""}${month}-${day < 10 ? "0" : ""
            }${day}`;
        const downloadExcelProps = {
            type: "filtered",
            title: `Unhold report - ${formattedDate}`,
            showLabel: true,
        };
        const { selectVal } = this.state;
        const { files, totalDataCount, currentPage, limit } = this.state;
        console.log("Render state:", { files: files.length, totalDataCount, currentPage, limit });
        const noOfPages = Math.ceil(totalDataCount / limit);

        return (
            <section className="pcoded-main-container">
                <ToastsContainer
                    store={ToastsStore}
                    position={ToastsContainerPosition.TOP_RIGHT}
                />{" "}
                <div className="pcoded-wrapper">
                    <div className="pcoded-content">
                        <div className="pcoded-inner-content">
                            <div className="main-body">
                                <div className="page-wrapper">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h5 className="file-status-header">UnHold Reports</h5>
                                                </div>
                                                {this.state.isFileRefresh && (
                                                    <>
                                                        <div className="ml-4 mt-2">
                                                            <button
                                                                className="download-table-xls-button excel-download-btn"
                                                                onClick={() => this.downloadExcel()}
                                                                disabled={this.state.isDownloading}
                                                            >
                                                                {this.state.isDownloading ? "Downloading..." : "Download"}
                                                            </button>
                                                        </div>
                                                        <div className="card-body">
                                                            <ReactFlexyTable
                                                                data={processedData}
                                                                columns={columns}
                                                                sortable
                                                                pagination={false}
                                                                globalSearch
                                                                // showExcelButton
                                                                className="Flexy-table-unhold"
                                                                downloadExcelProps={downloadExcelProps}
                                                                pageSize={20}
                                                                showTotalRecords={false}
                                                            />

                                                            <div className="pagination-container">
                                                                <div>
                                                                    {/* <span>Show</span>
                                                                    <Select className="select-container" variant="outlined" value={this.state.limit} onChange={e => this.handlePageSizeChange(e)}>
                                                                        {this.limitList.map((limitValue, index) => (
                                                                            <MenuItem key={index} value={limitValue}>
                                                                                {limitValue}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select> */}
                                                                    <span>Total data count : {this.state.totalDataCount}</span>
                                                                </div>
                                                                {noOfPages > 1 && (
                                                                    <Pagination
                                                                        count={noOfPages}
                                                                        defaultPage={this.state.page}
                                                                        onChange={this.handlePageChange}
                                                                        showFirstButton
                                                                        showLastButton
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="modal fade"
                                    id="holdModal"
                                    tabIndex={-1}
                                    role="dialog"
                                    aria-labelledby="holdModalLabel"
                                    aria-hidden="true"
                                >
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5>
                                                    Are you sure you want to unhold{" "}
                                                    {this.state.unholdFile}?
                                                </h5>
                                            </div>
                                            {this.state.unholdVal && (
                                                <>
                                                    <div className="modal-body">
                                                        <p style={{ textAlign: "center", color: "black" }}>
                                                            Please Type a Reason To Unhold The File
                                                        </p>
                                                        <div
                                                            className="form-group"
                                                            style={{ width: "98%", margin: "auto" }}
                                                        >
                                                            <textarea
                                                                className="form-control"
                                                                placeholder="Type Your Reason"
                                                                name="unholdreason"
                                                                onChange={this.onchange}
                                                            />
                                                            {isEmpty(this.state.unholdreason) ? (
                                                                <p
                                                                    style={{
                                                                        color: "red",
                                                                        textAlign: "center",
                                                                        fontSize: "16px",
                                                                    }}
                                                                >
                                                                    Please type your reason{" "}
                                                                </p>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            <div className="modal-footer">
                                                <button
                                                    type="button"
                                                    className="btn btn-secondary"
                                                    data-dismiss="modal"
                                                >
                                                    No
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={() => this.unholdFile()}
                                                >
                                                    {this.state.isLoading ? (
                                                        <i className="fa fa-spinner fa-spin"></i>
                                                    ) : (
                                                        "Yes"
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* THIS FOR OUT REASON MODAL */}
                <div
                    className="modal fade hide in"
                    id="out_reason"
                    data-backdrop="static"
                    data-keyboard="false"
                >
                    <div className="modal-dialog ">
                        <div
                            className="modal-content"
                            style={{ borderRadius: "10px", boxShadow: "0 0 15px #000" }}
                        >
                            <div
                                className="modal-body"
                                style={{ padding: "30px 23px 29px 24px" }}
                            >
                                <h3 style={{ margin: "auto", fontSize: "17px" }}>
                                    Please Select The Reason For To Close The File.No:
                                    {sessionStorage.getItem("currentfileId")}
                                </h3>
                            </div>
                            <div
                                className="form-group"
                                style={{ width: "74%", margin: "auto" }}
                            >
                                <select
                                    className="custom-select"
                                    name="outreason"
                                    value={this.state.outreason}
                                    onChange={(e) => this.onchangeReason(e)}
                                    required
                                    id="select1"
                                    style={{ marginTop: "8px" }}
                                >
                                    <option value="select">Select Your Reason</option>
                                    <option value="Query Raised">Query Raised</option>
                                    <option value=" Re-Query Raised"> Re-Query Raised</option>
                                    <option value="Hold ">Hold </option>
                                    <option value="Completed">Completed</option>
                                    <option value="Other">Others</option>
                                </select>
                                {selectVal ? (
                                    <p
                                        style={{
                                            color: "red",
                                            textAlign: "center",
                                            fontSize: "16px",
                                        }}
                                    >
                                        Please Select Your Reason{" "}
                                    </p>
                                ) : null}
                            </div>
                            <div className="modal-footer" style={{ marginTop: "27px" }}>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    disabled={this.state.isLoading}
                                    onClick={this.outimeGet}
                                    style={{ width: "76px", fontSize: "17px" }}
                                >
                                    {" "}
                                    {this.state.isLoading ? (
                                        <i className="fa fa-spinner fa-spin"></i>
                                    ) : (
                                        "OK"
                                    )}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-outline"
                                    onClick={this.BacktoFile}
                                    style={{
                                        width: "107px",
                                        fontSize: "14px",
                                        border: "1px solid black",
                                        height: "43px",
                                    }}
                                >
                                    {" "}
                                    Back To File
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="modal fade bd-example-modal-lg"
                    id="view-reasson"
                    tabIndex={-1}
                    role="dialog"
                    aria-hidden="true"
                >
                    <div
                        className="modal-dialog"
                        style={{
                            maxWidth: "1400px",
                            maxHeight: "660px",
                            overflow: "hidden",
                        }}
                        role="document"
                    >
                        <div
                            className="modal-content"
                            style={{
                                borderRadius: "10px",
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                            }}
                        >
                            <div
                                className="modal-header"
                                style={{
                                    backgroundColor: "#007bff",
                                    color: "white",
                                    borderBottom: "none",
                                    borderTopLeftRadius: "10px",
                                    borderTopRightRadius: "10px",
                                }}
                            >
                                <h5
                                    className="modal-title"
                                    style={{ textAlign: "center", color: "white" }}
                                >
                                    Hold & Unhold Details
                                </h5>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true" style={{ color: "white" }}>
                                        &times;
                                    </span>
                                </button>
                            </div>
                            <div
                                className="modal-body"
                                style={{
                                    backgroundColor: "#f8f9fa",
                                    padding: "20px",
                                    borderRadius: "8px",
                                    maxHeight: "670px",
                                    overflowY: "scroll",
                                }}
                            >
                                <div className="dt-responsive table-responsive">
                                    <table className="table table-striped table-bordered table-hover">
                                        <thead
                                            className="thead sticky-table-header"
                                            style={{ background: "#d9d9d9" }}
                                        >
                                            <tr style={{ fontSize: "14px" }}>
                                                <th scope="col" className="text-center th">
                                                    S.no
                                                </th>
                                                <th scope="col" className="text-center th">
                                                    Hold Data
                                                </th>
                                                <th scope="col" className="text-center th">
                                                    Hold By
                                                </th>
                                                <th scope="col" className="text-center th">
                                                    Hold Reason
                                                </th>
                                                <th scope="col" className="text-center th">
                                                    Unhhold Data
                                                </th>
                                                <th scope="col" className="text-center th">
                                                    Unhold By
                                                </th>
                                                <th scope="col" className="text-center th">
                                                    Unhold Reason
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="tbody">
                                            {this.state.holdDetails &&
                                                this.state.holdDetails.map((data: any, index: any) => (
                                                    <tr key={index}>
                                                        <td className="text-center td">{index + 1}</td>
                                                        <td className="text-center td">{data.hold_dt}</td>
                                                        <td className="text-center td">{data.hold_by}</td>
                                                        <td
                                                            className="text-center td"
                                                            style={{ width: "25%", padding: "1%" }}
                                                        >
                                                            {data.hold_reason}
                                                        </td>
                                                        <td className="text-center td">{data.unhold_dt}</td>
                                                        <td className="text-center td">{data.unhold_by}</td>
                                                        <td
                                                            className="text-center td"
                                                            style={{ width: "25%", padding: "1%" }}
                                                        >
                                                            {data.unhold_reason}
                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div
                                className="modal-footer"
                                style={{
                                    border: "none",
                                    backgroundColor: "#f8f9fa",
                                    borderBottomLeftRadius: "10px",
                                    borderBottomRightRadius: "10px",
                                }}
                            >
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-dismiss="modal"
                                >
                                    close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="modal hide fade in"
                    id="another-reason"
                    data-backdrop="static"
                    data-keyboard="false"
                >
                    <div className="modal-dialog ">
                        <div
                            className="modal-content"
                            style={{ borderRadius: "10px", boxShadow: "0 0 15px #000" }}
                        >
                            <h5 style={{ marginTop: "31px", textAlign: "center" }}>
                                Please Type a Reason To Close The File.No:{" "}
                                {sessionStorage.getItem("currentfileId")}{" "}
                            </h5>
                            <div
                                className="modal-body"
                                style={{ padding: "30px 23px 29px 24px" }}
                            >
                                {/* <div className="col-md-5"> */}
                                <div
                                    className="form-group"
                                    style={{ width: "98%", margin: "auto" }}
                                >
                                    <textarea
                                        className="form-control"
                                        placeholder="Type Your Reason"
                                        name="outreason"
                                        onChange={this.onchange}
                                    />
                                    {selectVal ? (
                                        <p
                                            style={{
                                                color: "red",
                                                textAlign: "center",
                                                fontSize: "16px",
                                            }}
                                        >
                                            Please Select Your Reason{" "}
                                        </p>
                                    ) : null}
                                </div>
                                {/* </div> */}
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => this.outimeGet()}
                                    style={{ width: "76px", fontSize: "17px" }}
                                    disabled={this.state.isLoading}
                                >
                                    {this.state.isLoading ? (
                                        <i className="fa fa-spinner fa-spin"></i>
                                    ) : (
                                        "OK"
                                    )}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-outline"
                                    onClick={() => this.previousPop()}
                                    // onClick={this.disableModalHandler}
                                    style={{
                                        width: "76px",
                                        fontSize: "17px",
                                        border: "1px solid black",
                                    }}
                                >
                                    {" "}
                                    Back
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="modal fade"
                    id="file-name"
                    tabIndex={-1}
                    role="dialog"
                    aria-labelledby="fileNameLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5>
                                    A file is currently in progress and cannot be changes to unhold.
                                </h5>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-dismiss="modal"
                                >
                                    Ok
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <VerifyFileModal
                    fileToBeVerified={this.state.fileToBeVerified}
                    getFiles={this.getFiles}
                    assignedFrom={"unassign"}
                />
            </section>
        );
    }
}
export default UnHoldReport;
