import React, { Component } from "react";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Select from 'react-select';
import MeasureLabelItem, { MeasureLabel } from "./MeasureLabelItem";
export interface MeasureList {
  measurement?: string;
  land_aries?: string;
  direction?: string;
  unit?: string;
  north?: string;
  south?: string;
  east?: string;
  west?: string;
  measu_id?: string;
  collapseid?: boolean;
  sw?: boolean;
  measurement_list: MeasureLabel[];
  asperpatta?: boolean;
  asperVAO?: boolean;
  asperVR?: boolean;
  asperTS?: boolean;
  asperRP?: boolean;
  boundaryNoType: string;
  plot_item_no: string;
}
interface AllProps {
  stateToProps: any;
  measurementDetail: any;
  extentUnits: any;
  required: any;
}
export default class MeasurementItem extends Component<AllProps> {
  state = {
    boundary_details: this.props.measurementDetail,
    delete_items_measure: [],
    extentUnits: this.props.extentUnits.filter(itm => itm.id !== 'Custom'),
    required: this.props.required
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.measurementDetail && nextProps.measurementDetail.length) {
      this.setState({ boundary_details: nextProps.measurementDetail });
    } else if (!this.state.boundary_details || !this.state.boundary_details.length) {
      this.setState({ boundary_details: [] });
      this.addItem();
    }
  }
  componentDidMount() {
    console.log(this.state.boundary_details);
    if (this.props.measurementDetail && this.props.measurementDetail.length) {
      this.setState({ survey: this.props.measurementDetail });
    } else if (!this.state.boundary_details || !this.state.boundary_details.length) {
      this.setState({ boundary_details: [] });
      this.addItem();
    }
  }
  componentDidUpdate(prevProps: AllProps) {
    if (prevProps.required !== this.props.required) {
      this.setState({ required: this.props.required });
    }
  }

  stateToProps = (event, index) => {
    const boundary_detailss: any = this.state.boundary_details;
    boundary_detailss[index][event.name] = event.value;
    this.setState({ boundary_detailss });
  };
  addItem = (): void => {
    const boundary_details: MeasureList[] = this.state.boundary_details
      ? this.state.boundary_details
      : [];
    boundary_details.push({
      measurement: "",
      direction: "",
      unit: "",
      north: "",
      east: "",
      west: "",
      south: "",
      measurement_list: [],
      boundaryNoType: '',
      plot_item_no: '',
    });
    this.setState({ boundary_details });
  };
  removeItem = (modal: MeasureList, index: number): void => {
    const boundary_details: MeasureList[] = this.state.boundary_details;
    boundary_details.splice(index, 1);

    const delete_items_measure: any = this.state.delete_items_measure;
    delete_items_measure.push(modal.measu_id);
    this.setState({ boundary_details, delete_items_measure });

    this.props.stateToProps({
      name: "delete_items_measure",
      value: this.state.delete_items_measure
    });
  };
  collapsearray = (modal: MeasureList, index: number): void => {
    const boundary_details: MeasureList[] = this.state.boundary_details;
    if (boundary_details[index] !== undefined) {
      if (boundary_details[index].collapseid) {
        boundary_details[index].collapseid = false;
      }
      else
        boundary_details[index].collapseid = true;
      this.setState({ boundary_details });
    }
  };
  handleChange = (e: any, index: number, propName?, fieldType?) => {
    const boundary_details: MeasureList[] = this.state.boundary_details;
    boundary_details.forEach((itm: any, i) => {
      if (i === index) {
        let fieldName = fieldType === 'singleselect' ? propName : e.target.name;
        let fieldValue = fieldType === 'singleselect' ? e.value : e.target.value;
        itm[fieldName] = fieldValue;
        if (fieldName === 'sw') itm[fieldName] = e.target.checked;
        else if (fieldName === "boundaryNoType") {
          itm.plot_item_no = '';
        } else {
          const resetFields = {
            asperpatta: ['asperVAO', 'asperVR', 'asperTS', 'asperRP'],
            asperVAO: ['asperpatta', 'asperVR', 'asperTS', 'asperRP'],
            asperVR: ['asperpatta', 'asperVAO', 'asperTS', 'asperRP'],
            asperTS: ['asperpatta', 'asperVAO', 'asperVR', 'asperRP'],
            asperRP: ['asperpatta', 'asperVAO', 'asperVR', 'asperTS']
          };

          if (resetFields.hasOwnProperty(fieldName)) {
            itm[fieldName] = e.target.checked;
            resetFields[fieldName].forEach(field => itm[field] = false);
          }
        }
      }
    });
    this.setState({ boundary_details }, () => {
      console.log("boundary_details", boundary_details);
    });
    this.props.stateToProps({
      name: "boundary_details",
      value: this.state.boundary_details
    });
  };
  render() {
    const boundaryTypes = [
      {
        id: '',
        name: 'Deselect'
      },
      {
        id: 'Plot No',
        name: 'Plot No'
      },
      {
        id: 'Item No',
        name: 'Item No'
      },
    ]
    return this.state.boundary_details && this.state.boundary_details.length
      ? this.state.boundary_details.map((measure: MeasureList, index) => {
        return (
          <>
            <Accordion defaultActiveKey="0" style={{ width: "100%" }}>
              <Card style={{
                borderRadius: "10px",
                width: "99%",
                margin: "auto"
              }}>
                <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => this.collapsearray(measure, index)}
                  className="card-border-c-green">
                  <div className="row">
                    <div className="col-md-8" style={{ paddingTop: "6px" }}>
                      <h2>
                        {measure.collapseid ? <i className="fa fa-angle-down rotate-icon"></i> : <i className="fa fa-angle-up rotate-icon"></i>}&nbsp;&nbsp;
                        Boundary-{index + 1}
                      </h2>
                    </div>
                    <div className="col-md-4" >
                      <div style={{
                        float: "right"
                      }}>
                        <button
                          type="button"
                          style={{ marginTop: "1px" }}
                          className="btn btn-icon btn-rounded btn-primary"
                          onClick={this.addItem}
                        >
                          <i className="feather icon-plus" />
                        </button>
                        <button
                          type="button"
                          style={{ marginTop: "1px" }}
                          className="btn btn-icon btn-rounded btn-danger"
                          disabled={this.state.boundary_details.length === 1}
                          onClick={() => this.removeItem(measure, index)}
                        >
                          <i className="feather icon-minus" />
                        </button>
                      </div>
                    </div>
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body style={{
                    borderRadius: "10px",
                    width: "99%",
                    margin: "auto"
                  }}>
                    <div className="row">
                      {/* <div className="col-md-4">
                        <div className="form-group">
                          <label>As Per Patta Extract</label>
                          <input
                            className="form-control"
                            name="asperpatta"
                            type="checkbox"
                            checked={measure.asperpatta}
                            onChange={e => this.handleChange(e, index)}
                          />
                        </div>
                      </div> */}
                      <div className="col-md-12">
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-6">
                              <label>As Per VAO Boundary Certificate</label>
                            </div>
                            <div className="col-md-6">
                              <input
                                className="form-control"
                                name="asperVAO"
                                type="checkbox"
                                checked={measure.asperVAO}
                                onChange={e => this.handleChange(e, index)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-6">
                              <label>As Per Valuation Report</label>
                            </div>
                            <div className="col-md-6">
                              <input
                                className="form-control"
                                name="asperVR"
                                type="checkbox"
                                checked={measure.asperVR}
                                onChange={e => this.handleChange(e, index)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-6">
                              <label>Triangle Shape</label>
                            </div>
                            <div className="col-md-6">
                              <input
                                className="form-control"
                                name="asperTS"
                                type="checkbox"
                                checked={measure.asperTS}
                                onChange={e => this.handleChange(e, index)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-6">
                              <label>For Road Purpose</label>
                            </div>
                            <div className="col-md-6">
                              <input
                                className="form-control"
                                name="asperRP"
                                type="checkbox"
                                checked={measure.asperRP}
                                onChange={e => this.handleChange(e, index)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Select Plot/Item No</label>
                          {boundaryTypes && boundaryTypes.length > 0 && boundaryTypes.map((opt: any) => { opt.label = opt.name; opt.value = opt.id }) &&
                            <Select options={boundaryTypes} name="boundaryNoType"
                              className={'form-select is-invalid'}
                              // className={this.state.required && measure.unit === '' ? 'form-select is-invalid' : 'form-select'}
                              value={boundaryTypes.find((o: any) => o.value === measure.boundaryNoType)}
                              onChange={(e) => this.handleChange(e, index, "boundaryNoType", 'singleselect')}
                            />
                          }
                        </div>
                      </div>
                      <div className="col-md-6">
                        {(measure.boundaryNoType == "Plot No" || measure.boundaryNoType == "Item No") ? <>
                          <div className="form-group">
                            {measure.boundaryNoType == "Plot No" ? <><label>Plot No</label></> :
                              (measure.boundaryNoType == "Item No" ? <><label>Item No</label></> : "")}
                            <input
                              className={'form-control'}
                              name="plot_item_no"
                              placeholder={`${measure.boundaryNoType == "Plot No" ? "Eg. 2, 3,156 [West Side Part]" : 'Eg. 1, 2, 3'}`}
                              type="text"
                              value={measure.plot_item_no}
                              onChange={e => this.handleChange(e, index)}
                              required
                            />
                          </div>
                        </> : ""}
                      </div>
                    </div>
                    <div className="row" >
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Boundaries<span style={{ color: "#FF0000" }}>*</span></label>
                          <input
                            className={this.state.required && measure.land_aries === '' ? 'form-control is-invalid' : 'form-control'}
                            name="land_aries"
                            placeholder="Eg. 1230"
                            type="text"
                            value={measure.land_aries}
                            onChange={e => this.handleChange(e, index)}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Select Unit<span style={{ color: "#FF0000" }}>*</span></label>
                          {this.state.extentUnits && this.state.extentUnits.length > 0 && this.state.extentUnits.map((opt: any) => { opt.label = opt.name; opt.value = opt.id }) &&
                            <Select options={this.state.extentUnits} name="unit"
                              className={this.state.required && measure.unit === '' ? 'form-select is-invalid' : 'form-select'}
                              value={this.state.extentUnits.find((o: any) => o.value === measure.unit)}
                              onChange={(e) => this.handleChange(e, index, "unit", 'singleselect')}
                            />
                          }
                        </div>
                      </div>
                      {/* </div> */}
                      {/* <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Measurements</label>
                    <input
                      className="form-control"
                      name="measurement"
                      value={measure.measurement}
                      type="text"
                      onChange={e => this.handleChange(e, index)}
                      required
                    />
                  </div>
                </div>
              </div> */}
                      {/* <div className="row"> */}
                      <div className="col-md-3">
                        <div className="form-group">
                          <label style={{ width: "150px" }}>Direction Of ( Or ) By<span style={{ color: "#FF0000" }}>*</span></label>
                          <Select options={[{ value: '', label: 'Deselect' }, { value: 'By', label: 'By', }, { value: 'Of', label: 'Of', }]} name="direction"
                            className={this.state.required && measure.direction === '' ? 'form-select is-invalid' : 'form-select'}
                            value={[{ value: 'By', label: 'By', }, { value: 'Of', label: 'Of', }].find((o: any) => o.value === measure.direction)}
                            onChange={(e) => this.handleChange(e, index, 'direction', 'singleselect')}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Switch</label>
                          <input
                            className="form-control"
                            name="sw"
                            type="checkbox"
                            checked={measure.sw}
                            onChange={e => this.handleChange(e, index)}
                          />
                        </div>
                      </div>
                      {/* </div>
              <div className="row"> */}
                      <div className="col-md-12">
                        <div className="form-group">
                          <label id="select_north">North<span style={{ color: "#FF0000" }}>*</span></label>
                          <input
                            className={this.state.required && measure.north === '' ? 'form-control is-invalid' : 'form-control'}
                            name="north"
                            type="text"
                            placeholder="Maximum 200 Characters..."
                            value={measure.north}
                            onChange={e => this.handleChange(e, index)}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label id="select_south">South<span style={{ color: "#FF0000" }}>*</span></label>
                          <input
                            className={this.state.required && measure.south === '' ? 'form-control is-invalid' : 'form-control'}
                            name="south"
                            type="text"
                            placeholder="Maximum 200 Characters..."
                            value={measure.south}
                            onChange={e => this.handleChange(e, index)}
                            required
                          />
                        </div>
                      </div>
                      {/* </div>
              <div className="row"> */}
                      <div className="col-md-12">
                        <div className="form-group">
                          <label id="select_east">East<span style={{ color: "#FF0000" }}>*</span></label>
                          <input
                            className={this.state.required && measure.east === '' ? 'form-control is-invalid' : 'form-control'}
                            name="east"
                            placeholder="Maximum 200 Characters..."
                            value={measure.east}
                            type="text"
                            onChange={e => this.handleChange(e, index)}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label id="select_west">West<span style={{ color: "#FF0000" }}>*</span></label>
                          <input
                            className={this.state.required && measure.west === '' ? 'form-control is-invalid' : 'form-control'}
                            name="west"
                            type="text"
                            placeholder="Maximum 200 Characters..."
                            value={measure.west}
                            onChange={e => this.handleChange(e, index)}
                            required
                          />
                        </div>
                      </div>
                      <MeasureLabelItem
                        stateToProps={e =>
                          this.stateToProps(e, index)
                        }
                        measurementDetail={
                          measure.measurement_list
                        }
                        extentUnits={this.props.extentUnits}
                        required={this.state.required}
                      />
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </>
        );
      })
      : "";
  }
}
