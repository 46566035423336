import * as React from "react";
import { callApi } from "../../utils/api";
import MaskedInput from "react-text-mask";
import Select from "react-select";
import ReactFlexyTable from "react-flexy-table";
import FileSearchModal from "./FileSearchModal";
import { PendingFile } from "./ManagePendingFile";
import "../process_eod/process_eod.css";
import { User } from "../user/ManageUser";
import DatePicker from "antd/lib/date-picker";
import moment from "moment";
import { V } from "jointjs";
import Accordion from "react-bootstrap/Accordion";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import "bootstrap/dist/css/bootstrap.css";
import {
	ToastsContainer,
	ToastsContainerPosition,
	ToastsStore,
} from "react-toasts";
import Spinner from "../PDF-Highlighter/Spinner";
import Spinner1 from "../work-file/loader";
import { green } from "@material-ui/core/colors";
import Pagination from '@material-ui/lab/Pagination';
import ExcelJS from "exceljs";

declare var $: any;

interface AllProps {
	match: any;
	history: any;
	router: any;
	route: any;
	id: any;
}

class SignatureFileSearch extends React.Component<AllProps> {
	state = {
		backReason: "",
		userVAlue: false,
		banks: [],
		bank_name: "",
		selectVal: false,
		setResponse: true,
		myJSON: "",
		lookup: "",
		fileId: "",
		sampleId: "",
		id: "",
		fromDate: "",
		toDate: "",
		files: [],
		fileNo: "",
		lawhandsBranches: [],
		lawhands_branch: "",
		assigned_to: "",
		users: [],
		total: "",
		green: "",
		red: "",
		outreason: "",
		isloading: false,
		isLoading: false,
		SigncompleteFiles: [],
		// fromDate: moment().format('YYYY-MM-DD'),
		// toDate: moment().format('YYYY-MM-DD'),
		filteredBranches: [],
		filteredProduct: [],
		products: [],
		product_id: "",
		branches: [],
		branch: "",
		page: 1,
		limit: 20,
		totalRecords: "",
		activekey: "SignpendingKey",
		isDownloading: false,
	};
	obj: any;
	table: any;
	componentDidMount() {
		this.verificationcompletedfiles();
		this.getLawHandsBranches();
		this.getUsers();
		this.getOutTime();
		this.ouTime();
		this.getBanks();
		this.listBankproduct();
		this.getBankBranches();
	}
	listBankproduct = () => {
		this.setState({ isFileRefresh: false });
		callApi("GET", "bank_product_get.php")
			.then((res) => res.data)
			.then((response) => {
				const files = response.data;
				this.setState({ products: files, filteredProduct: files });
			})
			.catch((err) => {
				console.log(err);
			});
	};
	previousPop = () => {
		$("#out_reason").modal("show");
		$("#another-reason").modal("hide");
	};
	BacktoFile = () => {
		$("#out_reason").modal("hide");
		const fileid1: any = sessionStorage.getItem("currentfileId");

		this.props.history.push("/file-dashboard/" + JSON.parse(fileid1));
	};

	getOutTime = () => {
		let date = new Date();
		let stratTime = date.getTime();
		localStorage.setItem("startTime", JSON.stringify(stratTime));
		let outDate = date.toLocaleDateString();
		localStorage.setItem("OutDateGet", JSON.stringify(outDate));
		var Railway = date.getMinutes();
		if (Railway < 10) {
			var TotalMinutes = "0" + Railway;
			let ouTtime = date.getHours() + ":" + TotalMinutes;
			localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
		} else {
			let ouTtime: any = date.getHours() + ":" + date.getMinutes();
			localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
		}
	};
	ouTime = () => {
		const getfileId: any = sessionStorage.getItem("currentfileId");
		console.log("54", JSON.parse(getfileId));
		console.log("32", getfileId);
		if (sessionStorage.getItem("currentfileId")) {
			$("#out_reason").modal("show");
		}
	};

	outimeGet = () => {
		this.setState({ isLoading: true });
		const fileid1: any = sessionStorage.getItem("currentfileId");
		const userid1: any = sessionStorage.getItem("user1");
		const inreason: any = localStorage.getItem("in_reason");
		const intime: any = localStorage.getItem("IntimeGet");
		console.log("70", intime);
		const outtime: any = localStorage.getItem("outtimeGet");
		console.log("73", outtime);
		const outdate: any = localStorage.getItem("OutDateGet");
		const secondsSpent = moment(outtime, "HH:mm").diff(
			moment(intime, "HH:mm"),
			"seconds"
		);
		console.log("Second Spn", secondsSpent);
		if (this.state.outreason == "") {
			this.setState({ selectVal: true });
		} else {
			let obj = {
				file_id: JSON.parse(fileid1),
				user_id: JSON.parse(userid1),
				in_reason: JSON.parse(inreason),
				out_reason: this.state.outreason,
				intime: JSON.parse(intime),
				outtime: JSON.parse(outtime),
				insert_date: JSON.parse(outdate),
				total_seconds: secondsSpent,
			};

			// console.log("72",obj);
			callApi("POST", "process_eod_insert.php", obj)
				.then((res) => res.data)
				.then((response) => {
					console.log("76", response);
					this.setState({ isLoading: false });
					sessionStorage.removeItem("currentfileId");
					localStorage.removeItem("in_reason");
					localStorage.removeItem("outreason");
					localStorage.removeItem("IntimeGet");
					localStorage.removeItem("OutDateGet");
					$("#out_reason").modal("hide");
					$("#another-reason").modal("hide");
					ToastsStore.success("Your Reason Saved Successfully");
					let process_officerId: any =
						sessionStorage.getItem("process_office_id");
					let process_officer_id = JSON.parse(process_officerId);
					const userid1: any = sessionStorage.getItem("user1");
					let user_id = JSON.parse(userid1);
					const take_file: any = sessionStorage.getItem("takenfile_id");
					const user: any = sessionStorage.getItem("user");
					const getfileId: any = sessionStorage.getItem("currentfileId");
					if (
						process_officer_id == user_id &&
						take_file != "" &&
						take_file == getfileId
					) {
						this.fileout(fileid1);
					} else {
						sessionStorage.removeItem("currentfileId");
					}
				})
				.catch((err) => {
					console.log(err);
					this.setState({ isLoading: false });
					ToastsStore.error("Your Reason Not Stored");
				});
		}
	};
	fileout = (fileid1) => {
		let fileId = JSON.parse(fileid1);
		const loginuser: any = sessionStorage.getItem("user1");
		let req = {
			id: fileId,
			reason: "File Out",
			created_by: JSON.parse(loginuser),
		};
		callApi("POST", "time_update.php", req)
			.then((res) => res.data)
			.then((response) => {
				sessionStorage.removeItem("currentfileId");
				// sessionStorage.removeItem("takenfile_id");
			});
	};
	onchangeReason = (e: any) => {
		if (e.target.value == "Other") {
			this.setState({ in_reason: "", selectVal: false });
			$("#out_reason").modal("hide");
			$("#another-reason").modal("show");
		} else if (e.target.value == "select") {
			this.setState({ setResponse: false });
		} else {
			// this.setState({})
			this.setState({ [e.target.name]: e.target.value, selectVal: false });
		}
	};
	onchange = (e: any) => {
		this.setState({
			[e.target.name]: e.target.value.replace(/\s+/g, " ").trim(),
			selectVal: false,
		});
	};

	handleTabSelect = (tabKey: any) => {
		this.setState({ activekey: tabKey }, () => {
			if (tabKey === 'SignpendingKey') {
				this.verificationcompletedfiles();
			} else if (tabKey === 'SigncompleteKey') {
				this.signaturecompletedfiles();
			}
		})
	}

	handlePageChange = (event, value) => {
		this.setState({ page: value }, () => {
			if (this.state.activekey === 'SignpendingKey') {
				this.verificationcompletedfiles();
			} else if (this.state.activekey === 'SigncompleteKey') {
				this.signaturecompletedfiles();
			}
		});
	};

	verificationcompletedfiles = () => {
		this.setState({ isloading: true });
		let userid: any = sessionStorage.getItem("user1");
		let req = {
			user_id: JSON.parse(userid),
			id: this.state.id,
			from_date: this.state.fromDate,
			to_date: this.state.toDate,
			employee_id: this.state.assigned_to,
			lawhands_branch: this.state.lawhands_branch,
			bank_name: this.state.bank_name,
			product_id: this.state.product_id,
			branch: this.state.branch,
			page: this.state.page,
			limit: this.state.limit
		};
		callApi("POST", "verification_complete_list.php", req)
			.then((res) => res.data)
			.then((result) => {
				if (result.statusCode == 200) {
					let data = [];
					localStorage.removeItem("EodReport");
					console.log(localStorage.removeItem("EodReport"));
					if (result?.responseJson && result?.responseJson.data) {
						data = result?.responseJson.data;
						this.setState({ isloading: false });
					}
					this.setState({ files: data ? data : [], totalRecords: result?.responseJson?.totalRecords });
				}
			})
			.catch((err) => {
				console.log(err);
				this.setState({ files: [], isloading: false });
			});
	};


	signaturecompletedfiles = () => {
		this.setState({ isloading: true });
		let userid: any = sessionStorage.getItem("user1");
		let req = {
			user_id: JSON.parse(userid),
			id: this.state.id,
			from_date: this.state.fromDate,
			to_date: this.state.toDate,
			employee_id: this.state.assigned_to,
			lawhands_branch: this.state.lawhands_branch,
			bank_name: this.state.bank_name,
			product_id: this.state.product_id,
			branch: this.state.branch,
			page: this.state.page,
			limit: this.state.limit
		};
		callApi("POST", "signature_complete_list.php", req)
			.then((res) => res.data)
			.then((result) => {
				if (result.statusCode == 200) {
					let data = [];
					localStorage.removeItem("EodReport");
					console.log(localStorage.removeItem("EodReport"));
					if (result?.responseJson && result?.responseJson.data) {
						data = result?.responseJson.data;
						this.setState({ isloading: false });
					}
					this.setState({ SigncompleteFiles: data ? data : [], totalRecords: result?.responseJson?.totalRecords });
				}
			})
			.catch((err) => {
				console.log(err);
				this.setState({ SigncompleteFiles: [], isloading: false });
			});
	};

	// Function to download data as Excel
	downloadExcel = () => {
		this.setState({ isloading: true, isDownloading: true });
		const user: any = sessionStorage.getItem("user");
		const userId = user ? JSON.parse(user)["userId"] : "";

		// Create the request object without pagination
		let req = {
			user_id: JSON.parse(userId),
			id: this.state.id,
			from_date: this.state.fromDate,
			to_date: this.state.toDate,
			employee_id: this.state.assigned_to,
			lawhands_branch: this.state.lawhands_branch,
			bank_name: this.state.bank_name,
			product_id: this.state.product_id,
			branch: this.state.branch,
			download: true,
			// No `page` and `limit` here since we need all data
		};

		const api = this.state.activekey == "SignpendingKey" ? "verification_complete_list.php" : "signature_complete_list.php";
		callApi("POST", `${api}`, req)
			.then((res) => res.data)
			.then((result) => {
				if (result.statusCode === 200) {
					// If data exists, set the state and trigger download
					const allData = result?.responseJson?.data || [];

					// Trigger the Excel download
					this.triggerExcelDownload(allData);
				} else {
					this.setState({ isloading: false, isDownloading: false });
				}
			})
			.catch((err) => {
				console.log(err);
				this.setState({ files: [], isloading: false, isDownloading: false });
			});
	};


	// Function to handle Excel download
	triggerExcelDownload = async (files) => {
		if (files && files.length > 0) {
			if (!Array.isArray(files)) {
				console.error("Data is not an array:", files);
				return;
			}

			const workbook = new ExcelJS.Workbook();
			const worksheet = workbook.addWorksheet('Sheet1');

			// Define columns with headers
			worksheet.columns = [
				{ header: 'S.No', key: 's_no', width: 10 },
				{ header: 'File No', key: 'file_no', width: 10 },
				{ header: 'Bank Name', key: 'bank_name', width: 30 },
				{ header: 'Product', key: 'product_name', width: 15 },
				{ header: 'Bank Branch Name', key: 'branch_name', width: 30 },
				{ header: 'Law Hands Branch Name', key: 'lh_branch_name', width: 30 },
				{ header: 'Borrower Name', key: 'borrower_name', width: 30 },
				{ header: 'Property Owner Name', key: 'property_ownername', width: 30 },
				{ header: 'Process Officer Name', key: 'process_officer_name', width: 30 },
				{ header: 'Verification Officer Name', key: 'verification_office_name', width: 30 },
				{ header: 'Verification Completed Date', key: 'verification_complete_date', width: 30 },
				{ header: 'Last Report Updated Date', key: 'last_report_update_date', width: 30 },
				{ header: 'Signature Count', key: 'sign_count', width: 30 },
				{ header: 'Signature Start Date', key: 'sign_inprogress_date', width: 30 },
				{ header: 'Signature Start By', key: 'sign_inprogress_by', width: 30 },
				{ header: 'Signature Completed Date', key: 'sign_completed_date', width: 30 },
				{ header: 'Signature Completed By', key: 'sign_completed_by', width: 30 },
			];

			// Apply alignment and color to header cells
			worksheet.getRow(1).eachCell((cell) => {
				cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
				cell.fill = {
					type: 'pattern',
					pattern: 'solid',
					fgColor: { argb: 'FFFF00' }, // Yellow color
				};
				cell.font = { bold: true, size: 14 };
			});

			const flattenedData = files.map((file, index) => {
				const sign_inprogress_date = file?.all_records?.map(
					(doc, docIndex) => `${docIndex + 1}. ${doc.sign_inprogress_date}`
				).join("\n");
				const sign_inprogress_by = file?.all_records?.map(
					(doc, docIndex) => `${docIndex + 1}. ${doc.sign_inprogress_by}`
				).join("\n");
				const sign_completed_date = file?.all_records?.map(
					(doc, docIndex) => `${docIndex + 1}. ${doc.sign_completed_date}`
				).join("\n");
				const sign_completed_by = file?.all_records?.map(
					(doc, docIndex) => `${docIndex + 1}. ${doc.sign_completed_by}`
				).join("\n");

				return {
					s_no: index + 1,
					file_no: file.file_no,
					bank_name: file.bank_name,
					product_name: file.product_name,
					branch_name: file.branch_name,
					lh_branch_name: file.lh_branch_name,
					borrower_name: file.borrower_name,
					property_ownername: file.property_ownername,
					process_officer_name: file.process_officer_name,
					verification_office_name: file.verification_office_name,
					verification_complete_date: file.verification_complete_date,
					last_report_update_date: file.last_report_update_date,
					sign_count: file?.sign_count,
					sign_inprogress_date: sign_inprogress_date,
					sign_inprogress_by: sign_inprogress_by,
					sign_completed_date: sign_completed_date,
					sign_completed_by: sign_completed_by,
				};
			});

			// Add data rows with custom cell alignment and wrap text
			flattenedData.forEach(item => {
				const row = worksheet.addRow(item);
				row.eachCell((cell) => {
					cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
				});
			});
			const currentDate = new Date();
			const formattedDate = `${currentDate.getDate().toString().padStart(2, '0')}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getFullYear()}`;
			// Generate Excel file buffer
			const report_name = this.state.activekey == "SignpendingKey" ? "Signature Pending" : "Signature Completed";
			const buffer = await workbook.xlsx.writeBuffer();
			const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
			const url = window.URL.createObjectURL(blob);
			const anchor = document.createElement('a');
			anchor.href = url;
			anchor.download = `${report_name} Report ${formattedDate}.xlsx`;
			anchor.click();
			window.URL.revokeObjectURL(url);
			this.setState({ isDownloading: false, isloading: false });
		}
	};

	getUsers = () => {
		const user: any = sessionStorage.getItem("user");
		const role_id = user ? JSON.parse(user)["role_id"] : "";
		const user_id = user ? JSON.parse(user)["userId"] : null;
		const userDetails = user ? JSON.parse(user) : "";
		const isAdmin = userDetails.role_id === "1" || userDetails.role_id === "2";
		const isFront = userDetails.role_id === "3" || userDetails.role_id === "4";
		if (isAdmin) {
			callApi("GET", "user_get.php")
				.then((res) => res.data)
				.then((response) => {
					this.setState({ users: response.data });
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			callApi("POST", "team_leader.php", { user_id: user_id })
				.then((res) => res.data)
				.then((response) => {
					console.log("210", response);
					this.setState({ users: response.data });
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};
	goToFileDashboard = (fileName) => {
		const url = "/signature-file/search";
		localStorage.setItem("url", url);
		let obj = { file_id: fileName };
		localStorage.setItem("thisPopup", JSON.stringify(obj));
		// sessionStorage.setItem("currentfileId", JSON.stringify(fileName));
		this.props.history.push("/signature-file/" + fileName);
	};

	getLawHandsBranches() {
		callApi("GET", "get_branch.php")
			.then((res) => res.data)
			.then((response) => {
				const user: any = sessionStorage.getItem("user");
				const userDetails = user ? JSON.parse(user) : "";
				let lawhandsBranches = response.data;
				if (
					lawhandsBranches &&
					lawhandsBranches.length > 0 &&
					userDetails.role_id !== "1" &&
					userDetails.my_branchs
				) {
					const myBranchList = userDetails.my_branchs.split(",");
					lawhandsBranches = lawhandsBranches.filter((value) =>
						myBranchList.includes(value.id)
					);
				}
				this.setState({ lawhandsBranches });
			})
			.catch((err) => {
				console.log({ err });
			});
	}
	getBanks() {
		callApi("GET", "bank_get.php")
			.then((res) => res.data)
			.then((result) => {
				this.setState({ banks: result.data });
			})
			.catch((err) => {
				console.log(err);
			});
	}
	lawBranchName = (type: any) => {
		if (type == 2) {
			console.log("431", type);
			this.setState({ lhBranchShow: true });
			this.getLawHandsBranches();
		} else {
			console.log("436", type);
		}
	};
	bankName = (type: any) => {
		if (type == 2) {
			console.log("431", type);
			this.setState({ showBank: true });
			this.getBanks();
		} else {
			console.log("445", type);
		}
	};
	branchName = (type: any) => {
		if (type == 2) {
			console.log("450", type);
			this.setState({ bankBranch: true });
			this.getBankBranches();
		} else {
			console.log("454", type);
		}
	};

	getBankBranches() {
		callApi("GET", "bank_branch_get.php")
			.then((res) => res.data)
			.then((result) => {
				this.setState({ branches: result.data, filteredBranches: [] });
				if (this.state.bank_name) {
					this.filterBranches(this.state.bank_name);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	}

	filterBranches = (bankid) => {
		const filteredBranches = this.state.branches.filter(
			(branch: any) => branch.bank_id === bankid
		);
		const state = this.state;
		state.filteredBranches = filteredBranches;
		this.setState({ ...state });
	};

	handleChange1 = (e: any, propName?, fieldType?) => {
		const bank_name = this.state.bank_name;
		let fieldName = fieldType === "singleselect" ? propName : e.target.name;
		let fieldValue = fieldType === "singleselect" ? e.value : e.target.value;
		if (fieldName === "bank_name") {
			this.filterBranches(bank_name);
			this.filterProduct(fieldValue);
		}
		this.setState({ bank_name });

		this.setState({ [fieldName]: fieldValue });
		if (fieldName === "product_id") {
			let newProductId = e.id;
			this.setState({ [fieldName]: newProductId }, () => {
				setTimeout(() => {
					// Ensure the updated state is used inside these methods if needed
					this.filterBranchesproduct(fieldValue);
				}, 1000);
				console.log("this.state.product_id", this.state.product_id);
			});
		}
	};
	filterBranchesproduct = (productid) => {
		const state = { ...this.state }; // Create a copy of the state
		const userBranches = state.lawhands_branch.split(","); // Split comma-separated branches into an array

		const filteredBranches = state.branches.filter((branch: any) => {
			// Split the branch's lawhands_branch into an array for comparison
			const branchLawhandsArray = branch.lawhands_branch.split(",");
			// Check if any of the branch's lawhands_branch matches userBranches
			const hasMatchingBranch = branchLawhandsArray.some((b) => userBranches.includes(b));

			return (
				branch.product_id === productid &&
				branch.bank_id === state.bank_name &&
				hasMatchingBranch // Check if there's a matching branch
			);
		});

		state.filteredBranches = filteredBranches;

		if (filteredBranches.length === 0) {
			state.branch = "";
		}

		this.setState(state);
	};
	filterProduct = (bankid: any) => {
		console.log("product id--", bankid);
		if (bankid) {
			const filteredProduct = this.state.products.filter(
				(branch: any) => branch.bank_id === bankid
			);
			const state = this.state;
			state.filteredProduct = filteredProduct;
			this.setState({ ...state });
		} else {
			this.setState({ product_id: "" });
		}
	};
	handleDateChange = (date, dateStr, type) => {
		this.setState({ [type]: dateStr });
	};
	disabledDate = (current) => {
		// Disable dates after today and dates before the last 3 months
		return (
			current &&
			(current > moment().endOf("day") ||
				current < moment().subtract(3, "months").startOf("day"))
		);
	};
	render() {
		const user: any = sessionStorage.getItem("user");
		const { selectVal } = this.state;

		const lawhandsBranchesoptions = [
			{ value: "", label: "All" },
			...this.state.lawhandsBranches,
		];
		const banksoptions = [{ value: "", label: "All" }, ...this.state.banks];
		const bankbranchoptions = [
			{ value: "", label: "All" },
			...this.state.filteredBranches,
		];
		const filteredProduct = [
			{ value: "", label: "All" },
			...this.state.filteredProduct,
		];

		const noOfPages = Math.ceil(Number(this.state.totalRecords) / this.state.limit);

		return (
			<section className="pcoded-main-container">
				<ToastsContainer
					store={ToastsStore}
					position={ToastsContainerPosition.TOP_RIGHT}
				/>{" "}
				<div className="pcoded-wrapper">
					<div className="pcoded-content">
						<div className="pcoded-inner-content">
							<div className="main-body">
								<div className="page-wrapper">
									<div className="row">
										<div className="col-sm-12">
											<div className="card" style={{ overflow: "hidden" }}>
												<div className="card-header">
													<h2
														className="file-status-header"
														style={{
															display: "flex",
															justifyContent: "center",
														}}
													>
														Signature{" "}
													</h2>
													<div className="row mb-3">
														<div className="col-xl-2 col-md-2">
															<h6>From</h6>
															<DatePicker
																format={"DD.MM.YYYY"}
																onChange={(date, dateStr) =>
																	this.handleDateChange(
																		date,
																		dateStr,
																		"fromDate"
																	)
																}
																style={{ height: "39px" }}
																disabledDate={this.disabledDate}
															/>
														</div>
														<div className="col-xl-2 col-md-2">
															<h6>To</h6>
															<DatePicker
																format={"DD.MM.YYYY"}
																disabledDate={this.disabledDate}
																onChange={(date, dateStr) =>
																	this.handleDateChange(
																		date,
																		dateStr,
																		"toDate"
																	)
																}
																style={{ height: "39px" }}
															/>
														</div>
														<div className="col-xl-2 col-md-2">
															<h6>LH Branch</h6>
															{this.state.lawhandsBranches &&
																this.state.lawhandsBranches.length > 0 &&
																this.state.lawhandsBranches.map((opt: any) => {
																	opt.label = opt.name;
																	opt.value = opt.id;
																}) && (
																	<Select
																		options={lawhandsBranchesoptions}
																		name="lawhands_branch"
																		value={this.state.lawhandsBranches.find(
																			(o: any) =>
																				o.value === this.state.lawhands_branch
																		)}
																		onChange={(e) =>
																			this.handleChange1(
																				e,
																				"lawhands_branch",
																				"singleselect"
																			)
																		}
																	/>
																)}
														</div>

														<div className="col-xl-2 col-md-2">
															<h6>Bank Name</h6>
															{this.state.banks &&
																this.state.banks.length > 0 &&
																this.state.banks.map((opt: any) => {
																	opt.label = opt.name;
																	opt.value = opt.id;
																}) && (
																	<Select
																		options={banksoptions}
																		name="bank_name"
																		value={this.state.banks.find(
																			(o: any) =>
																				o.value === this.state.bank_name
																		)}
																		onClick={() => this.bankName(1)}
																		onChange={(e) =>
																			this.handleChange1(
																				e,
																				"bank_name",
																				"singleselect"
																			)
																		}
																	/>
																)}
														</div>

														<div className="col-xl-2 col-md-2">
															{/* <div className="form-group"> */}
															<h6>Product</h6>
															{this.state.products &&
																this.state.products.map((opt: any) => {
																	opt.label = opt.product_name;
																	opt.value = opt.id;
																}) && (
																	<Select
																		options={filteredProduct}
																		name="product_id"
																		value={this.state.filteredProduct.find(
																			(o: any) =>
																				o.id === this.state.product_id
																					? this.state.product_id
																					: ""
																		)}
																		onChange={(e) =>
																			this.handleChange1(
																				e,
																				"product_id",
																				"singleselect"
																			)
																		}
																	/>
																)}
															{/* </div> */}
														</div>

														<div className="col-xl-2 col-md-2">
															<h6>Bank Branch</h6>
															{this.state.branches &&
																this.state.branches.length > 0 &&
																this.state.branches.map((opt: any) => {
																	opt.label = opt.name;
																	opt.value = opt.id;
																}) && (
																	<Select
																		options={bankbranchoptions}
																		name="branch"
																		value={this.state.filteredBranches.find(
																			(o: any) => o.value === this.state.branch
																		)}
																		onClick={() => this.branchName(1)}
																		onChange={(e) =>
																			this.handleChange1(
																				e,
																				"branch",
																				"singleselect"
																			)
																		}
																	/>
																)}
														</div>
													</div>
													<div className="row">
														<div className="col-xl-12 col-md-12 p-3">
															<button
																type="submit"
																className="btn btn-primary"
																style={{ float: "right" }}
																onClick={() => this.handleTabSelect(this.state.activekey)}
															>
																{this.state.isLoading ? (
																	<i className="fa fa-spinner fa-spin"></i>
																) : (
																	"Search"
																)}
															</button>
														</div>
													</div>
												</div>

												<Accordion className="mainAccordion">
													<Tabs
														defaultActiveKey="SignpendingKey"
														style={{ padding: 0 }}
														onSelect={this.handleTabSelect}
													>
														<Tab
															eventKey="SignpendingKey"
															title="Pending Files"
														>
															<div className="card-body">
																<div className="dt-responsive table-responsive">
																	<div className="mb-2 mt-2">
																		<button
																			className="download-table-xls-button excel-download-btn"
																			onClick={() => this.downloadExcel()}
																			disabled={this.state.isDownloading}
																		>
																			{this.state.isDownloading ? "Downloading..." : "Download"}
																		</button>
																	</div>
																	<table
																		id="processeodTable"
																		className="table table-striped table-bordered nowrap"
																	>
																		<thead>
																			<tr>
																				<th>Sl.No</th>
																				<th>File No</th>
																				<th>Bank Name</th>
																				<th>Product Name</th>
																				<th>Bank Branch Name</th>
																				<th>LH Branch Name</th>
																				<th>Borrower Name</th>
																				<th>Property Owner Name</th>
																				<th>Process Officer Name</th>
																				<th>Verification Officer Name</th>
																				<th>Verification Completed Date</th>
																				<th>Last Report Updated Date</th>
																				<th>Signature Count</th>
																			</tr>
																		</thead>
																		<tbody>
																			{this.state.files &&
																				this.state.files.length
																				? this.state.files.map(
																					(query: any, index: number) => {
																						return (
																							<tr
																								className="query-table-row cursor-pointer"
																								key={index}
																								onClick={() =>
																									this.goToFileDashboard(
																										query.file_id
																									)
																								}
																								style={{
																									backgroundColor:
																										query.file_status === 11
																											? "green"
																											: "white",
																								}}
																							>
																								<td className="text-align-center">
																									{query.serial_no}
																								</td>
																								<td className="click">
																									{query.file_no}
																								</td>
																								<td className="click">
																									{query.bank_name}
																								</td>
																								<td className="click">
																									{query.product_name}
																								</td>
																								<td className="click">
																									{query.branch_name}
																								</td>
																								<td className="click">
																									{query.lh_branch_name}
																								</td>
																								<td className="click">
																									{query.borrower_name}
																								</td>
																								<td className="click">
																									{query.property_ownername}
																								</td>
																								<td className="click">
																									{query.process_officer_name}
																								</td>
																								<td className="click">
																									{query.verification_office_name}
																								</td>
																								<td className="click">
																									{query.verification_complete_date}
																								</td>
																								<td className="click">
																									{query.last_report_update_date}
																								</td>
																								<td className="click">
																									{query?.sign_count}																								</td>
																							</tr>
																						);
																					}
																				)
																				: ""}
																		</tbody>
																	</table>
																</div>
																<div className="pagination-container">
																	<div>
																		<span>Total data count : {this.state.totalRecords}</span>
																	</div>
																	{noOfPages >= 1 && (
																		<Pagination
																			count={noOfPages}
																			defaultPage={this.state.page}
																			onChange={this.handlePageChange}
																			showFirstButton
																			showLastButton
																		/>
																	)}
																</div>
															</div>
														</Tab>
														<Tab
															eventKey="SigncompleteKey"
															title="Completed Files"
														>
															<div className="card-body">
																<div className="dt-responsive table-responsive">
																	<div className="mb-2 mt-2">
																		<button
																			className="download-table-xls-button excel-download-btn"
																			onClick={() => this.downloadExcel()}
																			disabled={this.state.isDownloading}
																		>
																			{this.state.isDownloading ? "Downloading..." : "Download"}
																		</button>
																	</div>
																	<table
																		id="signcompletefiles"
																		className="table table-striped table-bordered nowrap"
																	>
																		<thead>
																			<tr>
																				<th>Sl.No</th>
																				<th>File No</th>
																				<th>Bank Name</th>
																				<th>Product Name</th>
																				<th>Bank Branch Name</th>
																				<th>LH Branch Name</th>
																				<th>Borrower Name</th>
																				<th>Property Owner Name</th>
																				<th>Process Officer Name</th>
																				<th>Verification Officer Name</th>
																				<th>Verification Completed Date</th>
																				<th>Last Report Updated Date</th>
																				<th>Signature Count</th>
																			</tr>
																		</thead>
																		<tbody>
																			{this.state.SigncompleteFiles &&
																				this.state.SigncompleteFiles.length
																				? this.state.SigncompleteFiles.map(
																					(query: any, index: number) => {
																						return (
																							<tr
																								className="query-table-row cursor-pointer"
																								key={index}
																								onClick={() =>
																									this.goToFileDashboard(
																										query.file_id
																									)
																								}
																							>
																								<td className="text-align-center">
																									{query.serial_no}
																								</td>
																								<td className="click">
																									{query.file_no}
																								</td>
																								<td className="click">
																									{query.bank_name}
																								</td>
																								<td className="click">
																									{query.product_name}
																								</td>
																								<td className="click">
																									{query.branch_name}
																								</td>
																								<td className="click">
																									{query.lh_branch_name}
																								</td>
																								<td className="click">
																									{query.borrower_name}
																								</td>
																								<td className="click">
																									{query.property_ownername}
																								</td>
																								<td className="click">
																									{query.process_officer_name}
																								</td>
																								<td className="click">
																									{query.verification_office_name}
																								</td>
																								<td className="click">
																									{query.verification_complete_date}
																								</td>
																								<td className="click">
																									{query.last_report_update_date}
																								</td>
																								<td className="click">
																									{query?.sign_count}
																								</td>
																							</tr>
																						);
																					}
																				)
																				: ""}
																		</tbody>
																	</table>
																</div>
																<div className="pagination-container">
																	<div>
																		<span>Total data count : {this.state.totalRecords}</span>
																	</div>
																	{noOfPages >= 1 && (
																		<Pagination
																			count={noOfPages}
																			defaultPage={this.state.page}
																			onChange={this.handlePageChange}
																			showFirstButton
																			showLastButton
																		/>
																	)}
																</div>
															</div>
														</Tab>
													</Tabs>
												</Accordion>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{this.state.isloading ? <Spinner1 /> : ""}
				</div>
				{/* THIS FOR OUT REASON MODAL */}
				<div
					className="modal fade hide in"
					id="out_reason"
					data-backdrop="static"
					data-keyboard="false"
				>
					<div className="modal-dialog ">
						<div
							className="modal-content"
							style={{ borderRadius: "10px", boxShadow: "0 0 15px #000" }}
						>
							<div
								className="modal-body"
								style={{ padding: "30px 23px 29px 24px" }}
							>
								<h3 style={{ margin: "auto", fontSize: "17px" }}>
									Please Select The Reason For To Close The File.No:
									{sessionStorage.getItem("currentfileId")}
								</h3>
							</div>
							<div
								className="form-group"
								style={{ width: "74%", margin: "auto" }}
							>
								<select
									className="custom-select"
									name="outreason"
									value={this.state.outreason}
									onChange={(e) => this.onchangeReason(e)}
									required
									id="select1"
									style={{ marginTop: "8px" }}
								>
									<option value="select">Select Your Reason</option>
									<option value="Query Raised">Query Raised</option>
									<option value=" Re-Query Raised"> Re-Query Raised</option>
									<option value="Hold ">Hold </option>
									<option value="Completed">Completed</option>
									<option value="Other">Others</option>
								</select>
								{selectVal ? (
									<p
										style={{
											color: "red",
											textAlign: "center",
											fontSize: "16px",
										}}
									>
										Please Select Your Reason{" "}
									</p>
								) : null}
							</div>
							<div className="modal-footer" style={{ marginTop: "27px" }}>
								<button
									type="button"
									className="btn btn-primary"
									disabled={this.state.isLoading}
									onClick={this.outimeGet}
									style={{ width: "76px", fontSize: "17px" }}
								>
									{" "}
									{this.state.isLoading ? (
										<i className="fa fa-spinner fa-spin"></i>
									) : (
										"OK"
									)}
								</button>
								<button
									type="button"
									className="btn btn-outline"
									onClick={this.BacktoFile}
									style={{
										width: "107px",
										fontSize: "14px",
										border: "1px solid black",
										height: "43px",
									}}
								>
									{" "}
									Back To File
								</button>
							</div>
						</div>
					</div>
				</div>
				{/* OTHER REASON MODAL */}
				<div
					className="modal hide fade in"
					id="another-reason"
					data-backdrop="static"
					data-keyboard="false"
				>
					<div className="modal-dialog ">
						<div
							className="modal-content"
							style={{ borderRadius: "10px", boxShadow: "0 0 15px #000" }}
						>
							<h5 style={{ marginTop: "31px", textAlign: "center" }}>
								Please Type a Reason To Close The File.No:{" "}
								{sessionStorage.getItem("currentfileId")}{" "}
							</h5>
							<div
								className="modal-body"
								style={{ padding: "30px 23px 29px 24px" }}
							>
								{/* <div className="col-md-5"> */}
								<div
									className="form-group"
									style={{ width: "98%", margin: "auto" }}
								>
									<textarea
										className="form-control"
										placeholder="Type Your Reason"
										name="outreason"
										onChange={this.onchange}
									/>
									{selectVal ? (
										<p
											style={{
												color: "red",
												textAlign: "center",
												fontSize: "16px",
											}}
										>
											Please Select Your Reason{" "}
										</p>
									) : null}
								</div>
								{/* </div> */}
							</div>
							<div className="modal-footer">
								<button
									type="button"
									className="btn btn-primary"
									onClick={() => this.outimeGet()}
									style={{ width: "76px", fontSize: "17px" }}
									disabled={this.state.isLoading}
								>
									{" "}
									{this.state.isLoading ? (
										<i className="fa fa-spinner fa-spin"></i>
									) : (
										"OK"
									)}
								</button>
								<button
									type="button"
									className="btn btn-outline"
									onClick={() => this.previousPop()}
									// onClick={this.disableModalHandler}
									style={{
										width: "76px",
										fontSize: "17px",
										border: "1px solid black",
									}}
								>
									{" "}
									Back
								</button>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default SignatureFileSearch;

function x(arg0: string, x: any) {
	throw new Error("Function not implemented.");
}
