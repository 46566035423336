import * as React from "react";
import { NavLink } from "react-router-dom";
import { callApi } from "../../utils/api";
import { ADMIN_ROLE, FRONTOFFICE_ROLE, MANAGER_ROLE } from "../../utils/role";

class Sidebar extends React.Component {
	state = {
		openFileMenu: false,
		toggleCompletedFiles: false,
		openExternalMenu: false,
		externalLinks: [],
		openComplaintMenu: false,
		openNotificationMenu: false,
		openVisitorsMenu: false,
		openReportsMenu: false,
		vettingFileMenu: false,
		pageDynamic: [],
		waiting_queue_count: 0,
		queue_count: 0,
		autoAssign: [
			{
				id: "1",
				module_name: "Auto Assign",
				status: true,
			},
		],
		files: [],
		file_grade: 0,
		verifyEmergentCount: 0,
		verifyNormalFile: 0,
		deviationfiles: 0,
		deviationemergentFile: 0,
		rectificationfiles: 0,
		rectificationemergentFile: 0
	};
	interval: NodeJS.Timeout | null = null;
	componentDidMount() {
		// this.getExternalLinks();
		this.getPageDynamicData();
		// this.getAutoAssignStatus();
		this.getFilesqueue();
		this.getFiles(1, 20);
		this.getDeviationFilesqueue();
		this.getRectificationFilesqueue();
		// this.interval = setInterval(() => {
		// 	this.getFilesqueue();
		// }, 100000);
	}
	componentWillUnmount() {
		// Clear the interval when the component is unmounted
		// if (this.interval) {
		// 	clearInterval(this.interval);
		// }
	}
	getFilesqueue = () => {
		const user: any = sessionStorage.getItem("user");
		const userId = user ? JSON.parse(user)["userId"] : "";
		const user_grade = user ? JSON.parse(user)["user_grade"] : "";
		const roleType = user ? JSON.parse(user)["roleType"] : "";
		callApi("POST", "get_by_status.php", { status: 0, userId, user_grade })
			.then((res) => res.data)
			.then((response) => {
				const files = response.data;
				const currentDate: any = new Date();
				const newFilesMoreThan20: any = [];
				const newFilesLessThan20: any = [];
				let file_grade: any[] = [];
				files.forEach((file: any) => {
					const fileDateTime = file.date_time.split("/");
					if (
						file.file_grade == "E" &&
						["0", "1", "2", "8"].includes(file.file_status)
					) {
						file_grade.push(file);
					}
					const fileDate = fileDateTime[0];
					const fileTime = fileDateTime[1];
					const [fileDay, fileMonth, fileYear] = fileDate.split("-");
					const [fileHour, fileMinute] = fileTime.split(":");
					const fileDateObject: any = new Date(
						parseInt(fileYear, 10),
						parseInt(fileMonth, 10) - 1,
						parseInt(fileDay, 10),
						parseInt(fileHour, 10),
						parseInt(fileMinute, 10)
					);
					const timeDifference = Math.floor(
						(currentDate - fileDateObject) / (1000 * 60)
					);
					if (timeDifference >= 20 && file.file_grade != "E") {
						newFilesMoreThan20.push(file);
					} else {
						if (timeDifference <= 20 && file.file_grade != "E") {
							newFilesLessThan20.push(file);
						}
					}
				});
				this.setState({ waiting_queue_count: newFilesMoreThan20.length });
				this.setState({ queue_count: newFilesLessThan20.length });
				this.setState({ file_grade: file_grade.length });
			})
			.catch((err) => {
				console.log(err);
			});
	};
	getFiles = (page_id, row_count) => {
		const user: any = sessionStorage.getItem("user");
		const userId = user ? JSON.parse(user)["userId"] : "";
		callApi("POST", "get_by_status.php", {
			status: 6,
			userId,
			page_id,
			row_count,
		})
			.then((res) => res.data)
			.then((response) => {
				// const files = response.data;
				// let verifyEmergentFile: any[] = [];
				// let NormalFile: any[] = [];
				// files.forEach((file: any) => {
				// 	if (file.file_grade == "E" && ["6"].includes(file.file_status)) {
				// 		verifyEmergentFile.push(file);
				// 	}
				// });
				// files.forEach((file, index) => {
				// 	if (file.file_grade != "E" && ["6"].includes(file.file_status)) {
				// 		NormalFile.push(file);
				// 	}
				// });
				this.setState({
					verifyEmergentCount: response.countEmergent ?? "0",
					verifyNormalFile: response.countNormal ?? "0",
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};
	getDeviationFilesqueue = () => {
		const user: any = sessionStorage.getItem("user");
		const userId = user ? JSON.parse(user)["userId"] : "";
		callApi("POST", "get_deviation_files.php", {
			userId: userId
		})
			.then((res) => res.data)
			.then((response) => {
				const files = response.data;
				let EmergentFile: any[] = [];
				let NormalFile: any[] = [];
				files.forEach((file, index) => {
					if (file.file_grade == "E") {
						EmergentFile.push(file);
					}
				});
				files.forEach((file, index) => {
					if (file.file_grade != "E") {
						NormalFile.push(file);
					}
				});
				if (NormalFile && NormalFile.length > 0) {
					NormalFile.forEach((file, index) => {
						Object.keys(file).forEach(fileKey => {
							file[fileKey] = !file[fileKey] ? "" : typeof file[fileKey] !== 'string' ? String(file[fileKey]) : file[fileKey];
						})
						file.index = index + 1;
					})
				}
				if (EmergentFile && EmergentFile.length > 0) {
					EmergentFile.forEach((file, index) => {
						Object.keys(file).forEach(fileKey => {
							file[fileKey] = !file[fileKey] ? "" : typeof file[fileKey] !== 'string' ? String(file[fileKey]) : file[fileKey];
						})
						file.index = index + 1;
					})
				}
				this.setState({ deviationfiles: NormalFile.length, deviationemergentFile: EmergentFile.length });
			})
			.catch((err) => {
				console.log(err);
			});
	};
	getRectificationFilesqueue = () => {
		const user: any = sessionStorage.getItem("user");
		const userId = user ? JSON.parse(user)["userId"] : "";
		callApi("POST", "get_rectify_files.php", {
			userId: userId
		})
			.then((res) => res.data)
			.then((response) => {
				const files = response.data;
				let EmergentFile: any[] = [];
				let NormalFile: any[] = [];
				files.forEach((file, index) => {
					if (file.file_grade == "E") {
						EmergentFile.push(file);
					}
				});
				files.forEach((file, index) => {
					if (file.file_grade != "E") {
						NormalFile.push(file);
					}
				});
				if (NormalFile && NormalFile.length > 0) {
					NormalFile.forEach((file, index) => {
						Object.keys(file).forEach(fileKey => {
							file[fileKey] = !file[fileKey] ? "" : typeof file[fileKey] !== 'string' ? String(file[fileKey]) : file[fileKey];
						})
						file.index = index + 1;
					})
				}
				if (EmergentFile && EmergentFile.length > 0) {
					EmergentFile.forEach((file, index) => {
						Object.keys(file).forEach(fileKey => {
							file[fileKey] = !file[fileKey] ? "" : typeof file[fileKey] !== 'string' ? String(file[fileKey]) : file[fileKey];
						})
						file.index = index + 1;
					})
				}
				this.setState({ rectificationfiles: NormalFile.length, rectificationemergentFile: EmergentFile.length });
			})
			.catch((err) => {
				console.log(err);
			});
	};
	getPageDynamicData = () => {
		const user: any = sessionStorage.getItem("user");
		const userDetails = user ? JSON.parse(user) : "";
		let obj = {
			roleId: userDetails ? userDetails.role_id : null,
			userId: userDetails ? userDetails.userId : null,
			roleType: userDetails ? userDetails.roleType : null,
		};
		callApi("POST", "authenticating_pages_list_new.php", obj)
			.then((res) => res.data)
			.then((result) => {
				this.setState({ pageDynamic: result.responseJson.data });
			})
			.catch((err) => {
				console.log(err);
			});
	};
	getAutoAssignStatus = () => {
		const user: any = sessionStorage.getItem("user");
		const userDetails = user ? JSON.parse(user) : "";
		callApi("GET", "module_option_select.php", "")
			.then((res) => res.data)
			.then((result) => {
				this.setState({ autoAssign: result.responseJson.data });
			})
			.catch((err) => {
				console.log(err);
			});
	};
	toggleFileMenu = () => {
		const openFileMenu = !this.state.openFileMenu;
		this.setState({ openFileMenu });
	};
	toggleExternalMenu = () => {
		this.getExternalLinks();
		const openExternalMenu = !this.state.openExternalMenu;
		this.setState({ openExternalMenu });
	}; /*  */
	toggleComplaintMenu = () => {
		this.setState({ openComplaintMenu: !this.state.openComplaintMenu });
	};
	toggleNotificationMenu = () => {
		this.setState({ openNotificationMenu: !this.state.openNotificationMenu });
	};
	toggleVisitorsMenu = () => {
		this.setState({ openVisitorsMenu: !this.state.openVisitorsMenu });
	};
	toggleReportsMenu = () => {
		this.setState({ openReportsMenu: !this.state.openReportsMenu });
	};
	togglevettingMenu = () => {
		this.setState({ vettingFileMenu: !this.state.vettingFileMenu });
	};
	getExternalLinks = () => {
		callApi("GET", "get_external_link.php")
			.then((res) => res.data)
			.then((response) => {
				this.setState({ externalLinks: response.data });
			})
			.catch((err) => {
				console.log(err);
			});
	};
	render() {
		const user: any = sessionStorage.getItem("user");
		const role_id = user ? JSON.parse(user)["role_id"] : "";
		const user_id = user ? JSON.parse(user)["userId"] : null;
		const roleType = user ? JSON.parse(user)["roleType"] : "";
		const userDetails = user ? JSON.parse(user) : "";
		const user_grade = user ? JSON.parse(user)["e_grade"] : "";
		const r_grade = user ? JSON.parse(user)["r_grade"] : "";
		const isAdmin = userDetails.role_id === "1" || userDetails.role_id === "2";
		const {
			waiting_queue_count,
			queue_count,
			file_grade,
			verifyEmergentCount,
			verifyNormalFile,
			deviationfiles,
			deviationemergentFile,
			rectificationfiles,
			rectificationemergentFile
		} = this.state;
		const path = roleType === '16'
			? "/deviation-dashboard"
			: roleType === '17'
				? "/rectify-dashboard"
				: role_id === 3
					? "/executive-dashboard"
					: "/dashboard";

		const predefinedOrder = ["Hold", "UnHold"];

		const sortedPages = this.state?.pageDynamic.sort(
			(a: any, b: any) => predefinedOrder.indexOf(a.page_name) - predefinedOrder.indexOf(b.page_name)
		);

		return (
			<nav className="pcoded-navbar menupos-fixed ">
				<div className="navbar-wrapper ">
					<div className="navbar-brand header-logo">
						<a className="b-brand">
							<div className="b-bg">L</div>
							<span className="b-title">Law Hands</span>
						</a>
					</div>
					<div className="navbar-content scroll-div style-sidebar">
						<ul className="nav pcoded-inner-navbar ">
							<li
								data-username="dashboard default ecommerce sales Helpdesk ticket CRM analytics crypto project"
								className="nav-item"
							>
								<NavLink to={path}>
									<span className="pcoded-micon">
										<i className="feather icon-home" />
									</span>
									<span className="pcoded-mtext">Dashboard</span>
								</NavLink>
							</li>

							<li
								data-username="widget statistic data chart"
								className="nav-item pcoded-hasmenu"
							>
								<a onClick={this.toggleFileMenu}>
									<span className="pcoded-micon">
										<i className="feather  icon-server" />
									</span>
									<span className="pcoded-mtext">File management</span>
								</a>
								{this.state.openFileMenu && (
									<ul className="pcoded-submenu">
										{sortedPages.map((page: any, index: any) => {
											if (
												page.page_name === "Login Files" &&
												page.status == true
											) {
												return (
													<li className="" key={index}>
														<NavLink
															to={"/pending-file"}
															activeClassName="active"
														>
															{page.page_name}
														</NavLink>
													</li>
												);
											} else if (
												page.page_name === "Unassigned Files" &&
												page.status == true
											) {
												const autoAssignData = this.state.autoAssign.find(
													(data: any) =>
														data.module_name === "Auto Assign" && data.status
												);
												if (autoAssignData) {
													return (
														<li
															key={index}
															className=""
															style={{ display: "flex" }}
														>
															<NavLink
																to={"/unassigned-file"}
																activeClassName="active"
															>
																Queue
															</NavLink>
															<div style={{ marginTop: "3%" }}>
																<span className="queue-badge">
																	{queue_count}
																</span>
																<span className="queue-waiting-badge">
																	{waiting_queue_count}
																</span>
																{["E"].includes(user_grade) && (
																	<span className="emergent-count-badge">
																		{file_grade}
																	</span>
																)}
															</div>
														</li>
													);
												} else {
													return (
														<li
															key={index}
															className=""
															style={{ display: "flex" }}
														>
															<NavLink
																to={"/unassigned-file"}
																activeClassName="active"
															>
																{page.page_name}
															</NavLink>
														</li>
													);
												}
											}
											if (
												page.page_name === "Process Completed Files" &&
												page.status == true
											) {
												const autoAssignData = this.state.autoAssign.find(
													(data: any) =>
														data.module_name === "Auto Assign" && data.status
												);
												if (autoAssignData) {
													return (
														<li
															key={index}
															className=""
															style={{ display: "flex" }}
														>
															<NavLink
																to={"/to-be-verified-file"}
																activeClassName="active"
															>
																Verification Queue
															</NavLink>
															<div style={{ marginTop: "3%" }}>
																<span className="queue-badge">
																	{verifyNormalFile}
																</span>
																{["E"].includes(user_grade) && (
																	<span className="emergent-count-badge">
																		{verifyEmergentCount}
																	</span>
																)}
															</div>
														</li>
													);
												} else {
													return (
														<li key={index} className="">
															<NavLink
																to={"/to-be-verified-file"}
																activeClassName="active"
															>
																{page.page_name}
															</NavLink>
														</li>
													);
												}
											} else if (
												page.page_name === "Assigned Files" &&
												page.status == true
											) {
												return (
													<li key={index} className="">
														<NavLink
															to={"/assigned-file"}
															activeClassName="active"
														>
															{page.page_name}
														</NavLink>
													</li>
												);
											} else if (
												page.page_name === "Work In Progress" &&
												page.status == true
											) {
												return (
													<li key={index} className="">
														<NavLink
															to={"/inprogress-file"}
															activeClassName="active"
														>
															{page.page_name}
														</NavLink>
													</li>
												);
											} else if (
												page.page_name === "Query Raised Files" &&
												page.status == true
											) {
												return (
													<li key={index} className="">
														<NavLink
															to={"/query-raised-files"}
															activeClassName="active"
														>
															{page.page_name}
														</NavLink>
													</li>
												);
											} else if (
												page.page_name === "Query Received Files" &&
												page.status == true
											) {
												return (
													<li key={index} className="">
														<NavLink
															to={"/query-received-files"}
															activeClassName="active"
														>
															{page.page_name}
														</NavLink>
													</li>
												);
											} else if (
												page.page_name === "Your Verification Files" &&
												page.status == true
											) {
												return (
													<li key={index} className="">
														<NavLink
															to={"/to-user-verified-file"}
															activeClassName="active"
														>
															{page.page_name}
														</NavLink>
													</li>
												);
											}
											//   else if (
											//     page.page_name === "Hold and Reject" &&
											//     page.status == true
											//   ) {
											//     return (
											//       <li key={index} className="">
											//         <NavLink
											//           to={"/hold-reject-file"}
											//           activeClassName="active"
											//         >
											//           {page.page_name}
											//         </NavLink>
											//       </li>
											//     );
											//   } 
											else if (
												page.page_name === "Hold" &&
												page.status == true
											) {
												return (
													<li key={index} className="">
														<NavLink
															to={"/hold-file"}
															activeClassName="active"
														>
															{page.page_name}
														</NavLink>
													</li>
												);
											}
											else if (
												page.page_name === "UnHold" &&
												page.status == true
											) {
												return (
													<li key={index} className="">
														<NavLink
															to={"/unhold-file"}
															activeClassName="active"
														>
															{page.page_name}
														</NavLink>
													</li>
												);
											}
											else if (
												page.page_name === "Completed Files" &&
												page.status == true
											) {
												return (
													<li
														key={index}
														data-username="widget statistic data chart"
														className="nav-item pcoded-hasmenu"
													>
														<a
															onClick={() =>
																this.setState({
																	toggleCompletedFiles:
																		!this.state.toggleCompletedFiles,
																})
															}
														>
															<span className="pcoded-mtext">
																Completed Files
															</span>
														</a>
														{this.state.toggleCompletedFiles && (
															<ul className="pcoded-submenu">
																<li key={index} className="">
																	<NavLink
																		to={"/typing-completed"}
																		activeClassName="active"
																	>
																		Typing Completed
																	</NavLink>
																</li>
																<li key={index} className="">
																	<NavLink
																		to={"/completed-file"}
																		activeClassName="active"
																	>
																		Verify Completed
																	</NavLink>
																</li>
															</ul>
														)}
													</li>
												);
											} else if (
												page.page_name === "Signature" &&
												page.status == true
											) {
												return (
													<li key={index} className="">
														<NavLink
															to={"/signature-file/search"}
															activeClassName="active"
														>
															{page.page_name}
														</NavLink>
													</li>
												);
											} else if (
												page.page_name === "File Logs" &&
												page.status == true
											) {
												return (
													<li key={index} className="">
														<NavLink to={"/file-log"} activeClassName="active">
															{page.page_name}
														</NavLink>
													</li>
												);
											} else if (
												page.page_name === "Deviation Queue" &&
												page.status == true
											) {
												return (
													<li key={index} className="" style={{ display: "flex" }}>
														<NavLink to={"/deviation-queue"} activeClassName="active">
															{page.page_name}
														</NavLink>
														<div style={{ marginTop: "3%" }}>
															<span className="queue-badge">
																{deviationfiles}
															</span>
															{["E"].includes(user_grade) && (
																<span className="emergent-count-badge">
																	{deviationemergentFile}
																</span>
															)}
														</div>
													</li>
												);
											} else if (
												page.page_name === "Deviation InProgress" &&
												page.status == true
											) {
												return (
													<li key={index} className="">
														<NavLink to={"/deviation-work-inprogress"} activeClassName="active">
															{page.page_name}
														</NavLink>
													</li>
												);
											} else if (
												page.page_name === "Rectification Queue" &&
												page.status == true && r_grade == "R"
											) {
												return (
													<li key={index} className="" style={{ display: "flex" }}>
														<NavLink to={"/rectification-queue"} activeClassName="active">
															{page.page_name}
														</NavLink>
														<div style={{ marginTop: "3%" }}>
															<span className="queue-badge">
																{rectificationfiles}
															</span>
															{/* {["E"].includes(user_grade) && ( */}
																<span className="emergent-count-badge">
																	{rectificationemergentFile}
																</span>
															{/* )} */}
														</div>
													</li>
												);
											} else if (
												page.page_name === "Rectification InProgress" &&
												page.status == true && r_grade == "R"
											) {
												return (
													<li key={index} className="">
														<NavLink to={"/rectification-work-inprogress"} activeClassName="active">
															{page.page_name}
														</NavLink>
													</li>
												);
											}
											return null;
										})}
									</ul>
								)}
							</li>
							<li
								data-username="widget statistic data chart"
								className="nav-item"
							>
								<NavLink to={"/entry-tracking-report"}>
									<span className="pcoded-micon">
										<i className="feather icon-file-text" />
									</span>
									<span className="pcoded-mtext">Entry Tracking Report</span>
								</NavLink>
							</li>
							<li
								data-username="widget statistic data chart"
								className="nav-item"
							>
								<NavLink to={"/process-eod"}>
									<span className="pcoded-micon">
										<i className="feather icon-file-text" />
									</span>
									<span className="pcoded-mtext">Process Officer Eod</span>
								</NavLink>
							</li>
							<li
								data-username="widget statistic data chart"
								className="nav-item"
							>
								<NavLink to={"/eod-report"}>
									<span className="pcoded-micon">
										<i className="feather icon-file-text" />
									</span>
									<span className="pcoded-mtext">Verification Eod</span>
								</NavLink>
							</li>
							{role_id !== FRONTOFFICE_ROLE && (
								<li
									data-username="widget statistic data chart"
									className="nav-item pcoded-hasmenu"
								>
									<a onClick={this.toggleReportsMenu}>
										<span className="pcoded-micon">
											<i className="feather icon-file-text" />
										</span>
										<span className="pcoded-mtext">Reports</span>
									</a>

									{this.state.openReportsMenu && (
										<ul className="pcoded-submenu">
											{this.state.pageDynamic.map((page: any, index: any) => {
												if (
													page.page_name === "GOLM Report" &&
													page.status == true
												) {
													return (
														<li className="" key={index}>
															<NavLink
																to={"/golm-report"}
																activeClassName="active"
															>
																{page.page_name}
															</NavLink>
														</li>
													);
												}

												else if (
													page.page_name === "Deviation Report" &&
													page.status == true
												) {
													return (
														<li className="" key={index}>
															<NavLink
																to={"/Deviationreport-report"}
																activeClassName="active"
															>
																{page.page_name}
															</NavLink>
														</li>
													);
												}
												else if (
													page.page_name === "File Completed Report" &&
													page.status == true
												) {
													return (
														<li className="" key={index}>
															<NavLink
																to={"/filecompleted-report"}
																activeClassName="active"
															>
																{page.page_name}
															</NavLink>
														</li>
													);
												}
												else if (
													page.page_name === "vetting Report" &&
													page.status == true
												) {
													return (
														<li className="" key={index}>
															<NavLink
																to={"/vettingcomplete"}
																activeClassName="active"
															>
																{page.page_name}
															</NavLink>
														</li>
													);
												}
												else if (
													page.page_name === "Lawhands Mis" &&
													page.status == true
												) {
													return (
														<li className="" key={index}>
															<NavLink
																to={"/lawhandsMisreport"}
																activeClassName="active"
															>
																{page.page_name}
															</NavLink>
														</li>
													);
												}
												else if (
													page.page_name === "Emergent Report" &&
													page.status == true
												) {
													return (
														<li className="" key={index}>
															<NavLink
																to={"/emergentreport"}
																activeClassName="active"
															>
																{page.page_name}
															</NavLink>
														</li>
													);
												}
												else if (
													page.page_name === "Reassign Report" &&
													page.status == true
												) {
													return (
														<li className="" key={index}>
															<NavLink
																to={"/reassign-report"}
																activeClassName="active"
															>
																{page.page_name}
															</NavLink>
														</li>
													);
												}
												else if (
													page.page_name === "Video Report" &&
													page.status == true
												) {
													return (
														<li className="" key={index}>
															<NavLink
																to={"/videoreport"}
																activeClassName="active"
															>
																{page.page_name}
															</NavLink>
														</li>
													);
												}
												else if (
													page.page_name === "Express Report" &&
													page.status == true
												) {
													return (
														<li className="" key={index}>
															<NavLink
																to={"/express-report"}
																activeClassName="active"
															>
																{page.page_name}
															</NavLink>
														</li>
													);
												}
											})}

											{role_id == 3 && (
												<>
													<li className="">
														<NavLink to={"/dashboard"} activeClassName="active">
															Dashboard
														</NavLink>
													</li>
												</>
											)}
										</ul>
									)}
								</li>
							)}

							<li
								data-username="widget statistic data chart"
								className="nav-item"
							>
								<NavLink to={"/query"}>
									<span className="pcoded-micon">
										<i className="feather icon-file-text" />
									</span>
									<span className="pcoded-mtext">Query</span>
								</NavLink>
							</li>

							{this.state.pageDynamic.map((page: any, index: any) => {
								if (page.page_name === "Global Search" && page.status == true) {
									return (
										<>
											<li
												data-username="widget statistic data chart"
												className="nav-item"
												key={index}
											>
												<NavLink to={"/branch-search"}>
													<span className="pcoded-micon">
														<i className="feather icon-search" />
													</span>
													<span className="pcoded-mtext">Global Search</span>
												</NavLink>
											</li>
										</>
									);
								}
								return null;
							})}

							{role_id !== FRONTOFFICE_ROLE && (
								<li
									data-username="widget statistic data chart"
									className="nav-item pcoded-hasmenu"
								>
									<a onClick={this.toggleExternalMenu}>
										<span className="pcoded-micon">
											<i className="feather icon-link-2" />
										</span>
										<span className="pcoded-mtext">External References</span>
									</a>
									{this.state.openExternalMenu && (
										<ul className="pcoded-submenu">
											{this.state.externalLinks &&
												this.state.externalLinks.length > 0 ? (
												this.state.externalLinks.map(
													(extLink: any, extIndex: number) => {
														return (
															<li key={extIndex} className="nav-item">
																<a href={extLink.link} target="_blank">
																	<i className="feather icon-external-link">
																		{" "}
																		&nbsp; {extLink.name}
																	</i>
																</a>
															</li>
														);
													}
												)
											) : (
												<li>No Links Found</li>
											)}
										</ul>
									)}
								</li>
							)}
							<li
								data-username="widget statistic data chart"
								className="nav-item pcoded-hasmenu"
							>
								<a onClick={this.toggleComplaintMenu}>
									<span className="pcoded-micon">
										<i className="feather  icon-server" />
									</span>
									<span className="pcoded-mtext">Complaint Box</span>
								</a>
								{this.state.openComplaintMenu && (
									<ul className="pcoded-submenu">
										<li className="">
											<NavLink
												to={"/register-complaint"}
												activeClassName="active"
											>
												Register Complaint
											</NavLink>
										</li>
										{user_id == "1" || user_id == "242" || user_id == "401" ? (
											<li className="">
												<NavLink
													to={"/all-complaints"}
													activeClassName="active"
												>
													Complaint List
												</NavLink>
											</li>
										) : (
											""
										)}
									</ul>
								)}
							</li>
							<li
								data-username="widget statistic data chart"
								className="nav-item pcoded-hasmenu"
							>
								<a onClick={this.toggleNotificationMenu}>
									<span className="pcoded-micon">
										<i className="feather  icon-server" />
									</span>
									<span className="pcoded-mtext">Notice Board</span>
								</a>
								{this.state.openNotificationMenu && (
									<ul className="pcoded-submenu">
										{user_id && ["146", "229", "173"].includes(user_id) ? (
											<li className="">
												<NavLink
													to={"/notification/add"}
													activeClassName="active"
												>
													Send Notice
												</NavLink>
											</li>
										) : null}
										{isAdmin ? (
											<li className="">
												<NavLink to={"/livetracking"} activeClassName="active ">
													Live Track
												</NavLink>
											</li>
										) : null}

										<li className="">
											<NavLink
												to={"/all-notifications"}
												activeClassName="active"
											>
												Notice List
											</NavLink>
										</li>
										{/* <li className="">
											<NavLink
												to={"/all-Pushnotifications"}
												activeClassName="active"
											>
												Today Activity
											</NavLink>
										</li> */}
									</ul>
								)}
							</li>
							{role_id == FRONTOFFICE_ROLE && (
								<li
									data-username="widget statistic data chart"
									className="nav-item pcoded-hasmenu"
								>
									<a onClick={this.toggleVisitorsMenu}>
										<span className="pcoded-micon">
											<i className="feather  icon-server" />
										</span>
										<span className="pcoded-mtext">Visitor</span>
									</a>
									{this.state.openVisitorsMenu && (
										<ul className="pcoded-submenu">
											{/* {user_id && ["146", "229", "173"].includes(user_id) ? ( */}
											<li className="">
												<NavLink to={"/visitor/add/"} activeClassName="active">
													Add New Visitor
												</NavLink>
												<li className="">
													<NavLink
														to={"/visitor-list"}
														activeClassName="active "
													>
														Visitor's List
													</NavLink>
												</li>
											</li>
											{/* ) : null} */}
										</ul>
									)}
								</li>
							)}
							{/* <li
								data-username="widget statistic data chart"
								className="nav-item"
							>
								<NavLink to={"/vetting-report-template"}>
									<span className="pcoded-micon">
										<i className="feather icon-file-text" />
									</span>
									<span className="pcoded-mtext">Vetting Report Template</span>
								</NavLink>
							</li> */}
							{/* <li
								data-username="widget statistic data chart"
								className="nav-item"
							>
								<NavLink to={"/generate-report"}>
									<span className="pcoded-micon">
										<i className="feather icon-file-text" />
									</span>
									<span className="pcoded-mtext">Vetting Report Generate</span>
								</NavLink>
							</li> */}
						</ul>
					</div>
				</div>
			</nav>
		);
	}
}

export default Sidebar;
