import React from "react";
import ExcelJS from "exceljs";

const Excelbankbranchreport = ({ data }) => {

  const exportExcelFile = async () => {
    if (!Array.isArray(data)) {
      console.error("Data is not an array:", data);
      return;
    }

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");

    // Define columns with headers
    worksheet.columns = [
      { header: "S.No", key: "s_no", width: 10 },
      // { header: "ID", key: "id", width: 10 },
      { header: "LH Branch", key: "lh_branch_name", width: 15 },
      { header: "Bank", key: "bank_name", width: 25 },
      { header: "Product", key: "product_name", width: 10 },
      { header: "Branch", key: "name", width: 15 },
      { header: "Address", key: "address", width: 40 },
      { header: "Login Fees", key: "login_fees", width: 15 },
      { header: "Vetting Fees", key: "vetting_fees", width: 15 },
      { header: "Complete Fees", key: "complete_fees", width: 15 },
      { header: "Name", key: "bro_name", width: 20 },
      { header: "Designation", key: "bro_designation", width: 20 },
      { header: "Mobile Number", key: "bro_mobile", width: 20 },
      { header: "Email", key: "bro_email", width: 50 },
    ];

    // Apply alignment and color to header cells
    worksheet.getRow(1).eachCell((cell) => {
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" }, // Yellow color
      };
      cell.font = { bold: true, size: 14 };
    });
    
    const flattenedData = data.map((file, index) => {
    const ids = [];
    const names = [];
    const designations = [];
    const mobiles = [];
    const emails = [];

// Check if file.rl is an array before processing
// if (Array.isArray(file.rl)) {
//     file.rl.forEach((person, index) => {
//         // Always add the index, even if the field is empty
//         ids.push(`${index + 1}. ${person?.id || "-"}`);
//         names.push(`${index + 1}. ${person?.name?.trim() || "-"}`);
//         designations.push(`${index + 1}. ${person?.designation?.trim() || "-"}`);
//         mobiles.push(`${index + 1}. ${person?.mobile || "-"}`);
//         emails.push(`${index + 1}. ${person?.email?.trim() || "-"}`);
//     });
// }

      return {
        s_no: index+1,
        // id: file.id,
        lh_branch_name: file.lh_branch_name,
        bank_name: file.bank_name,
        name: file.name,
        address: file.address,
        product_name: file.product_name,
        complete_fees: file.complete_fees,
        vetting_fees: file.vetting_fees,
        login_fees: file.login_fees,
        bro_name: file.bro_name,
        bro_designation: file.bro_designation,
        bro_mobile: file.bro_mobile,
        bro_email: file.bro_email,
        // names: names.join("\n"),
        // designations: designations.join("\n"),
        // mobiles: mobiles.join("\n"),
        // emails: emails.join("\n"),
      };
    });

    // Add data rows with custom cell alignment and wrap text
    flattenedData.forEach((item) => {
      const row = worksheet.addRow(item);
      row.eachCell((cell) => {
        cell.alignment = {
          vertical: "middle",
          horizontal: "left",
          wrapText: true,
        };
      });
    });
    const currentDate = new Date();
    const formattedDate = `${currentDate
      .getDate()
      .toString()
      .padStart(2, "0")}-${(currentDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${currentDate.getFullYear()}`;
    // Generate Excel file buffer
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = `Bank Branch Management ${formattedDate}.xlsx`;
    anchor.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <>
      <button className="excel-download-btn" onClick={exportExcelFile}>
        Download as Excel
      </button>
    </>
  );
};

export default Excelbankbranchreport;
