import React, { Component } from "react";
import { Link } from "react-router-dom";
import { callApi } from "../../utils/api";
import Select from "react-select";
import DocumentNumber from "./DocumentNumber";
import ReactFileReader from "react-file-reader";
import {
  FRONTOFFICE_ROLE,
  EXECITUVE_ROLE,
  MANAGER_ROLE,
  ADMIN_ROLE,
} from "../../utils/role";
import {
  ToastsContainer,
  ToastsContainerPosition,
  ToastsStore,
} from "react-toasts";
import { FaRegEdit } from "react-icons/fa";
import { getFileStatus } from "../../utils/fileStatus";
import QueryTree from "./QueryTree";
import { cloneDeep } from "lodash";
import moment from "moment";
import { saveAs } from "file-saver";
import { notifyToUser } from "../../utils/firebase";
import { pushnotificationApi } from "../../utils/pushNotify";
import UploadDocument from "./uploadDocument";
import DashUploadDocument from "./DashUploadDocument";
import CountdownTimer from "./CountdownTimer";
import App from "../../App";
import { isEmpty, isNaN, isNumber } from "lodash";
//new qeury table start
import QueryTable from "./QueryTable";
import QueryAdd from "./QueryAdd";
//new qeury table end
import "../../components/file/filedash.css";
import { FaRegFilePdf } from "react-icons/fa6";
import { PiFileDocDuotone } from "react-icons/pi";
import { FaEye } from "react-icons/fa";
import { toast } from "react-toastify";
declare var $: any;
const particularList = [
  {
    label: "Original",
    value: "original",
  },
  {
    label: "Xerox",
    value: "xerox",
  },
];
export interface DocumentDetail {
  total: number;
  yettoopen: number;
  submitted: number;
  inprogress: number;
}
//new qeury table start
interface OriginalObject {
  id: string;
  name: string;
  parent_id: string;
  title: string;
  raice_by: string;
  to: string;
  status: string;
  reason: string;
  d_request_reason: string;
  d_officer_reason: string;
  deviation_status: string;
  location: string;
  created_date: string;
  serial_no: string | number; // Update this line
  children: OriginalObject[];
}

interface FlattenedObject {
  id: string;
  name: string;
  parent_id: string;
  title: string;
  raice_by: string;
  to: string;
  status: string;
  reason: string;
  d_request_reason: string;
  d_officer_reason: string;
  deviation_status: string;
  location: string;
  created_date: string;
  serial_no: string | number;
}
interface QueryItem {
  query: string;
  location: string;
  review: string;
  other_review: string;
  created_date: string;
  is_checked: boolean;
}
interface ReQueryItem {
  query: string;
  location: string;
  review: string;
  query_id: string;
  other_review: string;
  created_date: string;
}
//new qeury table end
interface AllProps {
  match: any;
  history: any;
}
interface RectificationData {
  id: string;
  file_id: string;
  reason: string;
  status: string;
  created_by: string;
  created_dt: string;
  rectify_status: string;
  count: string;
  completed_dt: string;
}
class FileDashboard extends Component<AllProps> {
  textareaRef: React.RefObject<any>;
  constructor(props) {
    super(props);
    this.textareaRef = React.createRef();
  }
  query: any = React.createRef();
  startTime: any;
  documentFormat = {
    base: "",
    base_url: "",
    docNoYear: "",
    particular: "xerox",
    type: "",
    isValid: true,
    doc_type: "",
  };
  state = {
    showModal: false,
    base: [],
    docToPreview: "",
    type: "",
    workfileEnable: true,
    // intime:"",
    inreasonRE: "",
    selectVal: false,
    queryRecived: "",
    setResponse: true,
    showThepopup: true,
    other: {},
    ec_doc: {},
    rev_rec_doc: {},
    queries: [],
    file_status: "0",
    info: {
      verify_officer: "",
      deviation_officer: "",
      process_officer: "",
      app_id: "",
      doc_request: "",
      file_name: "",
      file_status: "",
      file_grade: "",
      verify: "",
      report_status: "",
      pending: "",
      closed: "",
      total: "",
      bank_name: "",
      product_name: "",
      login_time: "",
      opinion_type: "",
      vtaken_status: "",
      taken_status: "",
      hold_date: "",
      noquery_status: "",
      rectify_noquery_status: "",
      rectify_status: "",
    },
    reason: "",
    step: 0,
    bank_templates: [],
    bank_id: 0,
    isbtnload: false,
    office_users: [],
    remote_users: [],
    remote_cc_users: [],
    multiSelectedQuery: [],
    repliedQueries: [],
    queryBase: [],
    isNewFile: false,
    queryPayload: {
      requery: [],
      resend: [],
    },
    previewDoc: "",
    queryLoading: false,
    queryQues: [],
    oldQueryQues: [],
    quesSubmitted: false,
    isFlowChartUploading: false,
    flowChartPreview: "",
    updateDownloadData: {
      reasonForDownload: [],
      updatedDetails: [],
    },
    action: "",
    uploadFile: {
      base64: "",
      fileList: [{ name: "" }],
    },
    isReasonForDownloadShown: false,
    isUpdateDownloadShown: false,
    date: "",
    hours: 0,
    minutes: 0,
    seconds: 0,
    intime: 0,
    curentDate: 0,
    in_reason: "",
    outreason: "",
    docNoYear: "",
    categoriesList: [],
    categoriesType: "",
    particular: "",
    newquery: [],
    uploadbtn: false,
    initialTimeInMinutes: 0,
    updatedRemainingTime: 0,
    doc_count: 0,
    blockdownload: "",
    showTitle: true,
    iscomplete: false,
    isprocesscomplete: false,
    reasons: [],
    query_tree_length: 0,
    isLoading: false,
    deeds: [],
    holdDetails: [],
    //new qeury table start
    isRaiseQueryShown: false,
    newQueryList: [] as QueryItem[],
    reQueryList: [] as ReQueryItem[],
    queryTableData: [],
    showPopup: false,
    selectedQueryname: "",
    selectedQuerytitle: "",
    selectedQueryid: "",
    //new qeury table end
    deviationTakenQuerys: [],
    isdeviationLoading: false,
    deviationReason: "",
    isverifycomplete: false,
    isrectifycomplete: false,
    Rectify_files: [] as RectificationData[],
    isFileRefresh: false,
    isrectificationComplete: true,
    LHBranchCode: "",
    pdfToPreview: "",
    pageDynamic: [],
    fileNotCompleteMsg: { __html: "" },
  };

  componentDidMount() {
    this.getBranchCode();
    this.getPageDynamicData();
    this.blockdownload();
    this.getQueryTree();
    this.getFileDashboard();
    this.listreason();
    this.getQueryTable();
    // this.getQueries();
    this.getStepStatus();
    this.getDeeds();
    this.getRectifyFiles();
    if (localStorage.getItem("BankTemplate")) {
      var bankTemp: any = localStorage.getItem("BankTemplate");
      this.setState({ bank_templates: JSON.parse(bankTemp) });
    } else {
      this.getTemplates();
    }
    this.getInTime();
    window.addEventListener("beforeunload", this._confirm);
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = this._backConfirm;
    sessionStorage.setItem(
      "currentfileId",
      JSON.stringify(this.props.match.params.id)
    );
    sessionStorage.setItem("dashboard_status", "1");
    var inreasonRE: any = localStorage.getItem("in_reason");
    this.setState({ inreasonRE: JSON.parse(inreasonRE) });
    const isPopupFromWIP: any = sessionStorage.getItem("isPopupFromWIP");
    if (isPopupFromWIP == "true") {
      this.checkProcessFile();
    }
    window.addEventListener("popstate", this.handlePopState);
  }

  countdownTimerRef: CountdownTimer | null = null;
  appRef: App | null = null;

  handlePopState = (type?: any) => {
    console.log("Navigated via browser back button");
    setTimeout(() => {
      const fileurl: any = localStorage.getItem("url");
      if (fileurl == "") {
        sessionStorage.removeItem("currentfileId");
        sessionStorage.setItem("returnBack", JSON.stringify(true));
        $("#in_time").modal("hide");
        this.props.history.push("/inprogress-file");
      } else if (type == "back") {
        this.props.history.push(fileurl);
      }
    }, 1500);
  };

  componentWillUnmount() {
    if (this.countdownTimerRef) {
      this.countdownTimerRef.clearInterval();
    }
    if (this.appRef) {
      this.appRef.getTimerZero();
    }
    var inreasonRe = localStorage.getItem("in_reason");
    if (inreasonRe) {
      window.removeEventListener("beforeunload", this._confirm);
      window.onpopstate = this._confirm;
    }
    const process_office_id: any = sessionStorage.getItem("process_office_id");
    const userid1: any = sessionStorage.getItem("user1");
    if (process_office_id == userid1) {
      this.timeUpdate(this.props.match.params.id, "file_dash_out");
    } else {
    }
    localStorage.setItem("from_deviation", JSON.stringify(false));
  }
  blockdownload = () => {
    callApi("POST", "block_download_status.php", {
      file_idfk: this.props.match.params.id,
    })
      .then((res) => res.data)
      .then((response) => {
        this.setState({
          blockdownload: response["responseJson"].data[0].block_download_status,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  getRectifyFiles = () => {
    callApi("POST", "get_rectify_data.php", {
      file_id: this.props.match.params.id,
    })
      .then((res) => res.data)
      .then((response) => {
        const Rectify_files = response.data;
        this.setState({ Rectify_files, isFileRefresh: true });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  getBranchCode = () => {
    callApi("POST", "get_branch_code.php", {
      id: this.props.match.params.id,
    })
      .then((res) => res.data)
      .then((response) => {
        const branchCode = response.data[0].lawhands_branch;
        this.setState({ LHBranchCode: branchCode });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  getPageDynamicData = () => {
    const user: any = sessionStorage.getItem("user");
    const userDetails = user ? JSON.parse(user) : "";
    let obj = {
      roleId: userDetails ? userDetails.role_id : null,
      userId: userDetails ? userDetails.userId : null,
      roleType: userDetails ? userDetails.roleType : null,
    };
    callApi("POST", "authenticating_pages_list_new.php", obj)
      .then((res) => res.data)
      .then((result) => {
        this.setState({ pageDynamic: result.responseJson.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  getQueryTable = () => {
    let req = { fod_fk: this.props.match.params.id };
    callApi("POST", "query_table_test.php", req)
      .then((res) => res.data)
      .then(async (response) => {
        if (response) {
          const data = response.result;
          const flatData: any = await this.flattenData(data);
          const flattenedJson: FlattenedObject[] = await this.flattenObjects(
            flatData
          );
          this.setState({ queryTableData: flattenedJson });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  flattenData = (data) => {
    const result: FlattenedObject[] = [];
    const flatten = (node) => {
      const { children, ...rest } = node;
      result.push({ ...rest, children: [] });
      if (children && children.length > 0) {
        children.forEach((child) => flatten(child));
      }
    };
    data.forEach((item) => flatten(item));
    return result;
  };
  flattenObjects(originalObjects: OriginalObject[]): FlattenedObject[] {
    const flattened: FlattenedObject[] = [];
    const flatten = (object: OriginalObject) => {
      if (object.status != "3" && object.deviation_status != "0") {
        flattened.push({
          id: object.id,
          name: object.name,
          parent_id: object.parent_id,
          title: object.title,
          raice_by: object.raice_by,
          to: object.to,
          status: object.status,
          reason: object.reason,
          location: object.location,
          created_date: object.created_date,
          serial_no: object.serial_no,
          d_request_reason: object.d_request_reason,
          d_officer_reason: object.d_officer_reason,
          deviation_status: object.deviation_status,
        });

        if (object.children && object.children.length > 0) {
          object.children.forEach((child) => flatten(child));
        }
      }
    };
    originalObjects.forEach((obj) => flatten(obj));
    return flattened;
  }
  getDeeds = () => {
    callApi("POST", "deed_master_get.php", { category: this.state.type })
      .then((res) => res.data)
      .then((response) => {
        if (response.data && response.data.length) {
          const deeds = response.data;
          this.setState({ deeds });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  getQueryTree = () => {
    let req = { fod_fk: this.props.match.params.id };
    callApi("POST", "query_tree.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response) {
          this.setState({ query_tree_length: response.length });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  getFileAssignStatus() {
    callApi("POST", "get_file_assign_status.php", {
      file_id: this.props.match.params.id,
    })
      .then((res) => res.data)
      .then((response) => {
        const files = response.data;
        this.setState({ fileAssignStatus: files });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  listreason = () => {
    this.setState({ isFileRefresh: false });
    callApi("GET", "query_reason_get.php")
      .then((res) => res.data)
      .then((response) => {
        const files = response.data;
        this.setState({ reasons: files });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  OPenToaster = () => {
    ToastsStore.success("Please Select The Bank Template");
  };
  _backConfirm = async () => {
    // let event: any = window.confirm("Do You Want Leave This Page");
    // if (event) {
    //   window.history.pushState(null, "", window.location.href);
    // }
  };
  getCategories = () => {
    callApi("POST", "category.php")
      .then((res) => res.data)
      .then((response) => {
        this.setState({ categoriesList: response.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  _confirm = (e) => {
    console.log("its working");
    var confirmationMessage = "o/";
    e.returnValue = confirmationMessage;
    return confirmationMessage;
  };
  previousPop = () => {
    $("#in_time").modal("show");
    $("#another-reason").modal("hide");
  };
  previousPage = (type?: any) => {
    const fileurl: any = localStorage.getItem("url");
    $("#in_time").modal("hide");
    if (type == "autoback") sessionStorage.removeItem("currentfileId");
    localStorage.removeItem("thisPopup");
    sessionStorage.removeItem("isPopupFromWIP");
    this.props.history.push(`${fileurl}`);
    localStorage.removeItem("url");
    if (type == "back") {
      localStorage.setItem("filedashboardback", "true");
      sessionStorage.removeItem("currentfileId");
    }
  };
  getInTime = () => {
    if (localStorage.getItem("thisPopup")) {
      $("#in_time").modal("show");
      let date = new Date();
      let curentDate = date.toLocaleDateString();
      localStorage.setItem("Intimedate", JSON.stringify(curentDate));
      var Railway = date.getMinutes();
      if (Railway < 10) {
        var TotalMinutes = "0" + Railway;
        let intime = date.getHours() + ":" + TotalMinutes;
        localStorage.setItem("IntimeGet", JSON.stringify(intime));
      } else {
        let intime: any = date.getHours() + ":" + date.getMinutes();
        this.setState({ intime: intime });
        localStorage.setItem("IntimeGet", JSON.stringify(intime));
      }
      let intime = date.getHours() + ":" + date.getMinutes();
      localStorage.setItem("IntimeGet", JSON.stringify(intime));
    } else {
      $("#in_time").modal("hide");
    }
  };
  SubmitREsponse = () => {
    localStorage.removeItem("filedashboardback");
    if (this.state.in_reason == "") {
      this.setState({ selectVal: true });
    } else {
      localStorage.setItem("filenumber", this.state.info.file_name);
      localStorage.setItem("inreasonlive", this.state.in_reason);
      localStorage.removeItem("thisPopup");
      const isPopupFromWIP: any = sessionStorage.getItem("isPopupFromWIP");
      if (isPopupFromWIP == "true") {
        this.checkProcessFile("onSubmit");
      } else {
        this.liveinsert();
      }
      let in_reason = this.state.in_reason;
      localStorage.setItem("in_reason", JSON.stringify(in_reason));
    }
  };
  checkProcessFile = (type?: any) => {
    const isPopupFromWIP: any = sessionStorage.getItem("isPopupFromWIP");
    const user: any = sessionStorage.getItem("user");
    const userId = user ? JSON.parse(user)["userId"] : "";
    let payload = {
      user_id: userId,
      file_no: this.props.match.params.id,
      ...(isPopupFromWIP && { flag: isPopupFromWIP }),
    };
    callApi("POST", "checkAutoAssignFile.php", payload)
      .then((res) => res.data)
      .then((response) => {
        if (response.response_Code == 200) {
          if (type == "onSubmit") this.liveinsert();
          sessionStorage.removeItem("isPopupFromWIP");
        } else {
          sessionStorage.removeItem("isPopupFromWIP");
          toast.error(response.message, {
            autoClose: 5000,
          });
          setTimeout(() => {
            this.previousPage("autoback");
          }, 2000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  liveinsert = () => {
    this.setState({ isLoading: true });
    const user: any = sessionStorage.getItem("user");
    const role_id = user ? JSON.parse(user)["team_type"] : "";
    const userId = user ? JSON.parse(user)["userId"] : "";
    const role = user ? JSON.parse(user)["teamType"] : "";
    const role_type = user ? JSON.parse(user)["roleType"] : "";
    const user_name = user ? JSON.parse(user)["userName"] : "";
    const bankName: any = localStorage.getItem("bankName");
    localStorage.setItem("fileNamCode", this.state.info.file_name);
    // const in_time : any = localStorage.getItem("IntimeGet");
    // const in_reason : any = localStorage.getItem("in_reason");
    let dateTime = new Date();
    let curentTime = dateTime.toLocaleTimeString();
    const textRay = `Currently working on File No : ${this.props.match.params.id}`;
    let obj = {
      file_no: this.state.info.file_name,
      in_time: curentTime,
      reason: this.state.in_reason,
      user_id: userId,
      user_name: user_name,
      team_type: role,
      role_type: role_type,
      working_process: textRay,
      bank_name: bankName,
    };
    callApi("POST", "live_track_insert.php", obj)
      .then((res) => res.data)
      .then((response) => {
        $("#in_time").modal("hide");
        $("#another-reason").modal("hide");
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        console.log(err);
        ToastsStore.error("Your Reason Not Stored");
        this.setState({ isLoading: false });
      });
  };
  onchangeReason = (e: any) => {
    if (e.target.value == "other") {
      // this.textareaRef.current.focus();
      this.setState(
        {
          showModal: true,
        },
        () => {
          this.textareaRef.current.focus();
        }
      );
      this.setState({ in_reason: "", selectVal: false });
      $("#in_time").modal("hide");
      $("#another-reason").modal("show");
    } else if (e.target.value == "select") {
      this.setState({ setResponse: false });
    } else {
      // this.setState({})
      this.setState({ [e.target.name]: e.target.value, selectVal: false });
    }
  };
  getFlowChart = () => {
    callApi("POST", "get_flow_chart.php", {
      fileId: this.props.match.params.id,
    })
      .then((res) => res.data)
      .then((response) => {
        if (response.data && response.data[0]) {
          this.setState({
            flowChartPreview: response.data[0].fileContent || "",
          });
        } else {
          this.setState({ flowChartPreview: "" });
        }
      });
  };
  getQueries = () => {
    callApi("POST", "query_get.php", { fod_fk: this.props.match.params.id })
      .then((res) => res.data)
      .then((response) => {
        this.setState({ queries: response.data });
        $(document).ready(function () {
          $("#simpletable").DataTable();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  getUsers = () => {
    const remote_users: any = [];
    callApi("POST", "office_details.php", {
      id: this.props.match.params.id,
    })
      .then((res) => res.data)
      .then((response) => {
        response.data.map((user) => {
          remote_users.push({ email: user.email, name: user.name });
        });
        this.setState({
          office_users: response.data,
          remote_users,
          remote_cc_users: remote_users,
        });
      });
  };
  getQueryQuestion = () => {
    callApi("POST", "query_question_get.php")
      .then((res) => res.data)
      .then((response) => {
        this.setState({
          queryQues: response.data,
          oldQueryQues: JSON.parse(JSON.stringify(response.data)),
        });
      });
  };
  getTemplates = () => {
    callApi("POST", "bank_temp_get.php")
      .then((res) => res.data)
      .then((response) => {
        let banks = response.data;
        const vetting = banks.filter(
          (item) => item.name.split(" ").pop() != "Vetting"
        );
        this.setState({ bank_templates: vetting });
        var bankTempName = [];
        bankTempName = vetting;
        localStorage.setItem("BankTemplate", JSON.stringify(bankTempName));
        // console.log(localStorage.getItem("BankTemplate"));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  initDocumentDetail = () => {
    return {
      total: 0,
      yettoopen: 0,
      submitted: 0,
      inprogress: 0,
    };
  };
  getFileDashboard = () => {
    const userid1: any = sessionStorage.getItem("user1");
    const user_id = JSON.parse(userid1);
    callApi("POST", "file_dash.php", {
      branch_idfk: this.props.match.params.id,
      user_id: user_id,
    })
      .then((res) => res.data)
      .then((response) => {
        const state = response.data;
        const process_officer: any =
          sessionStorage.getItem("process_office_id");
        const userid1: any = sessionStorage.getItem("user1");
        const take_file: any = sessionStorage.getItem("takenfile_id");
        const user: any = sessionStorage.getItem("user");
        // const taken_file = user ? JSON.parse(user)["taken_file"] : take_file;
        const getfileId: any = sessionStorage.getItem("currentfileId");
        if (response.info && response.info.length) {
          if (process_officer == userid1 && take_file == getfileId) {
            if (response.info[0].target_min == 0) {
              $("#balance-time").modal("show");
            } else {
              let initialTimeInMinutes: any = sessionStorage.getItem(
                "updatedRemainingTime"
              );
              if (initialTimeInMinutes > 0) {
                this.setState(
                  (prevState: any) => ({
                    initialTimeInMinutes: initialTimeInMinutes,
                  }),
                  () => {}
                );
              } else if (response.info) {
                this.setState(
                  {
                    initialTimeInMinutes: response.info[0].target_min,
                  },
                  () => {}
                );
              } else {
                this.setState(
                  {
                    initialTimeInMinutes: 0,
                  },
                  () => {}
                );
              }
            }
          }
          state.info = response.info[0];
          state.bank_id = state.info.bank_id;
          sessionStorage.setItem("file_d_grade", state.info.file_d_grade);
          sessionStorage.setItem("user_d_grade", state.info.user_d_grade);
          sessionStorage.setItem(
            "mail-bank",
            JSON.stringify(state.info.mail_to_bank)
          );
          this.setState({ doc_count: response.info[0].doc_count });
          if (state.bank_id == 0) {
            this.setState({ workfileEnable: true });
          } else {
            this.setState({ workfileEnable: false });
          }
        }
        this.setState({ ...state });
        $(document).ready(function () {
          $("#simpletable").DataTable();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  getStepStatus = () => {
    callApi("POST", "file_status_steps.php", { id: this.props.match.params.id })
      .catch((res) => res.data)
      .then((response) => {
        if (response.data.step) {
          this.setState({ step: response.data.step });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  confirmUpdateFileStatus = (file_status) => {
    if (this.state.info.file_status === "0") {
      this.setState({ reason: "" });
      $("#reason-modal").modal("show");
      const info = this.state.info;
      info.file_status = file_status;
      this.setState({ info });
      return;
    } else {
      file_status = this.state.info.file_status === "0" ? file_status : "0";
      this.updateFileStatus(file_status);
    }
  };
  submitReason = () => {
    const user: any = sessionStorage.getItem("user");
    const userId = user ? JSON.parse(user)["userId"] : "";
    callApi("POST", "reason_create.php", {
      userId,
      action: this.state.info.file_status === "3" ? "hold" : "reject",
      reason: this.state.reason,
      fod_fk: this.props.match.params.id,
    })
      .then((res) => res.data)
      .then((response) => {
        $("#reason-modal").modal("hide");
        this.updateFileStatus(this.state.info.file_status);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  updateFileStatus = (file_status) => {
    callApi("POST", "file_status.php", {
      id: this.props.match.params.id,
      file_status,
    })
      .then((res) => res.data)
      .then((response) => {
        this.getFileDashboard();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  modifyQueryStatus = (modal) => {
    const user: any = sessionStorage.getItem("user");
    const userId = user ? JSON.parse(user)["userId"] : "";
    callApi("POST", "query_close.php", {
      id: modal.query_id,
      status: modal.query_status === "Pending" ? 3 : 0,
      userId,
    })
      .then((res) => res.data)
      .then((response) => {
        // this.getQueries();
        this.getFileDashboard();
        this.getQueryTable();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleChange = (e, propName?, fieldType?) => {
    let fieldName = fieldType === "singleselect" ? propName : e.target.name;
    let fieldValue = fieldType === "singleselect" ? e.value : e.target.value;
    this.setState({ [fieldName]: fieldValue });
    if (fieldName !== "reason") {
      this.saveTemplate(fieldValue);
    }
  };
  handleChange1 = (e: any) => {
    const info: any = this.state.info;

    info[e.target.name] = e.target.value;
    this.setState({});
  };
  handleChange2 = (e: any) => {
    const info: any = this.state.info;

    info[e.target.name] = e.target.value;
    this.setState({});
  };
  saveDocNo = (e: any) => {
    this.setState({ docNoYear: e.target.value });
  };
  saveTemplate = (val) => {
    callApi("POST", "bank_temp_save.php", {
      id: this.props.match.params.id,
      bank_id: val,
    })
      .then((res) => res.data)
      .then((response) => {
        this.setState({ workfileEnable: false });
        localStorage.removeItem("BankTemplate");
        sessionStorage.removeItem("overviewDocument");
        this.getQueries();
        this.getFileDashboard();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  uploadFlowchart = (files: any, type: string) => {
    this.setState({ isFlowChartUploading: true });
    const base: any = [];
    files.base64.map((file: any, index: number) => {
      base.push({ base: file, details: files.fileList[index], type });
    });
    if (files.base64.length) {
      callApi("POST", "flow_chart_add.php", {
        fileId: this.props.match.params.id,
        fileName: files.fileList[0].name,
        fileContent: base[0].base.split(",")[1],
      })
        .then((res) => {
          this.setState({ isFlowChartUploading: false }, () => {
            this.getFlowChart();
          });
          ToastsStore.success("File(s) Uploaded Successully");
        })
        .catch((err) => {
          this.setState({ isFlowChartUploading: false });
        });
    }
  };
  deleteFlowChart = () => {
    const ParamId = this.props.match.params.id;
    this.setState({ isFlowChartUploading: true });
    callApi("POST", "delete_flow_chart.php", {
      fileId: ParamId,
    })
      .then((res) => {
        this.setState({ isFlowChartUploading: false }, () => {
          this.getFlowChart();
        });
        ToastsStore.success("File(s) Deleted Successully");
      })
      .catch((err) => {
        this.setState({ isFlowChartUploading: false });
      });
  };
  uploadFiles = () => {
    const isValid = this.validateDocuments();
    let updatedRemainingTime: any = sessionStorage.getItem(
      "updatedRemainingTime"
    );
    let updatedtime = Math.round(updatedRemainingTime);
    if (!isValid) {
      // Show error message or handle validation error
      ToastsStore.error("Please fill in all fields for this document");
      return;
    } else {
      this.setState({ isbtnload: true });
      this.setState({ uploadbtn: true });
      const user: any = sessionStorage.getItem("user");
      const userId = user ? JSON.parse(user)["userId"] : "";
      let flag_status;
      if (this.state.info.file_status == "7") {
        flag_status = 1;
      } else {
        flag_status = 0;
      }
      callApi("POST", "doc_file_upload.php", {
        id: this.props.match.params.id,
        uploadfile: this.state.base,
        flag_status: flag_status,
        type: "process",
        userId,
      })
        .then((res) => res)
        .then((response) => {
          const responseData = response.data;
          let doc_count =
            Number(this.state.base.length) + Number(this.state.doc_count);
          if (responseData.success === true) {
            let request = {
              file_id: this.props.match.params.id,
              doc_count: doc_count,
              balance_min: updatedtime,
            };
            callApi("POST", "file_time.php", request)
              .then((res) => res.data)
              .then((response) => {
                if (response.status == "200") {
                  if (this.countdownTimerRef) {
                    this.countdownTimerRef.clearInterval();
                  }
                  sessionStorage.removeItem("updatedRemainingTime");
                  window.location.reload();
                }
              });
            if (this.state.info.file_status == "7") {
              this.timeUpdate(this.props.match.params.id, "query receive");
            } else {
              this.timeUpdate(this.props.match.params.id, "before query raise");
            }
            this.setState({ isbtnload: false });
            this.setState({ uploadbtn: false });
            this.setState({ base: [] });
            $("#upload-modal").modal("hide");
            const user: any = sessionStorage.getItem("user");
            const userId = user ? JSON.parse(user)["userId"] : "";
            const userName = user ? JSON.parse(user)["firstName"] : "";
            const notifyData = {
              fileId: this.props.match.params.id,
              title: `${this.props.match.params.id}Query Upload`,
              notifyType: "message",
              updatedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
            };
            let user_id = userId;
            pushnotificationApi(notifyData, user_id);
            const branchManger: any = sessionStorage.getItem("branch_manager");
            const branch_Manager = JSON.parse(branchManger);
            const queryOfficer: any = sessionStorage.getItem("Query_officer");
            const query_ooficer = JSON.parse(queryOfficer);
            const Scan_officer: any = sessionStorage.getItem("scan_officer");
            const sacanofficer = JSON.parse(Scan_officer);
            const process_mennager: any =
              sessionStorage.getItem("process_manager");
            const processManager = JSON.parse(process_mennager);
            if (Array.isArray(branch_Manager) && branch_Manager.length > 0) {
              notifyToUser(
                "user",
                { ...notifyData, notifyType: "message" },
                branch_Manager
              );
              const branchManager = branch_Manager.join(",");
              let obj = {
                // notification_type:"queryupload",
                user_id: branchManager,
                ...notifyData,
              };
              callApi("POST", "pushnotification.php", obj)
                .then((res) => res.data)
                .then((response) => {})
                .catch((err) => {
                  console.log(err);
                });
            }
            if (Array.isArray(query_ooficer) && query_ooficer.length > 0) {
              notifyToUser(
                "user",
                { ...notifyData, notifyType: "message" },
                query_ooficer
              );
              const Queryofficer = query_ooficer.join(",");
              let obj = {
                // notification_type:"queryupload",
                user_id: Queryofficer,
                ...notifyData,
              };
              callApi("POST", "pushnotification.php", obj)
                .then((res) => res.data)
                .then((response) => {})
                .catch((err) => {
                  console.log(err);
                });
            }
            if (Array.isArray(sacanofficer) && sacanofficer.length > 0) {
              notifyToUser(
                "user",
                { ...notifyData, notifyType: "message" },
                sacanofficer
              );
              const ScanOfficer = sacanofficer.join(",");
              let obj = {
                // notification_type:"queryupload",
                user_id: ScanOfficer,
                ...notifyData,
              };
              callApi("POST", "pushnotification.php", obj)
                .then((res) => res.data)
                .then((response) => {})
                .catch((err) => {
                  console.log(err);
                });
            }
            if (Array.isArray(processManager) && processManager.length > 0) {
              notifyToUser(
                "user",
                { ...notifyData, notifyType: "message" },
                processManager
              );
              const ProcessManager = processManager.join(",");
              let obj = {
                // notification_type:"queryupload",
                user_id: ProcessManager,
                ...notifyData,
              };
              callApi("POST", "pushnotification.php", obj)
                .then((res) => res.data)
                .then((response) => {})
                .catch((err) => {
                  console.log(err);
                });
            }
            ToastsStore.success("File(s) Uploaded Successully");
          } else {
            this.setState({ uploadbtn: false });
            this.setState({ isbtnload: false });
            ToastsStore.success("File(s) Not Uploaded");
          }
        })
        .catch((err) => {
          this.setState({ isbtnload: false });
          this.setState({ uploadbtn: false });

          // this.setState({ base: [] });
        });
    }
  };
  validateDocuments = () => {
    let base: any = this.state.base;
    let isValid = true;
    const invalidDocuments = [
      "26",
      "27",
      "36",
      "35",
      "38",
      "103",
      "78",
      "5",
      "Online Encumbrance Certificate",
    ];
    for (let i = 0; i < base.length; i++) {
      const document = base[i];

      if (invalidDocuments.includes(document.doc_type)) {
        base[i].isValid = true;
      } else {
        if (
          document.type == "deed" ||
          document.type == "mother_doc" ||
          document.type == "title_deed" ||
          document.type == "ec_doc"
        ) {
          if (
            !document.docNoYear ||
            !document.doc_type ||
            !document.type ||
            !document.particular ||
            !document.base
          ) {
            isValid = false;
            base[i].isValid = false;
          } else {
            base[i].isValid = true;
          }
        } else if (
          document.type == "rev_rec_doc" ||
          document.type == "sale_agreement"
        ) {
          if (
            !document.doc_type ||
            !document.type ||
            !document.particular ||
            !document.base
          ) {
            isValid = false;
            base[i].isValid = false;
          } else {
            base[i].isValid = true;
          }
        } else {
          isValid = false;
          base[i].isValid = false;
        }
      }
    }
    return isValid;
  };
  timeUpdate = (fileid, type) => {
    const loginuser: any = sessionStorage.getItem("user1");
    let req;
    if (type == "query receive") {
      req = {
        query_received_by: JSON.parse(loginuser),
        id: fileid,
        reason: "Query Received",
        created_by: JSON.parse(loginuser),
        color: "#edada0",
      };
    } else if (type == "query raise") {
      req = {
        query_raised_by: JSON.parse(loginuser),
        id: fileid,
        reason: "Query Raised",
        created_by: JSON.parse(loginuser),
        color: "#afa5bd",
      };
    } else if (type == "before query raise") {
      req = {
        id: fileid,
        reason: "Scan File Upload",
        created_by: JSON.parse(loginuser),
        reason_status: 1,
      };
    } else {
      req = {
        // id: fileid,
        // reason: "File Out",
        // created_by: JSON.parse(loginuser)
      };
    }
    callApi("POST", "time_update.php", req)
      .then((res) => res.data)
      .then((response) => {});
  };
  handleChangeCategory = (e, propName?, fieldType?) => {
    let fieldName = fieldType === "singleselect" ? propName : e.target.name;
    let fieldValue = fieldType === "singleselect" ? e.value : e.target.value;

    if (fieldName === "category") {
      // const employee_id = fieldValue;
      this.setState({ type: fieldValue });
    }
    if (fieldName === "particular") {
      const bank_name = fieldValue;
      this.setState({ [fieldName]: fieldValue });
    }
  };
  selectUser = (e: any, user: any) => {
    let remote_users: any = this.state.remote_users;
    if (e.target.checked) {
      remote_users.push({ email: user.email, name: user.name });
    } else {
      remote_users = remote_users.filter((itm) => itm.email !== user.email);
    }
    this.setState({ remote_users });
  };
  selectCcUser = (e: any, user: any) => {
    let remote_users: any = this.state.remote_cc_users;
    if (e.target.checked) {
      remote_users.push({ email: user.email, name: user.name });
    } else {
      remote_users = remote_users.filter((itm) => itm.email !== user.email);
    }
    this.setState({ remote_cc_users: remote_users });
  };
  addItem = (): void => {
    // const querydetail: Query[] = this.state.querydetail
    //   ? this.state.querydetail
    //   : [];
    // querydetail.push({ query: "", review: "" });
    // this.setState({ querydetail });
  };
  handleFiles = (files: any) => {
    let baseFile: any = this.state.queryBase || [];
    const fileSize = [...files.fileList].reduce((a, b) => +a + +b.size, 0);
    if (Number(fileSize) > 26214400) {
      ToastsStore.error("File(s) size shouldn't exceed 25 MB");
    } else {
      files.base64.map((file: any, index: number) => {
        baseFile.push({
          file,
          fileType: files.fileList[index].type,
          fileName: files.fileList[index].name,
        });
      });
      this.setState({ queryBase: [...baseFile] });
    }
  };
  deleteDoc = (modal, index) => {
    const baseFile: never[] = cloneDeep(this.state.queryBase);
    baseFile.splice(index, 1);
    this.setState({ queryBase: [...baseFile] });
  };
  previewDoc = (modal) => {
    this.setState({ previewDoc: modal.file });
  };
  setSelectedItem = (item: any) => {
    let itemList = Array.isArray(item) ? [...item] : [item];
    let { multiSelectedQuery } = this.state;
    let tempArray: any[] = multiSelectedQuery;
    for (let i = 0; i < itemList.length; i++) {
      if (tempArray.find((val: any) => val.query_id === itemList[i].query_id)) {
        tempArray.splice(
          multiSelectedQuery.findIndex(
            (val: any) => val.query_id === itemList[i].query_id
          ),
          1
        );
      } else tempArray.push(itemList[i]);
    }
    this.setState({ multiSelectedQuery: tempArray });
  };
  repliedQueries = (queries: any) => {
    this.setState(
      {
        repliedQueries: queries,
        queryPayload: {
          requery: [],
          resend: [],
        },
      },
      () => this.formatData(queries)
    );
  };
  getDeviationQueryData = (queryid, isChecked, data) => {
    if (isChecked && ["0", "2", "3"].includes(data.details.deviation_status)) {
      this.setState((prevState: any) => ({
        deviationTakenQuerys: [...prevState.deviationTakenQuerys, queryid],
      }));
    } else {
      this.setState((prevState: any) => ({
        deviationTakenQuerys: prevState.deviationTakenQuerys.filter(
          (id: any) => id !== queryid
        ),
      }));
    }
  };

  submitRequeryDeviation = () => {
    const user: any = sessionStorage.getItem("user");
    const userId = user ? JSON.parse(user)["userId"] : "";
    if (!isEmpty(this.state.deviationReason)) {
      let req = {
        file_id: this.props.match.params.id,
        query_ids: this.state.deviationTakenQuerys,
        reason: this.state.deviationReason,
        user_id: userId,
      };
      callApi("POST", "deviation_request.php", req)
        .then((res) => res.data)
        .then((response) => {
          if (response.statusCode == 200) {
            this.deviationFileLog(this.props.match.params.id);
            this.getQueryTable();
            $("#deviationModal").modal("hide");
            this.setState({ deviationTakenQuerys: [], deviationReason: "" });
          }
        });
    } else {
      ToastsStore.error("Please type reason to proceed");
    }
  };

  closeDeviationModal = () => {
    this.setState({ deviationTakenQuerys: [], deviationReason: "" });
  };

  handleDeviationReasonChange = (e) => {
    const { value } = e.target;
    this.setState({ deviationReason: value });
  };

  getNewQuery = (newquerys: any) => {
    this.setState({ newquery: newquerys });
  };
  formatData = (queries) => {
    const queryData: any = this.state.queryPayload;
    queries.forEach((query) => {
      if (
        query.hasOwnProperty("replies") &&
        query.replies &&
        query.replies.length > 0
      ) {
        queryData.requery.push(query);
        this.setState({ queryPayload: queryData }, () => {
          if (query.children.length > 0) {
            this.formatData(query.children);
          }
        });
      } else if (query.children.length > 0) {
        this.formatData(query.children);
      }
    });
  };

  // {for query table function}
  // saveQuery = (): void => {
  //   const { multiSelectedQuery, remote_users, queryBase, remote_cc_users, newQueryList } = this.state;
  //   const queryData: any = this.state.queryPayload;
  //   const user: any = sessionStorage.getItem("user");
  //   const userId = user ? JSON.parse(user)["userId"] : "";
  //   const process_office_id: any = sessionStorage.getItem("process_office_id");
  //   const userid1: any = sessionStorage.getItem("user1");
  //   const payload: any = {
  //     // querydetail: [...multiSelectedQuery, ...this.state.newquery],
  //     querydetail: newQueryList,
  //     remote_users: remote_users,
  //     remote_cc_users,
  //     user_idfk: userId,
  //     fod_fk: this.props.match.params.id,
  //     base: queryBase
  //   }
  //   // queryData.requery.forEach((query) => {
  //   //   query.replies.forEach((reply) => {
  //   //     const Obj = {
  //   //       query: reply.query,
  //   //       review: reply.review,
  //   //       other_review: reply.other_review,
  //   //       location: reply.location,
  //   //       query_id: query.id,
  //   //       created_date: moment().format("DD-MM-YYYY HH:mm:ss")
  //   //     }
  //   //     payload.querydetail.push(Obj);
  //   //   });
  //   // });
  //   this.setState({ queryLoading: true });
  //   callApi("POST", "new_query_create.php", payload)
  //     .then(res => res.data)
  //     .then(response => {
  //       localStorage.setItem("query_raised_status", JSON.stringify(true));
  //       if (process_office_id == userid1) {
  //         this.timeUpdate(this.props.match.params.id, "query raise");
  //       }
  //       this.setState({ queryLoading: false, quesSubmitted: false });
  //       $('#send-mail').modal('hide');
  //       ToastsStore.success("Queries added Successully");
  //       this.setState({
  //         queryPayload: {
  //           requery: [],
  //           resend: []
  //         },
  //         multiSelectedQuery: [],
  //         newquery: []
  //       }, () => {
  //         // this.query.current.updateQuery();
  //       })
  //       // sessionStorage.removeItem("currentfileId")
  //       setTimeout(() => {
  //         this.props.history.push("/inprogress-file");
  //       }, 2000);
  //     })
  //     .catch(err => {
  //       this.setState({ queryLoading: false, quesSubmitted: true });
  //       // $('#send-mail').modal('hide');
  //       ToastsStore.error("Something went Wrong, Please try again!");
  //     });
  // }
  saveQuery = (): void => {
    const { multiSelectedQuery, remote_users, queryBase, remote_cc_users } =
      this.state;
    const queryData: any = this.state.queryPayload;
    const user: any = sessionStorage.getItem("user");
    const userId = user ? JSON.parse(user)["userId"] : "";
    const process_office_id: any = sessionStorage.getItem("process_office_id");
    const userid1: any = sessionStorage.getItem("user1");

    // Initialize querydetail
    let querydetail: any[] = [];

    // Step 1: Add multiSelectedQuery
    querydetail = [...multiSelectedQuery];

    // Step 2: Add queryData.requery replies
    if (queryData?.requery) {
      queryData.requery.forEach((query) => {
        query.replies.forEach((reply) => {
          const Obj = {
            ...reply,
            query: reply.query,
            review: reply.review,
            other_review: reply.other_review,
            location: reply.location,
            query_id: query.id,
            // created_date: moment().format("DD-MM-YYYY HH:mm:ss"),
            // filter_date: moment().format("DD-MM-YYYY HH:mm:ss.SSS"),
          };
          querydetail.push(Obj);
        });
      });
    }

    // Step 3: Add this.state.newquery
    querydetail.push(...this.state.newquery);

    // Step 4: Sort querydetail by filter_date
    querydetail.sort((a, b) => {
      const dateA = moment(a.filter_date, "DD-MM-YYYY HH:mm:ss").toDate();
      const dateB = moment(b.filter_date, "DD-MM-YYYY HH:mm:ss").toDate();
      return dateA.getTime() - dateB.getTime(); // Ascending order
    });

    const payload: any = {
      querydetail,
      remote_users: remote_users,
      remote_cc_users,
      user_idfk: userId,
      fod_fk: this.props.match.params.id,
      base: queryBase,
    };
    // console.log("payload___", payload, multiSelectedQuery, this.state.newquery);
    // return;
    this.setState({ queryLoading: true });
    const userDGrade: any = sessionStorage.getItem("user_d_grade");
    const fileDGrade: any = sessionStorage.getItem("file_d_grade");
    const mailToBank: any = sessionStorage.getItem("mail-bank");
    let apiPath;
    if (userDGrade === "D" || fileDGrade === "D") {
      apiPath =
        mailToBank === "true"
          ? "new_query_create.php"
          : "new_query_create_without_mail.php";
      this.deviationFileLog(this.props.match.params.id);
    } else {
      apiPath = "new_query_create.php";
    }
    callApi("POST", apiPath, payload)
      .then((res) => res.data)
      .then((response) => {
        console.log("query response___", response);
        if (response.success == true) {
          localStorage.setItem("query_raised_status", JSON.stringify(true));
          if (process_office_id == userid1) {
            this.timeUpdate(this.props.match.params.id, "query raise");
          }
          this.setState({ queryLoading: false, quesSubmitted: false });
          $("#send-mail").modal("hide");
          ToastsStore.success("Queries added Successully");
          this.setState(
            {
              queryPayload: {
                requery: [],
                resend: [],
              },
              multiSelectedQuery: [],
              newquery: [],
            },
            () => {
              this.query.current.updateQuery();
            }
          );
          // sessionStorage.removeItem("currentfileId")
          this.getOutTime();
        }
      })
      .catch((err) => {
        this.setState({ queryLoading: false, quesSubmitted: true });
        // $('#send-mail').modal('hide');
        ToastsStore.error("Something went Wrong, Please try again!");
      });
  };
  getOutTime = async () => {
    let date = new Date();
    let stratTime = date.getTime();
    localStorage.setItem("startTime", JSON.stringify(stratTime));
    let outDate = date.toLocaleDateString();
    localStorage.setItem("OutDateGet", JSON.stringify(outDate));
    var Railway = date.getMinutes();
    if (Railway < 10) {
      var TotalMinutes = "0" + Railway;
      let ouTtime = date.getHours() + ":" + TotalMinutes;
      localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
    } else {
      let ouTtime: any = date.getHours() + ":" + date.getMinutes();
      localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
    }
    await this.processEod();
  };
  processEod = () => {
    const fileid1: any = sessionStorage.getItem("currentfileId");
    const userid1: any = sessionStorage.getItem("user1");
    const inreason: any = localStorage.getItem("in_reason");
    const intime: any = localStorage.getItem("IntimeGet");
    console.log("70", intime);
    const outtime: any = localStorage.getItem("outtimeGet");
    console.log("73", outtime);
    const outdate: any = localStorage.getItem("OutDateGet");
    const secondsSpent = moment(outtime, "HH:mm").diff(
      moment(intime, "HH:mm"),
      "seconds"
    );
    console.log("Second Spn", secondsSpent);
    let updatedRemainingTime: any = sessionStorage.getItem(
      "updatedRemainingTime"
    );
    let updatedtime = Math.round(updatedRemainingTime);
    const status: any = sessionStorage.getItem("dashboard_status");
    let obj = {
      file_id: JSON.parse(fileid1),
      user_id: JSON.parse(userid1),
      in_reason: JSON.parse(inreason),
      out_reason: "Re Queries Raised",
      intime: JSON.parse(intime),
      outtime: JSON.parse(outtime),
      insert_date: JSON.parse(outdate),
      total_seconds: secondsSpent,
      balance_min: updatedtime,
      dashboard_status: status,
    };
    callApi("POST", "process_eod_insert.php", obj)
      .then((res) => res.data)
      .then((response) => {
        sessionStorage.removeItem("updatedRemainingTime");
        localStorage.removeItem("in_reason");
        localStorage.removeItem("outreason");
        localStorage.removeItem("IntimeGet");
        localStorage.removeItem("OutDateGet");
        $("#out_reason").modal("hide");
        $("#another-reason").modal("hide");
        // ToastsStore.success("Your Reason Saved Successfully");
        sessionStorage.removeItem("currentfileId");
        localStorage.removeItem("query_raised_status");
        localStorage.removeItem("file_completed_status");
        setTimeout(() => {
          this.props.history.push("/inprogress-file");
        }, 500);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  deviationFileLog = (fileid) => {
    const loginuser: any = sessionStorage.getItem("user1");
    let req = {
      query_raised_by: JSON.parse(loginuser),
      id: fileid,
      reason: "Request for Deviation",
      created_by: JSON.parse(loginuser),
      color: "#e9df9a",
    };
    callApi("POST", "time_update.php", req)
      .then((res) => res.data)
      .then((response) => {});
  };
  handleAnswerChange = (index, val) => {
    const queryQues: any = this.state.queryQues;
    queryQues[index].answer = val;
    this.setState({ queryQues });
  };
  saveQueryQuestion = () => {
    const { queryQues, oldQueryQues } = this.state;
    const user: any = sessionStorage.getItem("user");
    const obj = {
      fileNo: this.props.match.params.fileid,
      currentData: queryQues,
      oldData: oldQueryQues,
      loginId: user ? JSON.parse(user)["userId"] : "",
    };

    callApi("POST", "queryverification_checklist.php", obj)
      .then((res) => res.data)
      .then((response) => {
        this.setState({ quesSubmitted: true });
      })
      .catch((err) => {});
  };

  getUpdateReasonData = () => {
    const reasonData: any = this.state.updateDownloadData;
    callApi("POST", "dashboard_log.php", {
      id: this.props.match.params.id,
      path: "dashboard",
    })
      .then((res) => res.data)
      .then((result) => {
        reasonData.reasonForDownload = result.data.reasonForDownload;
        // Object.keys(result.data[0][1]).map(key => result.data[0][1][key]);
        reasonData.updatedDetails = result.data.updatedDetails;
        // result.data[0][0];
        this.setState({ updateDownloadData: reasonData });
      })
      .catch((err) => {
        // ToastsStore.error("Failed to get ");
      });
  };

  documentDownload = (listVal, type) => {
    if (type == "docx") {
      const name = `${this.state.LHBranchCode}-${this.props.match.params.id}.docx`;
      Array.isArray(listVal.url) &&
        listVal.url[0].docx &&
        saveAs(listVal.url[0].docx, name);
    } else {
      const name = `${this.state.LHBranchCode}-${this.props.match.params.id}.pdf`;
      Array.isArray(listVal.url) &&
        listVal.url[0].pdf &&
        saveAs(listVal.url[0].pdf, name);
    }
  };

  previewPDF = (url) => {
    this.setState({ pdfToPreview: url });
  };

  renderDetailsCard = (type, listOfData) => {
    return (
      listOfData &&
      listOfData.map((listVal, listIndex) => {
        return (
          <div
            className="row"
            key={`${listIndex}-${listVal.title}`}
            style={{
              background:
                listIndex === 0 && type === "UPDATE" ? "#cdffe0" : "#eff3f6",
              boxShadow: "0 0 0 1px #e2e5e8",
              borderRadius: "0.25rem",
              padding: "10px",
              paddingLeft: "12px",
              paddingRight: "12px",
              marginBottom: "15px",
            }}
          >
            <div style={{ width: "100%" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "black",
                    textTransform: "capitalize",
                  }}
                >
                  {listVal.title || ""}
                </p>
                {type === "UPDATE" ? (
                  <div style={{ display: "flex", gap: "20px" }}>
                    {this.state.pageDynamic.map((page: any, index: any) => {
                      if (
                        page.page_name === "PDF Download" &&
                        page.status == true
                      ) {
                        return (
                          <FaRegFilePdf
                            onClick={() =>
                              this.documentDownload(listVal, "pdf")
                            }
                            style={{
                              fontSize: "21px",
                              color: "black",
                              cursor: "pointer",
                            }}
                          />
                        );
                      } else if (
                        page.page_name === "DOC Download" &&
                        page.status == true
                      ) {
                        return (
                          <PiFileDocDuotone
                            onClick={() =>
                              this.documentDownload(listVal, "docx")
                            }
                            style={{
                              fontSize: "24px",
                              color: "black",
                              cursor: "pointer",
                            }}
                          />
                        );
                      } else if (
                        page.page_name === "PDF Preview" &&
                        page.status == true
                      ) {
                        return (
                          <FaEye
                            data-toggle="modal"
                            data-target="#preview-pdf"
                            style={{
                              fontSize: "20px",
                              color: "black",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              this.previewPDF(
                                Array.isArray(listVal.url) && listVal.url[0].pdf
                              )
                            }
                          />
                        );
                      }
                    })}
                    {/* <i className="icon feather icon-download" style={{ color: 'black', fontSize: '17px', fontWeight: 'bold', cursor: 'pointer' }} /> */}
                  </div>
                ) : null}
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex" }}>
                  <p style={{ margin: "0px", color: "grey", fontSize: "13px" }}>
                    {moment(listVal.date).format("DD-MM-YYYY hh:mm a")} <br />
                  </p>
                  {listVal.officerType && (
                    <p
                      style={{
                        margin: "0px",
                        color: "black",
                        fontSize: "13px",
                        fontWeight: "bold",
                        marginLeft: "10px",
                        marginRight: "10px",
                        textTransform: "capitalize",
                      }}
                    >
                      {listVal.officerType}
                    </p>
                  )}
                  {listVal.downloadedBy && (
                    <p
                      style={{ margin: "0px", fontSize: "13px", color: "grey" }}
                    >
                      {type === "DOWNLOAD" ? "Downloaded By " : "Updated By "}{" "}
                      <span style={{ color: "black", fontWeight: "bold" }}>
                        {listVal.downloadedBy || ""}
                      </span>
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })
    );
  };

  uploadFile = (file: any) => {
    this.setState({ uploadFile: file });
  };
  actionHandle = (action) => {
    this.setState({ action });
  };
  onchange = (e: any) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/\s+/g, " ").trim(),
      selectVal: false,
    });
  };
  validateReason = () => {
    if (this.state.reason === "") {
      ToastsStore.error("Please Enter Reason");
    } else {
      if (this.state.action === "upload") {
        $("#reason-modal1").modal("hide");
        this.uploadDoc();
      }
    }
  };

  uploadDoc = () => {
    $("#reason-modal1").modal("hide");
    const user: any = sessionStorage.getItem("user");
    const userId = user ? JSON.parse(user)["userId"] : "";
    const obj = {
      fileId: this.props.match.params.id,
      reason: this.state.reason,
      createdBy: userId,
      file: this.state.uploadFile.base64.split(",")[1],
      errorTypes: ["1", "2"],
      errorSection: ["1", "2"],
    };
    callApi("POST", "template/tests/upload_log.php", obj)
      .then((res) => res)
      .then((response) => {
        sessionStorage.removeItem("overviewDocument");
        if (response.status === 200) {
          this.setState({ isrectificationComplete: false });
          // ToastsStore.success("work file Q & A are saved succesfully")
          ToastsStore.success("Reason saved successfully");
          this.clearModal();
          this.getUpdateReasonData();
        } else {
          this.setState({ isrectificationComplete: true });
          ToastsStore.error("Error in storing, Please try again!");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  clearModal = () => {
    this.setState({
      reason: "",
    });
  };

  editOption = () => {
    $(".editicon").hide();
    $(".appidShow").hide();
    $(".appidEdit").show();
    $(document).ready(function () {
      $("#appid").focus();
    });
  };
  deleteDocumentDetails = (index) => {
    const baseArray = cloneDeep(this.state.base);
    baseArray.splice(index, 1);
    this.setState({ base: baseArray });
  };
  handleDocumentDetailsChange = (value, index, key) => {
    const invalidDocuments = [
      "26",
      "27",
      "36",
      "35",
      "38",
      "103",
      "78",
      "5",
      "Online Encumbrance Certificate",
    ];
    if (key == "docNoYear") {
      const isValidInput = /^[0-9/-]*$/.test(value);

      if (!isValidInput) {
        console.error(
          "Invalid input. Please enter only numeric characters, slash, or hyphen."
        );
        return;
      }
      let baseArray = cloneDeep(this.state.base);
      baseArray[index] = {
        ...baseArray[index],
        [key]: value,
      };
      this.setState({ base: baseArray });
    } else {
      let baseArray = cloneDeep(this.state.base);
      if (key == "doc_type") {
        if (invalidDocuments.includes(value)) {
          baseArray[index] = {
            ...baseArray[index],
            docNoYear: "",
          };
          this.setState({ base: baseArray });
        }
      }

      baseArray[index] = {
        ...baseArray[index],
        [key]: value,
      };
      this.setState({ base: baseArray });
    }
  };
  handleDocumentFilesChange1 = (value, index, key) => {
    let baseArray = cloneDeep(this.state.base);
    baseArray[index] = {
      ...baseArray[index],
      base: value.fileList[0].name,
      base_url: value.base64,
    };
    this.setState({ base: baseArray });
  };
  addUploadDocument = () => {
    let baseArray = cloneDeep(this.state.base);
    baseArray.push(this.documentFormat);
    this.setState({ base: baseArray });
  };
  validateRequiredFileds = (e) => {
    if (this.state.base && this.state.base.length > 0) {
      this.uploadFiles();
    } else {
    }
  };
  closeuploadfile = () => {
    this.setState({ base: [] });
  };
  deleteFile = (index) => {
    const baseArray = cloneDeep(this.state.base);
    baseArray[index] = {
      ...baseArray[index],
      details: {},
      base: "",
    };
    this.setState({ base: baseArray });
  };

  validateQuery = (payload) => {
    let querydetail: any = payload;
    let base = querydetail.querydetail;
    const mailToBank: any = sessionStorage.getItem("mail-bank");
    const userDGrade: any = sessionStorage.getItem("user_d_grade");
    const fileDGrade: any = sessionStorage.getItem("file_d_grade");

    let isValid;
    for (let i = 0; i < base.length; i++) {
      const document = base[i];
      if (document && document.review == "other") {
        if (!document.query || !document.review || !document.other_review) {
          isValid = false;
          base[i].isValid = false;
        } else {
          isValid = true;
          base[i].isValid = true;
        }
      } else if (document && document.review != "other") {
        if (!document.query || !document.review) {
          isValid = false;
          base[i].isValid = false;
        } else {
          isValid = true;
          base[i].isValid = true;
        }
      } else {
      }
    }
    if (isValid) {
      if (userDGrade === "D" || fileDGrade === "D") {
        if (mailToBank === "true") {
          $("#send-mail").modal("show");
        } else {
          // without mail raise query
          this.saveQuery();
        }
      } else {
        $("#send-mail").modal("show");
      }
    } else {
      ToastsStore.error("Please fill all the required fields");
    }
  };
  checkRestriction = async () => {
    const response = await callApi("POST", "check_restriction.php", {
      id: this.props.match.params.id,
      file_status: 6,
      flag: "re_query",
    }).then((res) => res.data);
    if (response.response_Code === 201) {
      $("#file_not_complete_modal").modal("show");
      this.setState({ fileNotCompleteMsg: { __html: response.message } });
      return true;
    }
    return false;
  };
  updateNewQueryList = async () => {
    const checkRestrict = await this.checkRestriction();
    if (checkRestrict) return;
    const { multiSelectedQuery, remote_users, queryBase, remote_cc_users } =
      this.state;
    const queryData: any = this.state.queryPayload;
    const user: any = sessionStorage.getItem("user");
    const userId = user ? JSON.parse(user)["userId"] : "";
    const payload: any = {
      querydetail: [...multiSelectedQuery, ...this.state.newquery],
      remote_users: remote_users,
      remote_cc_users,
      user_idfk: userId,
      fod_fk: this.props.match.params.id,
      base: queryBase,
    };
    queryData.requery.forEach((query) => {
      query.replies.forEach((reply) => {
        const Obj = {
          ...reply,
          query: reply.query,
          review: reply.review,
          other_review: reply.other_review,
          location: reply.location,
          query_id: query.id,
          // created_date: moment().format("DD-MM-YYYY HH:mm:ss")
        };
        payload.querydetail.push(Obj);
      });
    });
    if (payload && payload.querydetail.length > 0) {
      this.validateQuery(payload);
    } else {
      $("#send-mail").modal("show");
    }
    this.getQueryQuestion();
    this.getUsers();
  };
  AppidSave = () => {
    const obj = {
      fileid: this.props.match.params.id,
      appid: this.state.info.app_id,
    };
    callApi("POST", "appid_ubdate.php", obj)
      .then((res) => res)
      .then((response) => {
        if (response.status === 200) {
          sessionStorage.removeItem("overviewDocument");
          // ToastsStore.success("work file Q & A are saved succesfully")
          ToastsStore.success("App Id Updated successfully");
          $(".appidShow").show();
          $(".appidEdit").hide();
          $(".editicon").show();
          this.clearModal();
          this.getUpdateReasonData();
        } else {
          ToastsStore.error("Error in storing, Please try again!");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  handleReasonChange = (e) => {
    this.setState({ reason: e.target.value });
  };
  updatedRemainingTime(time) {
    this.setState(
      (prevState: any) => ({
        updatedRemainingTime: time,
      }),
      () => {}
    );
  }

  overviewtime(type) {
    const loginuser: any = sessionStorage.getItem("user1");
    var now = new Date();
    var date = now.toLocaleDateString();
    var time = now.toLocaleTimeString();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    const currenttime = `${hours}:${minutes}:${seconds}`;
    const currentdate = moment(date).format("YYYY-MM-DD");
    let obj;
    if (type == "Typing Completed") {
      obj = {
        id: this.props.match.params.id,
        typingcompleted_time: currenttime,
        typingcompleted_date: currentdate,
        typingcompleted_by: JSON.parse(loginuser),
        reason: "Typing Completed",
        created_by: JSON.parse(loginuser),
        color: "#edada0",
      };
    } else if (type == "Verification") {
      obj = {
        id: this.props.match.params.id,
        reason: "Verification Completed",
        created_by: JSON.parse(loginuser),
        color: "#faad4c",
      };
    } else {
      obj = {
        id: this.props.match.params.id,
        reason: "Rectification Completed",
        created_by: JSON.parse(loginuser),
        color: "rgb(128 222 251)",
      };
    }
    callApi("POST", "time_update.php", obj)
      .then((res) => res.data)
      .then((response) => {});
  }
  completeFlowHandler = (status, type) => {
    this.setState({ showTitle: true });
    if (type == "typing") {
      this.overviewtime("Typing Completed");
    } else if (type == "verification") {
      this.overviewtime("Verification");
    } else {
      this.overviewtime("Rectification");
    }
    const user: any = sessionStorage.getItem("user");
    const userId = user ? JSON.parse(user)["userId"] : "";
    const report_to = user ? JSON.parse(user)["report_to"] : "";
    // file status 6 => moved to "files to be verfied"
    callApi("POST", "file_status.php", {
      id: this.props.match.params.id,
      file_status: status,
    })
      .then((res) => res)
      .then((response) => {
        this.setState({ showTitle: false });
        if (response.status === 200) {
          $("#confirm-complete").modal("hide");
          $("#processComplete").modal("show");
          this.setState({ isrectificationComplete: true });
          const user: any = sessionStorage.getItem("user");
          const userId = user ? JSON.parse(user)["userId"] : "";
          const userName = user ? JSON.parse(user)["firstName"] : "";
          const notifyData = {
            title: `${this.props.match.params.id}Typing Completed By ${userName}`,
            notifyType: "message",
            updatedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
          };
          pushnotificationApi(notifyData, userId);
          const branchManger: any = sessionStorage.getItem("branch_manager");
          const branch_Manager = JSON.parse(branchManger);
          const queryOfficer: any = sessionStorage.getItem("Query_officer");
          const query_ooficer = JSON.parse(queryOfficer);
          const Scan_officer: any = sessionStorage.getItem("scan_officer");
          const sacanofficer = JSON.parse(Scan_officer);
          const process_mennager: any =
            sessionStorage.getItem("process_manager");
          const processManager = JSON.parse(process_mennager);
          if (Array.isArray(branch_Manager) && branch_Manager.length > 0) {
            notifyToUser(
              "user",
              { ...notifyData, notifyType: "message" },
              branch_Manager
            );
            const branchManager = branch_Manager.join(",");
            let obj = {
              notification_type: "typingcomplete",
              user_id: branchManager,
              ...notifyData,
              reason: `${this.props.match.params.id}Typing Completed`,
            };
            callApi("POST", "pushnotification.php", obj)
              .then((res) => res.data)
              .then((response) => {})
              .catch((err) => {
                console.log(err);
              });
          }
          if (Array.isArray(query_ooficer) && query_ooficer.length > 0) {
            notifyToUser(
              "user",
              { ...notifyData, notifyType: "notify" },
              query_ooficer
            );
            const OfficerQuery = query_ooficer.join(",");
            let obj = {
              notification_type: "typingcomplete",
              user_id: OfficerQuery,
              ...notifyData,
              reason: `${this.props.match.params.id}Typing Completed`,
            };
            callApi("POST", "pushnotification.php", obj)
              .then((res) => res.data)
              .then((response) => {})
              .catch((err) => {
                console.log(err);
              });
          }
          if (Array.isArray(processManager) && processManager.length > 0) {
            notifyToUser(
              "user",
              { ...notifyData, notifyType: "message" },
              processManager
            );
            const ProcessManager = processManager.join(",");
            let obj = {
              notification_type: "typingcomplete",
              user_id: ProcessManager,
              ...notifyData,
              reason: `${this.props.match.params.id}Typing Completed`,
            };
            callApi("POST", "pushnotification.php", obj)
              .then((res) => res.data)
              .then((response) => {})
              .catch((err) => {
                console.log(err);
              });
          }
        } else {
          ToastsStore.error("Error in completing the file , Please try again!");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  close = () => {
    const path = localStorage.getItem("url");
    $("#processComplete").modal("hide");
    sessionStorage.removeItem("currentfileId");
    setTimeout(() => {
      this.props.history.push(path);
    }, 2000);
  };
  confirmComplete(status, type) {
    this.setState({ iscomplete: true }, () => {
      if (this.state.iscomplete == true) {
        this.completeFlowHandler(status, type);
      }
    });
  }
  processComplete(val) {
    if (val == "process") {
      this.setState({ isprocesscomplete: true });
    } else if (val == "verify") {
      this.setState({ isverifycomplete: true });
    } else if (val == "rectify") {
      if (this.state.isrectificationComplete == false) {
        this.setState({ isrectifycomplete: true });
        $("#confirm-complete").modal("show");
      } else {
        $("#complete-process").modal("show");
      }
    } else {
    }
  }
  getHold = () => {
    let obj = { file_id: this.props.match.params.id };
    callApi("POST", "hold_file_get.php", obj)
      .then((res) => res.data)
      .then((response) => {
        if (response) {
          this.setState({ holdDetails: response.data });
          $("#view-reasson").modal("show");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  noRaiseQuery = () => {
    let obj = { file_id: this.props.match.params.id };
    callApi("POST", "noraise_query.php", obj)
      .then((res) => res.data)
      .then((response) => {
        if (response.statuscode == 200) {
          ToastsStore.success(response.message);
          $("#no-query").modal("hide");
        } else {
          ToastsStore.error(response.message);
          $("#no-query").modal("hide");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //new qeury table start
  addQueryList = () => {
    const { newQueryList } = this.state;
    newQueryList.push({
      query: "",
      location: "",
      review: "",
      is_checked: false,
      other_review: "",
      created_date: moment().format("DD-MM-YYYY HH:mm:ss"),
    });
    this.setState({ newQueryList, isRaiseQueryShown: true });
  };
  removeReply = (index) => {
    const { newQueryList } = this.state;
    const updatedQueryList = [
      ...newQueryList.slice(0, index),
      ...newQueryList.slice(index + 1),
    ];
    this.setState({ newQueryList: updatedQueryList });
  };

  handleChangequery = (e: any, index: number) => {
    let fieldName = e.target.name;
    let fieldValue = e.target.value;
    const { newQueryList } = this.state;
    newQueryList[index][fieldName] = fieldValue;
    this.setState({ newQueryList });
  };

  handleDetailsChange = (value: string, key: string, index: number) => {
    const { newQueryList } = this.state;
    newQueryList[index][key] = value;
    this.setState({ newQueryList });
  };

  newaddrequery = (data: any) => {
    const { reQueryList, newQueryList } = this.state;
    const newReQueryItem = {
      query: "",
      location: "",
      review: "",
      query_id: data.id ? data.id : data.query_id,
      other_review: "",
      created_date: moment().format("DD-MM-YYYY HH:mm:ss"),
    };
    const updatedReQueryList = [...reQueryList, newReQueryItem];
    const updatedNewQueryList = [...newQueryList, newReQueryItem];

    this.setState({
      reQueryList: updatedReQueryList,
      newQueryList: updatedNewQueryList,
      isRaiseQueryShown: true,
    });
  };

  addrequery = (event: any, data: any, index) => {
    const isChecked = event.target.checked;
    const { reQueryList, newQueryList } = this.state;
    if (isChecked) {
      const newReQueryItem = {
        query: data.title,
        location: data.location,
        review: data.reason,
        query_id: data.id,
        created_date: data.created_date,
        is_checked: isChecked,
      };
      const updatedReQueryList = [...reQueryList, newReQueryItem];
      const updatedNewQueryList = [...newQueryList, newReQueryItem];

      this.setState({
        reQueryList: updatedReQueryList,
        newQueryList: updatedNewQueryList,
        isRaiseQueryShown: true,
      });
    } else {
      const updatedQueryList = [
        ...newQueryList.slice(0, index),
        ...newQueryList.slice(index + 1),
      ];
      this.setState({ newQueryList: updatedQueryList });
    }
  };

  CompleteConfirm = (data) => {
    this.setState({
      showPopup: true,
      selectedQueryname: data.name,
      selectedQuerytitle: data.title,
      selectedQueryid: data.id,
    });
  };

  Completequery = () => {
    const { selectedQueryid } = this.state;
    const user = sessionStorage.getItem("user");
    const userId = user ? JSON.parse(user)["userId"] : "";
    callApi("POST", "query_close.php", {
      id: selectedQueryid,
      status: 3,
      userId,
    })
      .then((res) => res.data)
      .then((response) => {
        this.getQueryTable();
        this.setState({ showPopup: false });
      })
      .catch((err) => {
        console.log(err);
        this.getQueryTable();
        this.setState({ showPopup: false });
      });
  };

  //new qeury table end
  render() {
    const editCss = {
      marginTop: "-10px",
      // boxShadow: '0 0 4px #101010',
      width: "fit-content",
      padding: "7px",
      borderRadius: "13px",
    };
    const fileurl: any = localStorage.getItem("url");
    // console.log("807", fileurl);
    const user: any = sessionStorage.getItem("user");
    const role_id = user ? JSON.parse(user)["role_id"] : "";
    // const role_id = "4";
    const roleType = user ? JSON.parse(user)["roleType"] : "";
    const {
      info,
      showTitle,
      selectVal,
      multiSelectedQuery,
      queryLoading,
      queryQues,
      quesSubmitted,
      updateDownloadData,
      isReasonForDownloadShown,
      isUpdateDownloadShown,
      uploadFile,
      reason,
      setResponse,
      workfileEnable,
      initialTimeInMinutes,
      isRaiseQueryShown,
      newQueryList,
      Rectify_files,
      isFileRefresh,
    } = this.state;
    const banktemplatesoption = [
      { value: "", label: "All" },
      ...this.state.bank_templates,
    ];
    const process_officer: any = sessionStorage.getItem("process_office_id");
    const userid1: any = sessionStorage.getItem("user1");
    let RemainingTime: any = sessionStorage.getItem("updatedRemainingTime");
    const take_file: any = sessionStorage.getItem("takenfile_id");
    // const taken_file = user ? JSON.parse(user)["taken_file"] : take_file;
    const getfileId: any = sessionStorage.getItem("currentfileId");
    let initialtime;
    if (
      initialTimeInMinutes &&
      initialTimeInMinutes > 0 &&
      take_file == getfileId
    ) {
      initialtime = initialTimeInMinutes;
    } else {
      initialtime = RemainingTime;
    }
    const mailToBank: any = sessionStorage.getItem("mail-bank");
    const fromDeviation: any = sessionStorage.getItem("from_deviation");

    const accentStyle = { "--accent-color": "#5bc0de" } as React.CSSProperties;
    const greenStyle = { "--accent-color": "#28a745" } as React.CSSProperties;
    const grayStyle = { "--accent-color": "gray" } as React.CSSProperties;
    const loginStyle = {
      "--accent-color": ["-1"].includes(info.file_status)
        ? (accentStyle as React.CSSProperties)["--accent-color"]
        : this.state.step >= 1
        ? (greenStyle as React.CSSProperties)["--accent-color"]
        : (grayStyle as React.CSSProperties)["--accent-color"],
    } as React.CSSProperties;
    const QueueStyle = {
      "--accent-color": ["0", "8"].includes(info.file_status)
        ? (accentStyle as React.CSSProperties)["--accent-color"]
        : this.state.step >= 2
        ? (greenStyle as React.CSSProperties)["--accent-color"]
        : (grayStyle as React.CSSProperties)["--accent-color"],
    } as React.CSSProperties;
    const wipStyle = {
      "--accent-color": ["2"].includes(info.file_status)
        ? (accentStyle as React.CSSProperties)["--accent-color"]
        : this.state.step >= 3
        ? (greenStyle as React.CSSProperties)["--accent-color"]
        : (grayStyle as React.CSSProperties)["--accent-color"],
    } as React.CSSProperties;
    const QRStyle = {
      "--accent-color": ["7"].includes(info.file_status)
        ? (accentStyle as React.CSSProperties)["--accent-color"]
        : this.state.step >= 4
        ? (greenStyle as React.CSSProperties)["--accent-color"]
        : (grayStyle as React.CSSProperties)["--accent-color"],
    } as React.CSSProperties;
    const QreceiveStyle = {
      "--accent-color": ["8"].includes(info.file_status)
        ? (accentStyle as React.CSSProperties)["--accent-color"]
        : this.state.step >= 5
        ? (greenStyle as React.CSSProperties)["--accent-color"]
        : (grayStyle as React.CSSProperties)["--accent-color"],
    } as React.CSSProperties;
    const TCStyle = {
      "--accent-color":
        ["6"].includes(info.file_status) &&
        info.vtaken_status == "0" &&
        info.taken_status == "0"
          ? (accentStyle as React.CSSProperties)["--accent-color"]
          : this.state.step >= 6
          ? (greenStyle as React.CSSProperties)["--accent-color"]
          : (grayStyle as React.CSSProperties)["--accent-color"],
    } as React.CSSProperties;
    const VwipStyle = {
      "--accent-color":
        ["6"].includes(info.file_status) &&
        info.vtaken_status == "1" &&
        info.taken_status == "0"
          ? (accentStyle as React.CSSProperties)["--accent-color"]
          : this.state.step >= 7
          ? (greenStyle as React.CSSProperties)["--accent-color"]
          : (grayStyle as React.CSSProperties)["--accent-color"],
    } as React.CSSProperties;
    const HoldStyle = {
      "--accent-color": ["3"].includes(info.file_status)
        ? (accentStyle as React.CSSProperties)["--accent-color"]
        : this.state.step >= 7 && info.hold_date != ""
        ? (greenStyle as React.CSSProperties)["--accent-color"]
        : (grayStyle as React.CSSProperties)["--accent-color"],
    } as React.CSSProperties;
    const VcStyle = {
      "--accent-color":
        ["5"].includes(info.file_status) &&
        info.vtaken_status == "0" &&
        info.taken_status == "0"
          ? (accentStyle as React.CSSProperties)["--accent-color"]
          : this.state.step >= 8
          ? (greenStyle as React.CSSProperties)["--accent-color"]
          : (grayStyle as React.CSSProperties)["--accent-color"],
    } as React.CSSProperties;

    const takenfile_id: any = sessionStorage.getItem("takenfile_id");
    const currentfile_Id: any = sessionStorage.getItem("currentfileId");
    let takenfile = null;
    if (takenfile_id) {
      try {
        takenfile = JSON.parse(takenfile_id);
      } catch (error) {
        console.error("Error parsing takenfile_id JSON:", error);
      }
    }
    const currentfile = JSON.parse(currentfile_Id);

    const fileWorkType =
      this.state.blockdownload == "true" && this.state.info.opinion_type == "1"
        ? "Software task"
        : "Manual task";

    return (
      <div className="pcoded-main-container">
        <ToastsContainer
          store={ToastsStore}
          position={ToastsContainerPosition.TOP_RIGHT}
        />{" "}
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div
                          className="card-header"
                          style={{ padding: "12px " }}
                        >
                          <div className="row">
                            <div className="col-md-12">
                              {process_officer == userid1 &&
                                initialtime &&
                                initialtime > 0 &&
                                take_file == getfileId && (
                                  <CountdownTimer
                                    initialTime={initialtime}
                                    updatedTime={(time) =>
                                      this.updatedRemainingTime(time)
                                    }
                                    ref={(ref) =>
                                      (this.countdownTimerRef = ref)
                                    }
                                  />
                                )}
                            </div>
                            <br />
                          </div>
                          <div className="row">
                            <div className="col-md-9">
                              <div className="d-flex">
                                <h5>File No - {this.state.info.file_name}</h5>
                                <br />
                                <h5 style={{ marginLeft: "1%" }}>|</h5>
                                <h5 style={{ marginLeft: "1%" }}>
                                  Login Date - {this.state.info.login_time}
                                </h5>
                                <h5 style={{ marginLeft: "1%" }}>|</h5>
                                <h5 style={{ marginLeft: "1%" }}>
                                  Bank Name - {this.state.info.bank_name}{" "}
                                  {this.state.info.product_name ? (
                                    <>({this.state.info.product_name})</>
                                  ) : (
                                    ""
                                  )}
                                </h5>
                              </div>
                              <div
                                className="d-flex"
                                style={{ marginTop: "2%" }}
                              >
                                <h5>PO - {this.state.info.process_officer}</h5>
                                <h5 style={{ marginLeft: "1%" }}>|</h5>
                                <h5 style={{ marginLeft: "1%" }}>
                                  VO - {this.state.info.verify_officer}
                                </h5>
                                <h5 style={{ marginLeft: "1%" }}>|</h5>
                                <h5 style={{ marginLeft: "1%" }}>
                                  DO - {this.state.info.deviation_officer}
                                </h5>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div style={{ display: "flex" }}>
                                <h5>App Id:</h5>
                                <div style={editCss}>
                                  <b
                                    className="appidShow"
                                    style={{
                                      fontSize: "16px",
                                      color: "#111",
                                      fontWeight: 500,
                                    }}
                                  >
                                    {this.state.info.app_id}
                                  </b>
                                  <input
                                    type="text"
                                    className="form-control appidEdit"
                                    placeholder="AppId"
                                    id="appid"
                                    name="app_id"
                                    style={{
                                      width: "fit-content",
                                      display: "none",
                                      border: "none",
                                      height: "18px",
                                    }}
                                    onChange={(e) => this.handleChange1(e)}
                                    onBlur={() => this.AppidSave()}
                                    value={this.state.info.app_id}
                                  />{" "}
                                  <span>
                                    {" "}
                                    <FaRegEdit
                                      onClick={() => this.editOption()}
                                      style={{ marginBottom: "6px" }}
                                      className="editicon"
                                    />
                                  </span>
                                </div>
                              </div>
                              <div style={{ display: "flex", gap: "15px" }}>
                                <span
                                  className="badge badge-primary f-14"
                                  style={{ fontSize: "15px", marginTop: "3%" }}
                                >
                                  {getFileStatus(this.state.info.file_status)}
                                </span>
                                {this.state.info.rectify_status != "" &&
                                  this.state.info.file_status == "9" && (
                                    <span
                                      className="badge f-14 text-white"
                                      style={{
                                        fontSize: "15px",
                                        marginTop: "3%",
                                        background:
                                          "linear-gradient(45deg, #e8321f, #e74c3c)",
                                      }}
                                    >
                                      {this.state.info.rectify_status}
                                    </span>
                                  )}
                                {this.state.info.file_grade == "E" && (
                                  <>
                                    <span
                                      className="badge badge-warning f-14"
                                      style={{
                                        fontSize: "15px",
                                        marginTop: "3%",
                                      }}
                                    >
                                      Emergent
                                    </span>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>

                          <div style={{ marginTop: "2%" }}>
                            {roleType !== "7" && roleType !== "8" && (
                              <button
                                style={{ margin: "auto" }}
                                className="ml2 btn btn-primary btnupload float-right"
                                data-toggle="modal"
                                data-target="#upload-modal"
                                onClick={() => this.getCategories()}
                              >
                                Upload
                              </button>
                            )}
                            {roleType == "7" && takenfile == currentfile && (
                              <button
                                style={{ margin: "auto" }}
                                className="ml2 btn btn-primary btnupload float-right"
                                data-toggle="modal"
                                data-target="#upload-modal"
                                onClick={() => this.getCategories()}
                              >
                                Upload
                              </button>
                            )}
                            {/* <Link
                              to={`${fileurl}`}
                              className="btn btn-square float-right"
                              style={{ color: 'black', border: '1px solid black', borderRadius: '5px' }}
                            >
                              Back
                            </Link> */}
                            <button
                              onClick={() => this.handlePopState("back")}
                              className="btn btn-square float-right"
                              style={{
                                color: "black",
                                border: "1px solid black",
                                borderRadius: "5px",
                              }}
                            >
                              Back
                            </button>
                            {workfileEnable ? (
                              <>
                                {role_id !== FRONTOFFICE_ROLE ? (
                                  <>
                                    {this.state.info.opinion_type != "1" &&
                                    this.state.info.opinion_type != "0" &&
                                    this.state.info.opinion_type != null ? (
                                      this.state.info.file_status === "6" ? (
                                        <button
                                          className="btn btn-success"
                                          data-toggle="modal"
                                          data-target="#confirm-complete"
                                          onClick={() =>
                                            this.processComplete("verify")
                                          }
                                          style={{
                                            marginTop: "0px",
                                            float: "right",
                                          }}
                                        >
                                          Verification Completed
                                        </button>
                                      ) : this.state.info.file_status != "5" ? (
                                        <>
                                          <button
                                            onClick={() =>
                                              this.processComplete("process")
                                            }
                                            data-toggle="modal"
                                            data-target="#confirm-complete"
                                            type="button"
                                            className="btn btn-primary"
                                            style={{
                                              marginTop: "0px",
                                              float: "right",
                                            }}
                                          >
                                            Typing Completed
                                          </button>
                                        </>
                                      ) : (
                                        ""
                                      )
                                    ) : (
                                      ""
                                    )}
                                    {this.state.info.file_status === "9" ? (
                                      <>
                                        <button
                                          className="btn btn-success"
                                          // data-toggle="modal"
                                          // data-target="#confirm-complete"
                                          onClick={() =>
                                            this.processComplete("rectify")
                                          }
                                          style={{
                                            marginTop: "0px",
                                            float: "right",
                                          }}
                                        >
                                          Complete
                                        </button>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    <button
                                      className="ml2 btn btn-success btnupload float-right"
                                      onClick={() => this.OPenToaster()}
                                    >
                                      Work File
                                    </button>
                                    <button
                                      className="ml2 btn btn-success btnupload float-right"
                                      disabled={this.state.isFlowChartUploading}
                                      onClick={() => {
                                        this.props.history.push(
                                          "/sale-deed/" +
                                            this.props.match.params.id
                                        );
                                      }}
                                    >
                                      Flowchart
                                    </button>
                                    {this.state.query_tree_length < 1 && (
                                      <>
                                        <Link
                                          to={
                                            "/query/add/0/" +
                                            this.props.match.params.id
                                          }
                                          className="btn btn-square btn-info float-right"
                                        >
                                          Raise Query
                                        </Link>
                                      </>
                                    )}
                                    <button
                                      style={{
                                        margin: "auto",
                                        marginRight: "1%",
                                      }}
                                      className="btn btn-primary btnupload float-right"
                                      onClick={() => this.getHold()}
                                    >
                                      Hold Reason
                                    </button>
                                    <Link
                                      to={
                                        "/vetting-overview/" +
                                        this.props.match.params.id
                                      }
                                      className="btn btn-square btn-info float-right"
                                    >
                                      Vetting
                                    </Link>
                                  </>
                                ) : (
                                  <>
                                    <Link
                                      to={
                                        "/vetting-overview/" +
                                        this.props.match.params.id
                                      }
                                      className="btn btn-square btn-info float-right"
                                    >
                                      Vetting
                                    </Link>
                                    {role_id == FRONTOFFICE_ROLE && (
                                      <>
                                        <Link
                                          to={
                                            "/work-file/7/" +
                                            this.props.match.params.id
                                          }
                                          className="btn btn-square btn-success float-right"
                                        >
                                          Work File
                                        </Link>
                                        <button
                                          className="ml2 btn btn-success btnupload float-right"
                                          onClick={() => {
                                            this.props.history.push(
                                              "/sale-deed/" +
                                                this.props.match.params.id
                                            );
                                          }}
                                        >
                                          Flowchart
                                        </button>
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                {role_id !== FRONTOFFICE_ROLE ? (
                                  <>
                                    {this.state.info.opinion_type != "1" &&
                                    this.state.info.opinion_type != "0" ? (
                                      this.state.info.file_status === "6" ? (
                                        <button
                                          className="btn btn-success"
                                          data-toggle="modal"
                                          data-target="#confirm-complete"
                                          onClick={() =>
                                            this.processComplete("verify")
                                          }
                                          style={{
                                            marginTop: "0px",
                                            float: "right",
                                          }}
                                        >
                                          Verification Completed
                                        </button>
                                      ) : this.state.info.file_status != "5" ? (
                                        <>
                                          <button
                                            onClick={() =>
                                              this.processComplete("process")
                                            }
                                            data-toggle="modal"
                                            data-target="#confirm-complete"
                                            type="button"
                                            className="btn btn-primary"
                                            style={{
                                              marginTop: "0px",
                                              float: "right",
                                            }}
                                          >
                                            Typing Completed
                                          </button>
                                        </>
                                      ) : (
                                        ""
                                      )
                                    ) : (
                                      ""
                                    )}
                                    {this.state.info.file_status === "9" ? (
                                      <>
                                        <button
                                          className="btn btn-success"
                                          // data-toggle="modal"
                                          // data-target="#confirm-complete"
                                          onClick={() =>
                                            this.processComplete("rectify")
                                          }
                                          style={{
                                            marginTop: "0px",
                                            float: "right",
                                          }}
                                        >
                                          Complete
                                        </button>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    <Link
                                      to={
                                        "/work-file/7/" +
                                        this.props.match.params.id
                                      }
                                      className="btn btn-square btn-success float-right"
                                    >
                                      Work File
                                    </Link>
                                    <button
                                      className="ml2 btn btn-success btnupload float-right"
                                      onClick={() => {
                                        this.props.history.push(
                                          "/sale-deed/" +
                                            this.props.match.params.id
                                        );
                                      }}
                                    >
                                      Flowchart
                                    </button>
                                    {this.state.query_tree_length < 1 && (
                                      <>
                                        <Link
                                          to={
                                            "/query/add/0/" +
                                            this.props.match.params.id
                                          }
                                          className="btn btn-square btn-info float-right"
                                        >
                                          Raise Query
                                        </Link>
                                      </>
                                    )}
                                    <button
                                      style={{
                                        margin: "auto",
                                        marginRight: "1%",
                                      }}
                                      className="ml2 btn btn-primary btnupload float-right"
                                      onClick={() => this.getHold()}
                                    >
                                      Hold Reason
                                    </button>
                                    <Link
                                      to={
                                        "/vetting-overview/" +
                                        this.props.match.params.id
                                      }
                                      className="btn btn-square btn-info float-right"
                                    >
                                      Vetting
                                    </Link>
                                  </>
                                ) : (
                                  <>
                                    {role_id != FRONTOFFICE_ROLE && (
                                      <Link
                                        to={
                                          "/vetting-overview/" +
                                          this.props.match.params.id
                                        }
                                        className="btn btn-square btn-info float-right"
                                      >
                                        Vetting
                                      </Link>
                                    )}
                                    {roleType === "10" ||
                                      (role_id == FRONTOFFICE_ROLE && (
                                        <Link
                                          to={
                                            "/work-file/7/" +
                                            this.props.match.params.id
                                          }
                                          className="btn btn-square btn-success float-right"
                                        >
                                          Work File
                                        </Link>
                                      ))}
                                    {roleType === "9" ||
                                      (role_id == FRONTOFFICE_ROLE && (
                                        <Link
                                          to={
                                            "/work-file/7/" +
                                            this.props.match.params.id
                                          }
                                          className="btn btn-square btn-success float-right"
                                        >
                                          Work File
                                        </Link>
                                      ))}
                                    {role_id == FRONTOFFICE_ROLE && (
                                      <>
                                        <button
                                          className="ml2 btn btn-success btnupload float-right"
                                          onClick={() => {
                                            this.props.history.push(
                                              "/sale-deed/" +
                                                this.props.match.params.id
                                            );
                                          }}
                                        >
                                          Flowchart
                                        </button>
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                            {role_id !== FRONTOFFICE_ROLE && (
                              <div className="template-select">
                                {this.state.bank_templates &&
                                  this.state.bank_templates.length > 0 &&
                                  this.state.bank_templates.map((opt: any) => {
                                    opt.label = opt.name;
                                    opt.value = opt.id;
                                  }) && (
                                    <Select
                                      options={banktemplatesoption}
                                      name="bank_id"
                                      value={this.state.bank_templates.find(
                                        (o: any) =>
                                          o.value === this.state.bank_id
                                      )}
                                      onChange={(e) =>
                                        this.handleChange(
                                          e,
                                          "bank_id",
                                          "singleselect"
                                        )
                                      }
                                    />
                                  )}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="card-body">
                          {/* get_rectify_data */}
                          {isFileRefresh &&
                          Rectify_files &&
                          Rectify_files.length > 0 ? (
                            <div
                              className="p-3 m-2 mb-5 rectification-container"
                              style={{
                                background:
                                  Rectify_files[0].rectify_status == "2"
                                    ? "linear-gradient(45deg, #28a745, #218838)"
                                    : "linear-gradient(45deg, #e8321f, #e74c3c)",
                                borderRadius: "10px",
                                boxShadow: "0 0 20px rgba(0, 0, 0, 0.5)",
                                transition: "transform 0.3s, box-shadow 0.3s",
                              }}
                            >
                              <h2
                                className="text-center font-weight-bold"
                                style={{
                                  color: "white",
                                  fontSize: "2rem",
                                  marginBottom: "10px",
                                  textShadow: "2px 2px 4px rgba(0, 0, 0, 1.8)",
                                }}
                              >
                                Rectification - {Rectify_files[0].count}
                              </h2>
                              <div style={{ color: "white" }}>
                                <div className="row d-flex justify-content-between pl-3 pr-3">
                                  <p
                                    style={{
                                      fontSize: "1.3rem",
                                      marginBottom: "12px",
                                      color: "white",
                                    }}
                                  >
                                    {Rectify_files[0].reason}
                                  </p>
                                  <h4
                                    className="text-center font-weight-bold"
                                    style={{
                                      color: "white",
                                      fontSize: "1.2rem",
                                      marginBottom: "10px",
                                      textShadow:
                                        "2px 2px 4px rgba(0, 0, 0, 1.8)",
                                    }}
                                  >
                                    {fileWorkType}
                                  </h4>
                                </div>
                                <div className="row mt-5">
                                  <div className="col-md-4">
                                    {Rectify_files[0].created_dt && (
                                      <p
                                        style={{
                                          fontSize: "1rem",
                                          marginBottom: "5px",
                                        }}
                                      >
                                        <span style={{ fontWeight: "bold" }}>
                                          Received date:
                                        </span>{" "}
                                        {Rectify_files[0].created_dt}
                                      </p>
                                    )}
                                  </div>
                                  <div className="col-md-4">
                                    {Rectify_files[0].completed_dt && (
                                      <p
                                        style={{
                                          fontSize: "1rem",
                                          marginBottom: "5px",
                                        }}
                                      >
                                        <span style={{ fontWeight: "bold" }}>
                                          Completed date:
                                        </span>{" "}
                                        {Rectify_files[0].completed_dt}
                                      </p>
                                    )}
                                  </div>
                                  <div className="col-md-2"></div>
                                  <div className="col-md-2">
                                    {Rectify_files.length > 0 && (
                                      <p
                                        data-toggle="modal"
                                        data-target="#rectify-modal"
                                        className="read-more-link"
                                      >
                                        Read more...{" "}
                                        <i
                                          className="fa fa-info-circle"
                                          style={{
                                            marginLeft: "5px",
                                            fontSize: "1.2rem",
                                          }}
                                        ></i>
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {(roleType != "16" ||
                            (roleType == "16" && fromDeviation == "false")) && (
                            <>
                              <div className="file-status-container">
                                <ul className="file-status-queue">
                                  <li style={loginStyle}>
                                    <div className="icon">1</div>
                                    <div className="title">Login</div>
                                  </li>
                                  <li style={QueueStyle}>
                                    <div className="icon">2</div>
                                    <div className="title">Queue</div>
                                  </li>
                                  <li style={wipStyle}>
                                    <div className="icon">3</div>
                                    <div className="title">W.I.P</div>
                                  </li>
                                  <li style={QRStyle}>
                                    <div className="icon">4</div>
                                    <div className="title">Q.Raised</div>
                                  </li>
                                  <li style={QreceiveStyle}>
                                    <div className="icon">5</div>
                                    <div className="title">Q.Received</div>
                                  </li>
                                  <li style={TCStyle}>
                                    <div className="icon">6</div>
                                    <div className="title">T.Completed</div>
                                  </li>
                                  <li style={VwipStyle}>
                                    <div className="icon">7</div>
                                    <div className="title">V.W.I.P</div>
                                  </li>
                                  <li style={HoldStyle}>
                                    <div className="icon">8</div>
                                    <div className="title">Hold</div>
                                  </li>
                                  <li style={VcStyle}>
                                    <div className="icon">9</div>
                                    <div className="title">V.Completed</div>
                                  </li>
                                </ul>
                              </div>

                              <div className="row">
                                <DocumentNumber
                                  documentdetail={this.state.other}
                                  categoryname={"Document"}
                                />
                                <DocumentNumber
                                  documentdetail={this.state.ec_doc}
                                  categoryname={"EC"}
                                />
                                <DocumentNumber
                                  documentdetail={this.state.rev_rec_doc}
                                  categoryname={"Revenue Record"}
                                />
                              </div>

                              {updateDownloadData ? (
                                <div
                                  className="row"
                                  style={{ marginBottom: "20px" }}
                                >
                                  <div className="col-md-6">
                                    <div
                                      className="accordion"
                                      id="download-reason"
                                    >
                                      <div className="card addon-card">
                                        <a
                                          className="accordion-toggle addon-accordion"
                                          style={{ backgroundColor: "white" }}
                                          data-toggle="collapse"
                                          data-parent="#download-reason"
                                          href="#downloadReason"
                                        >
                                          <div
                                            className="card-header"
                                            style={{
                                              borderBottomWidth: "0px",
                                              padding: "0px",
                                              justifyContent: "space-between",
                                              display: "flex",
                                            }}
                                            onClick={() => {
                                              this.setState({
                                                isReasonForDownloadShown:
                                                  !isReasonForDownloadShown,
                                              });
                                            }}
                                          >
                                            <h5
                                              style={{
                                                paddingLeft: "5px",
                                                paddingRight: "5px",
                                                fontWeight: "bold",
                                              }}
                                            >
                                              Reason for download
                                            </h5>
                                            <i
                                              className={`icon feather icon-${
                                                isReasonForDownloadShown
                                                  ? "minus"
                                                  : "plus"
                                              }`}
                                              style={{
                                                color: "black",
                                                fontSize: "17px",
                                                fontWeight: "bold",
                                              }}
                                            />
                                          </div>
                                        </a>
                                        <div
                                          id="downloadReason"
                                          className="panel-body collapse"
                                          style={{
                                            maxHeight: "475px",
                                            height: "auto",
                                            overflowX: "hidden",
                                          }}
                                        >
                                          <div className="card-body">
                                            {this.renderDetailsCard(
                                              "DOWNLOAD",
                                              updateDownloadData.reasonForDownload
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {updateDownloadData ? (
                                    <div className="col-md-6">
                                      <div
                                        className="accordion"
                                        id="update-details"
                                      >
                                        <div className="card addon-card">
                                          <a
                                            className="accordion-toggle addon-accordion"
                                            style={{ backgroundColor: "white" }}
                                            onClick={() =>
                                              this.getUpdateReasonData()
                                            }
                                            data-toggle="collapse"
                                            data-parent="#update-details"
                                            href="#updateDetails"
                                          >
                                            <div
                                              className="card-header"
                                              style={{
                                                borderBottomWidth: "0px",
                                                padding: "0px",
                                                justifyContent: "space-between",
                                                display: "flex",
                                              }}
                                            >
                                              <div className="">
                                                <h5
                                                  style={{
                                                    paddingLeft: "5px",
                                                    paddingRight: "5px",
                                                    fontWeight: "bold",
                                                  }}
                                                  onClick={() => {
                                                    this.setState({
                                                      isUpdateDownloadShown:
                                                        !isUpdateDownloadShown,
                                                    });
                                                  }}
                                                >
                                                  Updated Details
                                                </h5>
                                              </div>
                                              <div>
                                                {this.state.blockdownload ==
                                                "false" ? (
                                                  <button
                                                    className="btn btn-outline-dark btn-sm"
                                                    onClick={() =>
                                                      this.actionHandle(
                                                        "upload"
                                                      )
                                                    }
                                                    data-toggle="modal"
                                                    data-target="#reason-modal1"
                                                  >
                                                    Upload Report{" "}
                                                    <i className="fa fa-upload" />
                                                  </button>
                                                ) : (
                                                  ""
                                                )}
                                                <i
                                                  className={`icon feather icon-${
                                                    isUpdateDownloadShown
                                                      ? "minus"
                                                      : "plus"
                                                  }`}
                                                  style={{
                                                    color: "black",
                                                    fontSize: "17px",
                                                    fontWeight: "bold",
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </a>
                                          <div
                                            id="updateDetails"
                                            className="panel-body collapse"
                                            style={{
                                              maxHeight: "475px",
                                              height: "auto",
                                              overflowX: "hidden",
                                            }}
                                          >
                                            <div className="card-body">
                                              {this.renderDetailsCard(
                                                "UPDATE",
                                                updateDownloadData.updatedDetails
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              ) : null}

                              <div className="row">
                                <div className="col-md-12">
                                  <div className="card table-card">
                                    <div className="row-table">
                                      <div className="col-sm-4 card-body-big br">
                                        <div className="row">
                                          <div className="col-sm-4">
                                            <i className="icon feather icon-eye text-c-green mb-1 d-block" />
                                          </div>
                                          <div className="col-sm-8 text-md-center">
                                            <h5>Original verification</h5>
                                            <span>
                                              {this.state.info.verify}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-sm-4 card-body-big br">
                                        <div className="row">
                                          <div className="col-sm-4">
                                            <i className="icon feather  icon-file-text text-c-red mb-1 d-block" />
                                          </div>
                                          <div className="col-sm-8 text-md-center">
                                            <h5>Report Status</h5>
                                            <span>
                                              {this.state.info.report_status}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-sm-4 card-body-big">
                                        <div className="row">
                                          <div className="col-sm-2">
                                            <i className="icon feather icon-help-circle text-c-yellow mb-1 d-block" />
                                          </div>
                                          <div className="col-sm-10 text-md-center">
                                            <h5>Query Request</h5>

                                            <span className="text-c-red">
                                              Pending -{" "}
                                              {this.state.info.pending}
                                            </span>
                                            <span className="text-c-green">
                                              {" "}
                                              / Closed -{" "}
                                              {this.state.info.closed}
                                            </span>
                                            <span className="text-c-info">
                                              {" "}
                                              / Total - {this.state.info.total}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}

                          {/* FLOW CHART PREVIEW */}
                          {this.state.flowChartPreview ? (
                            <div className="row">
                              <div className="col-sm-12">
                                <div className="card query-card">
                                  <div className="card-header d-flex align-items-center justify-content-between">
                                    <h5>Flowchart</h5>
                                    {/* <button
                                      className="ml2 btn btn-danger btnupload float-right"
                                      disabled={this.state.isFlowChartUploading}
                                    >
                                      {this.state.isFlowChartUploading && (
                                        <i className="fa fa-spinner fa-spin"></i>
                                      )}
                                      Download
                                    </button> */}
                                    <button
                                      className="ml2 btn btn-danger btnupload float-right"
                                      disabled={this.state.isFlowChartUploading}
                                      onClick={this.deleteFlowChart}
                                    >
                                      {this.state.isFlowChartUploading && (
                                        <i className="fa fa-spinner fa-spin"></i>
                                      )}
                                      Delete
                                    </button>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <img
                                      src={this.state.flowChartPreview}
                                      className="img-fluid"
                                      alt="User-Profile-Image"
                                      style={{ padding: "5%" }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}

                          <div className="row">
                            <div className="col-md-12">
                              {isRaiseQueryShown && newQueryList && (
                                <>
                                  <div
                                    className="raise-query-style"
                                    style={{ marginTop: "4%", flex: 1 }}
                                  >
                                    <QueryAdd
                                      addnewQueryList={newQueryList}
                                      handleChange={this.handleChangequery}
                                      handleDetailsChange={
                                        this.handleDetailsChange
                                      }
                                      removeReply={this.removeReply}
                                      newaddrequery={this.newaddrequery}
                                      queryreason={this.state.reasons}
                                    />
                                  </div>
                                </>
                              )}
                              {this.state.queryTableData?.length > 0 && (
                                <>
                                  <QueryTable
                                    history={this.props.history}
                                    match={this.props.match}
                                    newaddrequery={this.newaddrequery}
                                    addrequery={this.addrequery}
                                    Completequery={this.CompleteConfirm}
                                    queryTableData={this.state.queryTableData}
                                  />
                                </>
                              )}
                            </div>
                          </div>

                          {(roleType != "16" ||
                            (roleType == "16" && fromDeviation == "false")) && (
                            <>
                              <div className="row">
                                <div className="col-sm-12">
                                  <div className="card query-card">
                                    <div className="card-header d-flex justify-content-between">
                                      <h5>Query Manage</h5>
                                      <div className="d-flex">
                                        {this.state.deviationTakenQuerys
                                          .length > 0 && (
                                          <>
                                            <button
                                              type="button"
                                              className="btn btn-info btn-sm"
                                              data-toggle="modal"
                                              data-target="#deviationModal"
                                            >
                                              Request Deviation
                                            </button>
                                          </>
                                        )}

                                        {this.state.query_tree_length < 1 &&
                                          this.state.info.noquery_status ==
                                            "0" && (
                                            <button
                                              type="button"
                                              className="btn btn-info btn-sm"
                                              data-toggle="modal"
                                              data-target="#no-query"
                                            >
                                              No Query
                                            </button>
                                          )}
                                        {this.state.info.file_status == "9" &&
                                          this.state.info
                                            .rectify_noquery_status == "1" && (
                                            <>
                                              <button
                                                type="button"
                                                className="btn btn-info btn-sm"
                                                data-toggle="modal"
                                                data-target="#no-query"
                                              >
                                                No Query
                                              </button>
                                            </>
                                          )}
                                        {/* new qeury table start */}
                                        {/* <button type="button" className="btn btn-info btn-sm" onClick={() => {
                                      this.addQueryList()
                                    }}>
                                      Raise Query Table
                                    </button> */}
                                        {/* new qeury table end */}

                                        <button
                                          type="button"
                                          className="btn btn-info btn-sm"
                                          onClick={() => {
                                            this.query.current.updateRaiseQuery();
                                          }}
                                        >
                                          Raise Query
                                        </button>
                                        <ReactFileReader
                                          fileTypes={[".pdf"]}
                                          base64={true}
                                          multipleFiles={true}
                                          handleFiles={(e) =>
                                            this.handleFiles(e)
                                          }
                                        >
                                          <button
                                            type="button"
                                            className="btn btn-info btn-sm"
                                          >
                                            Upload Document
                                          </button>
                                        </ReactFileReader>
                                        <button
                                          type="button"
                                          className="btn btn-info btn-sm"
                                          onClick={() =>
                                            this.updateNewQueryList()
                                          }
                                        >
                                          Submit All
                                        </button>
                                      </div>
                                      {/* {multiSelectedQuery.length > 0 ?
                                  <div className="d-flex">
                                    <button type="button" className="btn btn-info btn-sm" onClick={()=>{
                                      this.props.history.push(`/query/add/${1}/${2}`,{
                                        selectedQueryIds:this.state.multiSelectedQuery
                                      })
                                    }}>Reply All</button>
                                    <button type="button" className="btn btn-info btn-sm">Close All</button>
                                  </div>
                                   :null} */}
                                    </div>
                                    <div className="card-body">
                                      <div className="query-document">
                                        {this.state.queryBase &&
                                        this.state.queryBase.length
                                          ? this.state.queryBase.map(
                                              (itm: any, index: number) => {
                                                return (
                                                  <div key={index}>
                                                    <div className="card card-border-c-green">
                                                      <span
                                                        onClick={() =>
                                                          this.deleteDoc(
                                                            itm,
                                                            index
                                                          )
                                                        }
                                                      >
                                                        <i className="feather icon-x cursor-pointer" />
                                                      </span>
                                                      <div className="card-body">
                                                        <h5 className="card-title">
                                                          <span className="mb-1 text-c-green">
                                                            <i className="feather icon-file-text" />
                                                          </span>
                                                          <span
                                                            style={{
                                                              fontSize: "14px",
                                                            }}
                                                          >
                                                            {itm.fileName || ""}
                                                          </span>
                                                          <span
                                                            style={{
                                                              float: "right",
                                                            }}
                                                            className="cursor-pointer"
                                                            data-toggle="modal"
                                                            data-target="#preview-document"
                                                            onClick={() =>
                                                              this.previewDoc(
                                                                itm
                                                              )
                                                            }
                                                          >
                                                            <i className="fa fa-eye" />
                                                          </span>
                                                        </h5>
                                                      </div>
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            )
                                          : ""}
                                      </div>
                                      {/* new qeury table start */}
                                      {/* {isRaiseQueryShown && newQueryList &&
                                    <>
                                      <div className='raise-query-style' style={{ marginTop: '4%', flex: 1 }}>
                                        <QueryAdd
                                          addnewQueryList={newQueryList}
                                          handleChange={this.handleChangequery}
                                          handleDetailsChange={this.handleDetailsChange}
                                          removeReply={this.removeReply}
                                          newaddrequery={this.newaddrequery}
                                          queryreason={this.state.reasons}
                                        />
                                      </div>
                                    </>
                                  }
                                  <QueryTable
                                    history={this.props.history}
                                    match={this.props.match}
                                    newaddrequery={this.newaddrequery}
                                    addrequery={this.addrequery}
                                    Completequery={this.CompleteConfirm}
                                    queryTableData={this.state.queryTableData} /> */}
                                      {/* new qeury table end */}

                                      <QueryTree
                                        fod_fk={this.props.match.params.id}
                                        history={this.props.history}
                                        ref={this.query}
                                        setSelectedItem={(item) =>
                                          this.setSelectedItem(item)
                                        }
                                        getRepliedQueries={this.repliedQueries}
                                        getDeviationQueryData={
                                          this.getDeviationQueryData
                                        }
                                        getnewquery={this.getNewQuery}
                                        queryreason={this.state.reasons}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="preview-pdf"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-lg"
            role="document"
            style={{ maxWidth: "1400px" }}
          >
            <div className="modal-content" style={{ height: "90vh" }}>
              <div className="modal-header">
                <h5 className="modal-title">Document Preview</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <iframe
                  width="100%"
                  height="100%"
                  src={this.state.pdfToPreview}
                ></iframe>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={() => {
                    this.setState({ pdfToPreview: "" });
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal hide fade in"
          id="processComplete"
          data-backdrop="static"
          data-keyboard="false"
        >
          <div className="modal-dialog ">
            <div
              className="modal-content"
              style={{ borderRadius: "10px", boxShadow: "0 0 15px #000" }}
            >
              <div className="modal-body">
                <h5 style={{ textAlign: "center" }}>
                  {" "}
                  Thanks For Complete Your File:{this.props.match.params.id}
                </h5>
              </div>
              <div className="modal-footer">
                {showTitle ? null : (
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={() => this.close()}
                    // onClick={this.disableModalHandler}
                    style={{ width: "50%", fontSize: "17px", margin: "auto" }}
                  >
                    {" "}
                    Ok
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="modal" id="complete-process">
          <div className="modal-dialog ">
            <div className="modal-content">
              <div className="modal-body">
                <h3>Without upload document you can't complete the file</h3>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  // onClick={this.disableModalHandler}
                >
                  {" "}
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal"
          id="confirm-complete"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div
                className="modal-body d-flex"
                style={{ justifyContent: "center" }}
              >
                <h5 className="modal-title">
                  Are You Sure You Want to Complete this File?
                </h5>
              </div>
              <div className="modal-footer">
                {this.state.isprocesscomplete ? (
                  <>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => this.confirmComplete("6", "typing")}
                    >
                      Yes
                    </button>
                  </>
                ) : this.state.isverifycomplete ? (
                  <>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => this.confirmComplete("5", "verification")}
                    >
                      Yes
                    </button>
                  </>
                ) : this.state.isrectifycomplete ? (
                  <>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => this.confirmComplete("5", "rectify")}
                    >
                      Yes
                    </button>
                  </>
                ) : null}
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade bd-example-modal-lg"
          id="upload-modal"
          tabIndex={-1}
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            style={{ maxWidth: "1400px" }}
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Upload File</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="text-right">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm upload-document-button"
                    onClick={() => this.addUploadDocument()}
                  >
                    Add Document +
                  </button>
                </div>
                {this.state.base.length > 0 &&
                  this.state.base.map((document, index) => (
                    <DashUploadDocument
                      baseDetails={document}
                      handleDocumentDetailsChange={
                        this.handleDocumentDetailsChange
                      }
                      handleDocumentFilesChange={
                        this.handleDocumentFilesChange1
                      }
                      deleteDocumentDetails={this.deleteDocumentDetails}
                      deleteFile={this.deleteFile}
                      deedList={this.state.deeds}
                      previewDoc={this.previewDoc}
                      categoriesList={this.state.categoriesList}
                      index={index}
                      newfile={this.state.isNewFile}
                      path="filedashboard"
                    />
                  ))}
                <div className="modal-footer" style={{ border: "none" }}>
                  <div style={{ margin: "auto" }}>
                    {this.state.base.length > 0 && (
                      <>
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                          onClick={this.closeuploadfile}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          style={{ margin: "auto" }}
                          // data-dismiss="modal"
                          disabled={this.state.uploadbtn == true}
                          onClick={(e) => this.validateRequiredFileds(e)}
                        >
                          {this.state.isbtnload && (
                            <i className="fa fa-spinner fa-spin"></i>
                          )}
                          Confirm
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade bd-example-modal-lg"
          id="rectify-modal"
          tabIndex={-1}
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            style={{
              maxWidth: "1400px",
              maxHeight: "660px",
              overflow: "hidden",
            }}
            role="document"
          >
            <div
              className="modal-content"
              style={{
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <div
                className="modal-header"
                style={{
                  backgroundColor: "#007bff",
                  color: "white",
                  borderBottom: "none",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                }}
              >
                <h5
                  className="modal-title"
                  style={{ textAlign: "center", color: "white" }}
                >
                  Rectification Details
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div
                className="modal-body"
                style={{
                  backgroundColor: "#f8f9fa",
                  padding: "20px",
                  borderRadius: "8px",
                  maxHeight: "670px",
                  overflowY: "scroll",
                }}
              >
                <div className="dt-responsive table-responsive">
                  <table className="table table-striped table-bordered table-hover">
                    <thead
                      className="thead sticky-table-header"
                      style={{ background: "#d9d9d9" }}
                    >
                      <tr style={{ fontSize: "14px" }}>
                        <th scope="col" className="text-center th">
                          S.no
                        </th>
                        <th scope="col" className="text-center th">
                          Received Date & Time
                        </th>
                        <th scope="col" className="text-center th">
                          Completed Date & Time
                        </th>
                        <th scope="col" className="text-center th">
                          Reason
                        </th>
                      </tr>
                    </thead>
                    <tbody className="tbody">
                      {Rectify_files &&
                        Rectify_files.map((rectify: any, index: any) => (
                          <tr key={index}>
                            <td className="text-center td">{index + 1}</td>
                            <td className="text-center td">
                              {rectify.created_dt}
                            </td>
                            <td className="text-center td">
                              {rectify.completed_dt}
                            </td>
                            <td
                              className="text-center td"
                              style={{ width: "50%", padding: "1%" }}
                            >
                              {rectify.reason}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                className="modal-footer"
                style={{
                  border: "none",
                  backgroundColor: "#f8f9fa",
                  borderBottomLeftRadius: "10px",
                  borderBottomRightRadius: "10px",
                }}
              >
                {/* Additional modal footer content can be added here */}
              </div>
            </div>
          </div>
        </div>
        <div className="modal" id="no-query">
          <div className="modal-dialog">
            <div className="modal-content">
              {/* <div className="modal-header">
                <h4 className="modal-title">Confirm Reset</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div> */}
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <h4 className="modal-title">Are you sure?</h4>
                    {/* <p> Are you sure?</p> */}
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger"
                  data-dismiss="modal"
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn btn-info"
                  data-dismiss="modal"
                  onClick={() => {
                    this.noRaiseQuery();
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade bd-example-modal-lg"
          id="view-reasson"
          tabIndex={-1}
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            style={{
              maxWidth: "1400px",
              maxHeight: "660px",
              overflow: "hidden",
            }}
            role="document"
          >
            <div
              className="modal-content"
              style={{
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <div
                className="modal-header"
                style={{
                  backgroundColor: "#007bff",
                  color: "white",
                  borderBottom: "none",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                }}
              >
                <h5
                  className="modal-title"
                  style={{ textAlign: "center", color: "white" }}
                >
                  Hold & Unhold Details
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div
                className="modal-body"
                style={{
                  backgroundColor: "#f8f9fa",
                  padding: "20px",
                  borderRadius: "8px",
                  maxHeight: "670px",
                  overflowY: "scroll",
                }}
              >
                <div className="dt-responsive table-responsive">
                  <table className="table table-striped table-bordered table-hover">
                    <thead
                      className="thead sticky-table-header"
                      style={{ background: "#d9d9d9" }}
                    >
                      <tr style={{ fontSize: "14px" }}>
                        <th scope="col" className="text-center th">
                          S.no
                        </th>
                        <th scope="col" className="text-center th">
                          Hold Data
                        </th>
                        <th scope="col" className="text-center th">
                          Hold By
                        </th>
                        <th scope="col" className="text-center th">
                          Hold Reason
                        </th>
                        <th scope="col" className="text-center th">
                          Unhhold Data
                        </th>
                        <th scope="col" className="text-center th">
                          Unhold By
                        </th>
                        <th scope="col" className="text-center th">
                          Unhold Reason
                        </th>
                      </tr>
                    </thead>
                    <tbody className="tbody">
                      {this.state.holdDetails &&
                        this.state.holdDetails.map((data: any, index: any) => (
                          <tr key={index}>
                            <td className="text-center td">{index + 1}</td>
                            <td className="text-center td">{data.hold_dt}</td>
                            <td className="text-center td">{data.hold_by}</td>
                            <td
                              className="text-center td"
                              style={{ width: "25%", padding: "1%" }}
                            >
                              {data.hold_reason}
                            </td>
                            <td className="text-center td">{data.unhold_dt}</td>
                            <td className="text-center td">{data.unhold_by}</td>
                            <td
                              className="text-center td"
                              style={{ width: "25%", padding: "1%" }}
                            >
                              {data.unhold_reason}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                className="modal-footer"
                style={{
                  border: "none",
                  backgroundColor: "#f8f9fa",
                  borderBottomLeftRadius: "10px",
                  borderBottomRightRadius: "10px",
                }}
              >
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  close
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="reason-modal"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Confirm Status</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {/* <h6>Are you sure you want to delete this document?</h6> */}
                <div className="form-group">
                  {/* <label>Reason</label> */}
                  <textarea
                    name="reason"
                    className="form-control"
                    onChange={(e) => this.handleChange(e)}
                    value={this.state.reason}
                    placeholder="Reason"
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  onClick={this.submitReason}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal" id="reason-modal1">
          <div className="modal-dialog ">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Reason For Upload</h5>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <div>
                  <React.Fragment>
                    <label>Enter Updated Informations*</label>
                    <ReactFileReader
                      fileTypes={[".doc", ".docx"]}
                      base64={true}
                      // multipleFiles={true}
                      handleFiles={(e) => this.uploadFile(e)}
                    >
                      <button className="ml2 btn btn-success btnupload">
                        Select File
                      </button>
                    </ReactFileReader>
                    <span>{uploadFile.fileList[0].name}</span>
                  </React.Fragment>
                  <textarea
                    className="form-control mt-10"
                    name="reason"
                    value={reason}
                    onChange={this.handleReasonChange}
                  ></textarea>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn " data-dismiss="modal">
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={this.validateReason}
                >
                  Upload
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal" id="send-mail">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Send Mail</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                {!quesSubmitted && (
                  <div className="query-question">
                    <div className="row">
                      {queryQues.map((ques: any, index) => (
                        <div className="col-md-6" key={index}>
                          <div className="question">
                            <p>{ques.query_question}</p>
                            <div className="row">
                              <div className="col-md-3">
                                <div className="custom-control custom-checkbox">
                                  <input
                                    type="radio"
                                    className=""
                                    name={`answer-${index}`}
                                    checked={ques.answer}
                                    onChange={() =>
                                      this.handleAnswerChange(index, true)
                                    }
                                  />
                                  <label className="">Yes</label>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="custom-control custom-checkbox">
                                  <input
                                    type="radio"
                                    className=""
                                    name={`answer-${index}`}
                                    checked={!ques.answer}
                                    onChange={() =>
                                      this.handleAnswerChange(index, false)
                                    }
                                  />
                                  <label className="">No</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div>
                      <button
                        type="button"
                        className="btn btn-info"
                        onClick={this.saveQueryQuestion}
                      >
                        <span>Submit</span>
                      </button>
                    </div>
                  </div>
                )}
                {quesSubmitted && (
                  <div className="row">
                    <div className="col-md-6">
                      <label>To:</label>
                      <div className="form-group">
                        {this.state.office_users &&
                        this.state.office_users.length ? (
                          this.state.office_users.map((user: any, index) => {
                            return (
                              <div
                                className="custom-control custom-checkbox"
                                key={index}
                              >
                                <input
                                  type="checkbox"
                                  className=""
                                  onChange={(e) => this.selectUser(e, user)}
                                  defaultChecked
                                />
                                <label className="">
                                  {user.name + " - " + user.email}
                                </label>
                              </div>
                            );
                          })
                        ) : (
                          <button
                            onClick={this.addItem}
                            className="btn btn-primary m-b-20"
                          >
                            + Add User
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label>Cc:</label>
                      <div className="form-group">
                        {this.state.office_users &&
                        this.state.office_users.length ? (
                          this.state.office_users.map((user: any, index) => {
                            return (
                              <div
                                className="custom-control custom-checkbox"
                                key={index}
                              >
                                <input
                                  type="checkbox"
                                  className=""
                                  onChange={(e) => this.selectCcUser(e, user)}
                                  defaultChecked
                                />
                                <label className="">
                                  {user.name + " - " + user.email}
                                </label>
                              </div>
                            );
                          })
                        ) : (
                          <button
                            onClick={this.addItem}
                            className="btn btn-primary m-b-20"
                          >
                            + Add User
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {quesSubmitted && (
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-danger"
                    data-dismiss="modal"
                    onClick={() => {
                      this.setState({ quesSubmitted: false });
                    }}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-info"
                    // data-dismiss="modal"
                    disabled={queryLoading}
                    onClick={this.saveQuery}
                  >
                    {queryLoading ? (
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <span>Send</span>
                    )}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className={`modal ${this.state.previewDoc ? "open" : ""}`}
          id="preview-document"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Document Preview</h5>
                <button
                  type="button"
                  className="close cursor-pointer"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.setState({ previewDoc: "" })}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <iframe
                  width="100%"
                  height="400px"
                  src={this.state.previewDoc}
                ></iframe>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={() => this.setState({ previewDoc: "" })}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal hide fade in"
          id="in_time"
          data-backdrop="static"
          data-keyboard="false"
        >
          <div className="modal-dialog ">
            <div
              className="modal-content"
              style={{ borderRadius: "10px", boxShadow: "0 0 15px #000" }}
            >
              <div
                className="modal-body"
                style={{ padding: "30px 23px 29px 24px" }}
              >
                <h5>
                  Please Select a Reason To Open File.No:{" "}
                  {this.props.match.params.id}
                </h5>
                <div
                  className="form-group"
                  style={{ width: "74%", margin: "auto" }}
                >
                  <select
                    className="custom-select"
                    name="in_reason"
                    value={this.state.in_reason.trimStart()}
                    onChange={(e) => this.onchangeReason(e)}
                    required
                    style={{ marginTop: "24px" }}
                  >
                    <option value="select">Select.</option>
                    <option value="New Login">New Login</option>
                    <option value="Manual">Manual</option>
                    <option value="Query Received">Query Received</option>
                    <option value="Previous Login Pending">
                      Previous Login Pending
                    </option>
                    <option value="Previous Query Pending">
                      Previous Query Pending
                    </option>
                    <option value="Re-Assign">Re-Assign</option>
                    <option value="Doubts&Clarification">
                      Doubts/Clarification
                    </option>
                    <option value="Verification">Verification</option>
                    <option value="Data Entry">Data Entry</option>
                    <option value="Vetting">Vetting</option>
                    <option value="other">Others</option>
                  </select>
                  {selectVal ? (
                    <p
                      style={{
                        color: "red",
                        textAlign: "center",
                        fontSize: "16px",
                      }}
                    >
                      Please Select Your Reason{" "}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.SubmitREsponse()}
                  disabled={this.state.isLoading}
                  style={{ width: "76px", fontSize: "17px" }}
                >
                  {" "}
                  {this.state.isLoading ? (
                    <i className="fa fa-spinner fa-spin"></i>
                  ) : (
                    "OK"
                  )}
                </button>
                <button
                  type="button"
                  className="btn btn-outline"
                  onClick={() => this.previousPage("back")}
                  style={{
                    width: "76px",
                    fontSize: "17px",
                    border: "1px solid black",
                  }}
                >
                  {" "}
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal hide fade in"
          id="another-reason"
          data-backdrop="static"
          data-keyboard="false"
        >
          <div className="modal-dialog ">
            <div
              className="modal-content"
              style={{ borderRadius: "10px", boxShadow: "0 0 15px #000" }}
            >
              <h5 style={{ marginTop: "31px", textAlign: "center" }}>
                Please Type a Reason To Open File.No:{" "}
                {this.props.match.params.id}{" "}
              </h5>

              <div
                className="modal-body"
                style={{ padding: "30px 23px 29px 24px" }}
              >
                {/* <div className="col-md-5"> */}
                {this.state.showModal && (
                  <div
                    className="form-group"
                    style={{ width: "98%", margin: "auto" }}
                  >
                    <textarea
                      typeof="text"
                      className="form-control"
                      placeholder="Type Your Reason"
                      name="in_reason"
                      onChange={this.onchange}
                      ref={this.textareaRef}
                      autoFocus={true}
                    />
                    {selectVal ? (
                      <p
                        style={{
                          color: "red",
                          textAlign: "center",
                          fontSize: "16px",
                        }}
                      >
                        Please Type Your Reason{" "}
                      </p>
                    ) : null}
                  </div>
                )}
                {/* </div> */}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.SubmitREsponse()}
                  disabled={this.state.isLoading}
                  style={{ width: "76px", fontSize: "17px" }}
                >
                  {" "}
                  {this.state.isLoading ? (
                    <i className="fa fa-spinner fa-spin"></i>
                  ) : (
                    "OK"
                  )}
                </button>
                <button
                  type="button"
                  className="btn btn-outline"
                  onClick={() => this.previousPop()}
                  // onClick={this.disableModalHandler}
                  style={{
                    width: "76px",
                    fontSize: "17px",
                    border: "1px solid black",
                  }}
                >
                  {" "}
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="deviationModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="deviationLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5>Are you sure you want to request for deviation?</h5>
              </div>
              <div className="modal-body">
                <p style={{ textAlign: "center", color: "black" }}>
                  Please Type a Reason To deviation request for this query
                </p>
                <div
                  className="form-group"
                  style={{ width: "98%", margin: "auto" }}
                >
                  <textarea
                    className="form-control"
                    placeholder="Type Your Reason"
                    onChange={(e) => this.handleDeviationReasonChange(e)}
                    value={this.state.deviationReason}
                    name="deviationReason"
                  />
                  {isEmpty(this.state.deviationReason) ? (
                    <p
                      style={{
                        color: "red",
                        textAlign: "center",
                        fontSize: "16px",
                      }}
                    >
                      Please type your reason{" "}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={() => this.closeDeviationModal()}
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.submitRequeryDeviation()}
                >
                  {this.state.isLoading ? (
                    <i className="fa fa-spinner fa-spin"></i>
                  ) : (
                    "Yes"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
        {this.state.showPopup && (
          <div className="dialog-modal" role="dialog" aria-hidden="true">
            <div className="dialog-modal-body" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">
                    {this.state.selectedQueryname}
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => this.setState({ showPopup: false })}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p>{this.state.selectedQuerytitle}</p>
                  <div style={{ width: "100%", textAlignLast: "center" }}>
                    <button
                      onClick={() => this.Completequery()}
                      className="justify-content-center ml2 btn btn-success btnupload text-center"
                      // disabled={isBtnLoad}
                    >
                      Complete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          className="modal hide fade in"
          id="file_not_complete_modal"
          data-backdrop="static"
          data-keyboard="false"
        >
          <div className="modal-dialog ">
            <div
              className="modal-content"
              style={{ borderRadius: "10px", boxShadow: "0 0 15px #000" }}
            >
              <div className="modal-body">
                <h5
                  style={{ textAlign: "left" }}
                  dangerouslySetInnerHTML={this.state.fileNotCompleteMsg}
                ></h5>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-success"
                  data-dismiss="modal"
                  style={{ width: "50%", fontSize: "17px", margin: "auto" }}
                >
                  {" "}
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default FileDashboard;
