import React, { Component } from "react";
import { callApi } from "../../utils/api";
import Select from 'react-select';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
export interface MeasureLabel {
  measurement: string;
  measurement_id?: number;
  meas_dir_list: MeasureDirList[];
  collapseid?: boolean;
  m_unit: string;
  l_unit: string;
  measureNoType: string;
  plot_item_no: string;
}
export interface MeasureDirList {
  meas_dir_id?: number;
  label: string;
  value: string;
  measure_custom: string;

}
interface AllProps {
  stateToProps: any;
  measurementDetail: any;
  extentUnits: any;
  required: any;
}
export default class MeasureLabelItem extends Component<AllProps> {
  state = {
    measurement_list: this.props.measurementDetail,
    delete_items_measurements: [],
    delete_items_measure_label: [],
    measures: [],
    required: this.props.required,
    extentUnits: this.props.extentUnits,
    customUnits: []
  };
  componentDidMount() {
    this.getMeasures();
    this.getCustomUnits();
    if (this.props.measurementDetail && this.props.measurementDetail.length) {
      this.setState({ survey: this.props.measurementDetail });
    } else if (
      !this.state.measurement_list ||
      !this.state.measurement_list.length
    ) {
      this.setState({ measure_list: [] });
      this.addMeasure();
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.measurementDetail && nextProps.measurementDetail.length) {
      this.setState({ measurement_list: nextProps.measurementDetail });
    } else if (
      !this.state.measurement_list ||
      !this.state.measurement_list.length
    ) {
      this.setState({ measurement_list: [] });
      this.addMeasure();
    }
  }
  getMeasures = () => {
    callApi("GET", "measure_get.php")
      .then(res => res.data)
      .then(response => {
        this.setState({ measures: response.data });
      })
      .catch(err => {
        console.log(err);
      });
  };


  getCustomUnits = () => {
    const customUnits = [
      {
        id: 'Lings',
        name: 'Ling'
      },
      {
        id: 'Yards',
        name: 'Yards'
      },
      {
        id: 'ft',
        name: 'ft'
      },
      {
        id: 'mt',
        name: 'mt'
      },
      {
        id: 'Ankanam',
        name: 'Ankanam'
      }
    ]
    this.setState({ customUnits })
  };

  addMeasure = (): void => {
    const measurement_list: MeasureLabel[] = this.state.measurement_list
      ? this.state.measurement_list
      : [];
    measurement_list.push({
      measurement: "", m_unit: "", l_unit: "", measureNoType: "", plot_item_no: "",
      meas_dir_list: [{ label: "", value: "", measure_custom: "" }]
    });
    this.setState({ measurement_list });
  };
  removeMeasure = (modal: MeasureLabel, index: number): void => {
    const measurement_list: MeasureLabel[] = this.state.measurement_list;
    measurement_list.splice(index, 1);

    const delete_items_measurements: any = this.state.delete_items_measurements;
    delete_items_measurements.push(modal.measurement_id);
    this.setState({ measurement_list, delete_items_measurements });

    this.props.stateToProps({
      name: "delete_items_measurements",
      value: this.state.delete_items_measurements
    });
  };
  collapsearray = (modal: MeasureLabel, index: number): void => {
    const measurement_list: MeasureLabel[] = this.state.measurement_list;
    if (measurement_list[index] !== undefined) {
      if (measurement_list[index].collapseid) {
        measurement_list[index].collapseid = false;
      }
      else
        measurement_list[index].collapseid = true;
      this.setState({ measurement_list });
    }
  };
  addLabel = index => {
    const measurement_list: MeasureLabel[] = this.state.measurement_list;
    console.log("pop()---->>", measurement_list[index].meas_dir_list);
    // for (let index = 0; index < measurement_list[index].meas_dir_list.length; index++) {
    //   const element = measurement_list[index].meas_dir_list[index];
    //   console.log("element--->>", element);
    //   if (element.label && element.measure_custom || element.value != "") {

    //   }
    // }
    measurement_list[index].meas_dir_list.push({
      label: "",
      value: "",
      measure_custom: ""
    });
    this.setState({ measurement_list });
  };
  removeLabel = (mIndex: number, modal: MeasureLabel, index: number): void => {
    const measurement_list: MeasureLabel[] = this.state.measurement_list;

    const delete_items_measure_label: any = this.state
      .delete_items_measure_label;
    delete_items_measure_label.push(modal.meas_dir_list[index].meas_dir_id);
    measurement_list[mIndex].meas_dir_list.splice(index, 1);
    this.setState({ measurement_list, delete_items_measure_label });

    this.props.stateToProps({
      name: "delete_items_measure_label",
      value: this.state.delete_items_measure_label
    });
  };
  handleChange = (e: any, index: number, propName?: string, fieldType?: string) => {
    const measurement_list: MeasureLabel[] = this.state.measurement_list;
    measurement_list.forEach((itm: any, i) => {
      if (i === index) {
        let fieldName = fieldType === 'singleselect' ? propName : e.target.name;
        let fieldValue = fieldType === 'singleselect' ? e.value : e.target.value;
        itm[fieldName] = fieldValue;
        if (fieldName === "measureNoType") {
          itm.plot_item_no = '';
        }
      }
    });
    this.setState({ measurement_list });
    this.props.stateToProps({
      name: "measurement_list",
      value: this.state.measurement_list
    });
  };
  handleLabelChange = (mIndex: number, e: any, index: number, propName?: string, fieldType?: string) => {
    const measurement_list: MeasureLabel[] = this.state.measurement_list;
    measurement_list[mIndex].meas_dir_list.forEach((itm: any, i) => {
      if (i === index) {
        let fieldName = fieldType === 'singleselect' ? propName : e.target.name;
        let fieldValue = fieldType === 'singleselect' ? e.value : e.target.value;
        itm[fieldName] = fieldValue;
      }
    });
    for (let index = 0; index < measurement_list[mIndex].meas_dir_list.length; index++) {
      const element = measurement_list[mIndex].meas_dir_list[index];
      console.log("element", element);
      if (element.label && element.measure_custom || element.value != "") {
        this.setState({ measurement_list });
        this.props.stateToProps({
          name: "measurement_list",
          value: this.state.measurement_list
        });
      } else {
        this.setState({ required: true });
      }
    }

  };
  render() {
    const boundaryTypes = [
      {
        id: '',
        name: 'Deselect'
      },
      {
        id: 'Plot No',
        name: 'Plot No'
      },
      {
        id: 'Item No',
        name: 'Item No'
      },
    ];
    const customStyles = {
      menu: (provided, state) => ({
        ...provided,
        position: 'relative'
      }),
    }
    const extentUnits = [
      { value: "", label: "Deselect" },
      ...this.state.extentUnits
    ];
    const customUnits = [
      { value: "", label: "Deselect" },
      ...this.state.customUnits
    ];
    return this.state.measurement_list && this.state.measurement_list.length
      ? this.state.measurement_list.map((measure: MeasureLabel, mIndex, vIndex) => {
        return (
          <>
            <Accordion defaultActiveKey="0" style={{ width: "100%" }}>
              <Card style={{
                borderRadius: "10px",
                width: "100%",
                margin: "auto"
              }}>
                <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => this.collapsearray(measure, mIndex)}
                  className="card-border-c-green">
                  <div className="row">
                    <div className="col-md-8" style={{ paddingTop: "6px" }}>
                      <h2>
                        {measure.collapseid ? <i className="fa fa-angle-down rotate-icon"></i> : <i className="fa fa-angle-up rotate-icon"></i>}&nbsp;&nbsp;
                        Measure-{mIndex + 1}
                      </h2>
                    </div>
                    <div className="col-md-4">
                      <div style={{
                        float: "right"
                      }}>
                        <button
                          type="button"
                          style={{ marginTop: "1px" }}
                          className="btn btn-icon btn-rounded btn-primary"
                          onClick={this.addMeasure}
                        >
                          <i className="feather icon-plus" />
                        </button>
                        <button
                          type="button"
                          style={{ marginTop: "1px" }}
                          className="btn btn-icon btn-rounded btn-danger"
                          disabled={this.state.measurement_list.length === 1}
                          onClick={() => this.removeMeasure(measure, mIndex)}
                        >
                          <i className="feather icon-minus" />
                        </button>
                      </div>
                    </div>
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Measurement</label>
                          <input
                            className="form-control"
                            name="measurement"
                            placeholder="Eg. 1200"
                            type="text"
                            value={measure.measurement}
                            onChange={e => this.handleChange(e, mIndex)}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Measurement Unit</label>
                          {this.state.extentUnits && this.state.extentUnits.length > 0 && this.state.extentUnits.map((opt: any) => { opt.label = opt.name; opt.value = opt.id }) &&
                            <Select options={extentUnits} name="m_unit"
                              value={this.state.extentUnits.find((o: any) => o.value === measure.m_unit)}
                              onChange={(e) => this.handleChange(e, mIndex, "m_unit", 'singleselect')}
                              styles={customStyles}
                            />
                          }
                        </div>
                      </div>
                      {/* </div> */}
                      {measure.meas_dir_list && measure.meas_dir_list.length > 0 &&
                        <>
                          {/* <div className="row"> */}
                          <div className="form-group col-md-4">
                            <label>Value Unit</label>
                            {this.state.customUnits && this.state.customUnits.length > 0 && this.state.customUnits.map((opt: any) => { opt.label = opt.name; opt.value = opt.id }) &&
                              <Select options={customUnits} name="l_unit" className="form-select"
                                value={this.state.customUnits.find((o: any) => o.value === measure.l_unit)}
                                onChange={(e) => this.handleChange(e, mIndex, "l_unit", 'singleselect')}
                                styles={customStyles}
                              />
                            }
                          </div>

                        </>
                      }
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Select Plot/Item No</label>
                          {boundaryTypes && boundaryTypes.length > 0 && boundaryTypes.map((opt: any) => { opt.label = opt.name; opt.value = opt.id }) &&
                            <Select options={boundaryTypes} name="measureNoType"
                              className={'form-select is-invalid'}
                              // className={this.state.required && measure.unit === '' ? 'form-select is-invalid' : 'form-select'}
                              value={boundaryTypes.find((o: any) => o.value === measure.measureNoType)}
                              onChange={(e) => this.handleChange(e, mIndex, "measureNoType", 'singleselect')}
                            />
                          }
                        </div>
                      </div>
                      <div className="col-md-6">
                        {(measure.measureNoType == "Plot No" || measure.measureNoType == "Item No") ? <>
                          <div className="form-group">
                            {measure.measureNoType == "Plot No" ? <><label>Plot No</label></> :
                              (measure.measureNoType == "Item No" ? <><label>Item No</label></> : "")}
                            <input
                              className={'form-control'}
                              name="plot_item_no"
                              placeholder={`${measure.measureNoType == "Plot No" ? "Eg. 2, 3,156 [West Side Part]" : 'Eg. 1, 2, 3'}`}
                              type="text"
                              value={measure.plot_item_no}
                              onChange={e => this.handleChange(e, mIndex)}
                              required
                            />
                          </div>
                        </> : ""}
                      </div>
                    </div>
                    {measure.meas_dir_list && measure.meas_dir_list.length
                      ? measure.meas_dir_list.map(
                        (dirLabel: MeasureDirList, index) => {
                          return (
                            <div className="row" style={{ width: "106%" }}>
                              <div className="col-md-5">
                                <div className="form-group">
                                  <label>Label</label>
                                  {this.state.measures && this.state.measures.length > 0 && this.state.measures.map((opt: any) => { opt.label = opt.name; opt.value = opt.id }) &&
                                    <Select options={this.state.measures} name="label"
                                      className={this.state.required && dirLabel.label === '' ? 'form-select is-invalid' : 'form-select'}
                                      value={this.state.measures.find((o: any) => o.value === dirLabel.label)}
                                      onChange={(e) => this.handleLabelChange(mIndex, e, index, "label", 'singleselect')}
                                      styles={customStyles}
                                    />
                                  }
                                  <div className="invalid-feedback">Kindly add Label here.</div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label>Value <span className="inputEgMsg">Eg. 15(don't enter units)</span></label>
                                  <input
                                    className={this.state.required && dirLabel.value === '' ? 'form-control is-invalid' : 'form-control'}
                                    name="value"
                                    value={dirLabel.value}
                                    type="text"
                                    onChange={e =>
                                      this.handleLabelChange(mIndex, e, index)
                                    }
                                  />
                                  <div className="invalid-feedback">Kindly add Value here.</div>
                                </div>
                              </div>
                              <div className="col-md-3" style={{ top: "22px" }}>
                                <div style={{ float: "right" }}>
                                  <button
                                    type="button"
                                    className="btn btn-icon btn-rounded btn-primary"
                                    onClick={() => this.addLabel(mIndex)}
                                  >
                                    <i className="feather icon-plus" />
                                  </button>
                                  <button
                                    type="button"
                                    style={{ marginTop: "7px" }}
                                    className="btn btn-icon btn-rounded btn-danger"
                                    disabled={measure.meas_dir_list.length === 1}
                                    onClick={() =>
                                      this.removeLabel(mIndex, measure, index)
                                    }
                                  >
                                    <i className="feather icon-minus" />
                                  </button>
                                </div>
                              </div>
                              {dirLabel.label == "8" && (
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <label>Custom</label>
                                    <input
                                      className="form-control"
                                      placeholder="Eg. Towards eastern side North South from this point / None of the above labels"
                                      name="measure_custom"
                                      type="text"
                                      value={dirLabel.measure_custom}
                                      onChange={e =>
                                        this.handleLabelChange(mIndex, e, index)
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          );
                        }
                      )
                      : ""}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </>
        );
      })
      : "";
  }
}
