import React, { Component } from "react";
import { callApi } from "../../utils/api";
import ReactFlexyTable from "react-flexy-table";
import "../block-download/block_download.css";
import { Link } from "react-router-dom";
import {
  ToastsStore,
  ToastsContainer,
  ToastsContainerPosition,
} from "react-toasts";
import moment from "moment";
import Accordion from "react-bootstrap/Accordion";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

declare var $: any;
interface AllProps {
  history: any;
}
class EmployeeWise extends Component<AllProps> {
  state = {
    Categoryemp: [
      {
        id: "",
        name: "",
        user_grade: "",
        e_grade: "",
        v_grade: "",
        d_grade: "",
        r_grade: "",
      },
    ],
    rectifyemp: [
      {
        id: "",
        name: "",
        rectify_user_grade: "",
      },
    ],
    outreason: "",
    selectVal: false,
    isLoading: false,
  };

  componentDidMount() {
    this.getUser();
    this.getOutTime();
    this.ouTime();
  }

  getUser = () => {
    callApi("GET", "user_get.php")
      .then((res) => res.data)
      .then((result) => {
        if (result && result.data) {
          const rectifyUser = result?.data.filter(
            (user: any) => user.r_grade == "R"
          );
          this.setState({ Categoryemp: result.data, rectifyemp: rectifyUser });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleChangetable(event, emp) {
    const { name, checked } = event.target;
    let updatedbank;
    if (name === "E") {
      updatedbank = this.state.Categoryemp.map((item) =>
        item.id === emp.id ? { ...item, e_grade: checked ? name : "" } : item
      );
    } else if (name === "V") {
      updatedbank = this.state.Categoryemp.map((item) =>
        item.id === emp.id ? { ...item, v_grade: checked ? name : "" } : item
      );
    } else if (name === "D") {
      updatedbank = this.state.Categoryemp.map((item) =>
        item.id === emp.id ? { ...item, d_grade: checked ? name : "" } : item
      );
    } else if (name === "R") {
      updatedbank = this.state.Categoryemp.map((item) =>
        item.id === emp.id ? { ...item, r_grade: checked ? name : "" } : item
      );
    } else {
      updatedbank = this.state.Categoryemp.map((item) =>
        item.id === emp.id ? { ...item, user_grade: checked ? name : "" } : item
      );
    }
    this.setState({ Categoryemp: updatedbank }, () => {
      console.log(this.state.Categoryemp);
    });
  }

  handleChangeRectifytable(event, emp) {
    const { name, checked } = event.target;

    // Prevent unchecking an already checked input
    if (emp.rectify_user_grade === name && !checked) {
      return;
    }

    let updatedbank;

    updatedbank = this.state.rectifyemp.map((item) =>
      item.id === emp.id
        ? { ...item, rectify_user_grade: checked ? name : "" }
        : item
    );

    this.setState({ rectifyemp: updatedbank }, () => {
      console.log(this.state.rectifyemp);
    });
  }

  handleSubmit = (event, type) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    const checkedemp =
      type == "allUser" ? this.state.Categoryemp : this.state.rectifyemp;
    const api =
      type == "allUser"
        ? "employee_grade_update"
        : "rectify_employee_grade_update";
    callApi("POST", `${api}.php`, {
      employee_grade: checkedemp,
    })
      .then((res) => res.data)
      .then((response) => {
        if (response.success === true) {
          this.setState({ isLoading: false });
          ToastsStore.success(response.message);
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isLoading: false });
      });
  };

  previousPop = () => {
    $("#out_reason").modal("show");
    $("#another-reason").modal("hide");
  };
  BacktoFile = () => {
    $("#out_reason").modal("hide");
    const fileid1: any = sessionStorage.getItem("currentfileId");

    this.props.history.push("/file-dashboard/" + JSON.parse(fileid1));
  };
  getOutTime = () => {
    let date = new Date();
    let stratTime = date.getTime();
    localStorage.setItem("startTime", JSON.stringify(stratTime));
    let outDate = date.toLocaleDateString();
    localStorage.setItem("OutDateGet", JSON.stringify(outDate));
    var Railway = date.getMinutes();
    if (Railway < 10) {
      var TotalMinutes = "0" + Railway;
      let ouTtime = date.getHours() + ":" + TotalMinutes;
      localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
    } else {
      let ouTtime: any = date.getHours() + ":" + date.getMinutes();
      localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
    }
  };
  ouTime = () => {
    const getfileId: any = sessionStorage.getItem("currentfileId");
    console.log("54", JSON.parse(getfileId));
    console.log("32", getfileId);
    if (sessionStorage.getItem("currentfileId")) {
      $("#out_reason").modal("show");
    }
  };
  outimeGet = () => {
    this.setState({ isLoading: true });
    const fileid1: any = sessionStorage.getItem("currentfileId");
    const userid1: any = sessionStorage.getItem("user1");
    const inreason: any = localStorage.getItem("in_reason");
    const intime: any = localStorage.getItem("IntimeGet");
    console.log("70", intime);
    const outtime: any = localStorage.getItem("outtimeGet");
    console.log("73", outtime);
    const outdate: any = localStorage.getItem("OutDateGet");
    const secondsSpent = moment(outtime, "HH:mm").diff(
      moment(intime, "HH:mm"),
      "seconds"
    );
    console.log("Second Spn", secondsSpent);
    if (this.state.outreason == "") {
      this.setState({ selectVal: true });
    } else {
      let obj = {
        file_id: JSON.parse(fileid1),
        user_id: JSON.parse(userid1),
        in_reason: JSON.parse(inreason),
        out_reason: this.state.outreason,
        intime: JSON.parse(intime),
        outtime: JSON.parse(outtime),
        insert_date: JSON.parse(outdate),
        total_seconds: secondsSpent,
      };

      // console.log("72",obj);
      callApi("POST", "process_eod_insert.php", obj)
        .then((res) => res.data)
        .then((response) => {
          console.log("76", response);
          this.setState({ isLoading: false });
          // sessionStorage.removeItem("currentfileId");
          localStorage.removeItem("in_reason");
          localStorage.removeItem("outreason");
          localStorage.removeItem("IntimeGet");
          localStorage.removeItem("OutDateGet");
          $("#out_reason").modal("hide");
          $("#another-reason").modal("hide");
          ToastsStore.success("Your Reason Saved Successfully");
          let process_officerId: any =
            sessionStorage.getItem("process_office_id");
          let process_officer_id = JSON.parse(process_officerId);
          const userid1: any = sessionStorage.getItem("user1");
          let user_id = JSON.parse(userid1);
          const take_file: any = sessionStorage.getItem("takenfile_id");
          const user: any = sessionStorage.getItem("user");
          const getfileId: any = sessionStorage.getItem("currentfileId");
          if (
            process_officer_id == user_id &&
            take_file != "" &&
            take_file == getfileId
          ) {
            this.fileout(fileid1);
          } else {
            sessionStorage.removeItem("currentfileId");
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({ isLoading: false });
          ToastsStore.error("Your Reason Not Stored");
        });
    }
  };
  fileout = (fileid1) => {
    let fileId = JSON.parse(fileid1);
    const loginuser: any = sessionStorage.getItem("user1");
    let req = {
      id: fileId,
      reason: "File Out",
      created_by: JSON.parse(loginuser),
    };
    callApi("POST", "time_update.php", req)
      .then((res) => res.data)
      .then((response) => {
        sessionStorage.removeItem("currentfileId");
        // sessionStorage.removeItem("takenfile_id");
      });
  };
  onchangeReason = (e: any) => {
    if (e.target.value == "Other") {
      this.setState({ in_reason: "", selectVal: false });
      $("#out_reason").modal("hide");
      $("#another-reason").modal("show");
    } else if (e.target.value == "select") {
      this.setState({ setResponse: false });
    } else {
      // this.setState({})
      this.setState({ [e.target.name]: e.target.value, selectVal: false });
    }
  };

  onchange = (e: any) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/\s+/g, " ").trim(),
      selectVal: false,
    });
  };
  render() {
    const { Categoryemp, rectifyemp } = this.state;
    return (
      <section className="pcoded-main-container">
        <ToastsContainer
          store={ToastsStore}
          position={ToastsContainerPosition.TOP_RIGHT}
        />
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="card-header">
                          <h5 className="file-status-header">
                            Employee Grade Management
                          </h5>
                        </div>
                        <div className="card-body">
                          <Accordion className="mainAccordion">
                            <Tabs
                              defaultActiveKey="overall"
                              style={{ padding: 0 }}
                              onSelect={(key) => this.getUser()}
                            >
                              <Tab
                                eventKey="overall"
                                title="Overall User Grade Management"
                              >
                                <div className="row">
                                  <div className="col-lg-12 table-responsive">
                                    <form
                                      onSubmit={(e) =>
                                        this.handleSubmit(e, "allUser")
                                      }
                                    >
                                      <div
                                        className="file-search-button formbtn"
                                        style={{ marginTop: "0%" }}
                                      >
                                        <button
                                          type="submit"
                                          className="btn btn-primary"
                                          disabled={this.state.isLoading}
                                        >
                                          {this.state.isLoading
                                            ? "Saving..."
                                            : "Save"}
                                        </button>

                                        <Link
                                          to={"/dashboard"}
                                          className="btn btn-icon btn-primary"
                                          style={{ width: "6%" }}
                                        >
                                          Back
                                        </Link>
                                      </div>
                                      <div
                                        className="sticky-table-container"
                                        style={{ height: "70vh" }}
                                      >
                                        <table className="table">
                                          <thead className="sticky-table-header">
                                            <tr>
                                              <th scope="col">Sl.No</th>
                                              <th scope="col">Name</th>
                                              <th scope="col head-left">
                                                A+ Grade
                                              </th>
                                              <th scope="col head-left">
                                                A1 Grade
                                              </th>
                                              <th scope="col head-left">
                                                A Grade
                                              </th>
                                              <th scope="col head-left">
                                                B Grade
                                              </th>
                                              <th scope="col head-left">
                                                C Grade
                                              </th>
                                              <th scope="col head-left">
                                                E Grade
                                              </th>
                                              <th scope="col head-left">
                                                V Grade
                                              </th>
                                              <th scope="col head-left">
                                                Deviation
                                              </th>
                                              <th scope="col head-left">
                                                Rectification
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {Categoryemp.map((bank, index) => (
                                              <tr
                                                className="pointer"
                                                key={bank.id}
                                              >
                                                <td className="indexInput text-center">
                                                  {index + 1}
                                                </td>
                                                <td className="indexInput text-center">
                                                  {bank.name}
                                                </td>
                                                <td className="table-body-align">
                                                  <input
                                                    type="checkbox"
                                                    className="form-control"
                                                    name="A+"
                                                    checked={
                                                      bank.user_grade === "A+"
                                                    }
                                                    onChange={(event) =>
                                                      this.handleChangetable(
                                                        event,
                                                        bank
                                                      )
                                                    }
                                                  />
                                                </td>
                                                <td className="table-body-align">
                                                  <input
                                                    type="checkbox"
                                                    className="form-control"
                                                    name="A1"
                                                    checked={
                                                      bank.user_grade === "A1"
                                                    }
                                                    onChange={(event) =>
                                                      this.handleChangetable(
                                                        event,
                                                        bank
                                                      )
                                                    }
                                                  />
                                                </td>
                                                <td className="table-body-align">
                                                  <input
                                                    type="checkbox"
                                                    className="form-control"
                                                    name="A"
                                                    checked={
                                                      bank.user_grade === "A"
                                                    }
                                                    onChange={(event) =>
                                                      this.handleChangetable(
                                                        event,
                                                        bank
                                                      )
                                                    }
                                                  />
                                                </td>
                                                <td className="table-body-align">
                                                  <input
                                                    type="checkbox"
                                                    className="form-control"
                                                    name="B"
                                                    checked={
                                                      bank.user_grade === "B"
                                                    }
                                                    onChange={(event) =>
                                                      this.handleChangetable(
                                                        event,
                                                        bank
                                                      )
                                                    }
                                                  />
                                                </td>
                                                <td className="table-body-align">
                                                  <input
                                                    type="checkbox"
                                                    className="form-control"
                                                    name="C"
                                                    checked={
                                                      bank.user_grade === "C"
                                                    }
                                                    onChange={(event) =>
                                                      this.handleChangetable(
                                                        event,
                                                        bank
                                                      )
                                                    }
                                                  />
                                                </td>
                                                <td className="table-body-align">
                                                  <input
                                                    type="checkbox"
                                                    className="form-control"
                                                    name="E"
                                                    checked={
                                                      bank.e_grade === "E"
                                                    }
                                                    onChange={(event) =>
                                                      this.handleChangetable(
                                                        event,
                                                        bank
                                                      )
                                                    }
                                                  />
                                                </td>
                                                <td className="table-body-align">
                                                  <input
                                                    type="checkbox"
                                                    className="form-control"
                                                    name="V"
                                                    checked={
                                                      bank.v_grade === "V"
                                                    }
                                                    onChange={(event) =>
                                                      this.handleChangetable(
                                                        event,
                                                        bank
                                                      )
                                                    }
                                                  />
                                                </td>
                                                <td className="table-body-align">
                                                  <input
                                                    type="checkbox"
                                                    className="form-control"
                                                    name="D"
                                                    checked={
                                                      bank.d_grade === "D"
                                                    }
                                                    onChange={(event) =>
                                                      this.handleChangetable(
                                                        event,
                                                        bank
                                                      )
                                                    }
                                                  />
                                                </td>
                                                <td className="table-body-align">
                                                  <input
                                                    type="checkbox"
                                                    className="form-control"
                                                    name="R"
                                                    checked={
                                                      bank.r_grade === "R"
                                                    }
                                                    onChange={(event) =>
                                                      this.handleChangetable(
                                                        event,
                                                        bank
                                                      )
                                                    }
                                                  />
                                                </td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </Tab>
                              <Tab
                                eventKey="rectifyUser"
                                title="Rectification User Grade Management"
                              >
                                <div className="row">
                                  <div className="col-lg-12 table-responsive">
                                    <form
                                      onSubmit={(e) =>
                                        this.handleSubmit(e, "rectifyUser")
                                      }
                                    >
                                      <div
                                        className="file-search-button formbtn"
                                        style={{ marginTop: "0%" }}
                                      >
                                        <button
                                          type="submit"
                                          className="btn btn-primary"
                                          disabled={this.state.isLoading}
                                        >
                                          {this.state.isLoading
                                            ? "Saving..."
                                            : "Save"}
                                        </button>

                                        <Link
                                          to={"/dashboard"}
                                          className="btn btn-icon btn-primary"
                                          style={{ width: "6%" }}
                                        >
                                          Back
                                        </Link>
                                      </div>
                                      <div
                                        className="sticky-table-container"
                                        style={{ height: "70vh" }}
                                      >
                                        <table className="table">
                                          <thead className="sticky-table-header">
                                            <tr>
                                              <th scope="col">Sl.No</th>
                                              <th scope="col">Name</th>
                                              <th scope="col head-left">
                                                Title Deed Work (A Grade)
                                              </th>
                                              <th scope="col head-left">
                                                B Grade
                                              </th>
                                              <th scope="col head-left">
                                                Corrections (C Grade)
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {rectifyemp.map((bank, index) => (
                                              <tr
                                                className="pointer"
                                                key={bank.id}
                                              >
                                                <td className="indexInput text-center">
                                                  {index + 1}
                                                </td>
                                                <td className="indexInput text-center">
                                                  {bank.name}
                                                </td>
                                                <td className="table-body-align">
                                                  <input
                                                    type="checkbox"
                                                    className="form-control"
                                                    name="A"
                                                    checked={
                                                      bank.rectify_user_grade ===
                                                      "A"
                                                    }
                                                    onChange={(event) =>
                                                      this.handleChangeRectifytable(
                                                        event,
                                                        bank
                                                      )
                                                    }
                                                  />
                                                </td>
                                                <td className="table-body-align">
                                                  <input
                                                    type="checkbox"
                                                    className="form-control"
                                                    name="B"
                                                    checked={
                                                      bank.rectify_user_grade ===
                                                      "B"
                                                    }
                                                    onChange={(event) =>
                                                      this.handleChangeRectifytable(
                                                        event,
                                                        bank
                                                      )
                                                    }
                                                  />
                                                </td>
                                                <td className="table-body-align">
                                                  <input
                                                    type="checkbox"
                                                    className="form-control"
                                                    name="C"
                                                    checked={
                                                      bank.rectify_user_grade ===
                                                      "C"
                                                    }
                                                    onChange={(event) =>
                                                      this.handleChangeRectifytable(
                                                        event,
                                                        bank
                                                      )
                                                    }
                                                  />
                                                </td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </Tab>
                            </Tabs>
                          </Accordion>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* THIS FOR OUT REASON MODAL */}
        <div
          className="modal fade hide in"
          id="out_reason"
          data-backdrop="static"
          data-keyboard="false"
        >
          <div className="modal-dialog ">
            <div
              className="modal-content"
              style={{ borderRadius: "10px", boxShadow: "0 0 15px #000" }}
            >
              <div
                className="modal-body"
                style={{ padding: "30px 23px 29px 24px" }}
              >
                <h3 style={{ margin: "auto", fontSize: "17px" }}>
                  Please Select The Reason For To Close The File.No:
                  {sessionStorage.getItem("currentfileId")}
                </h3>
              </div>
              <div
                className="form-group"
                style={{ width: "74%", margin: "auto" }}
              >
                <select
                  className="custom-select"
                  name="outreason"
                  value={this.state.outreason}
                  onChange={(e) => this.onchangeReason(e)}
                  required
                  id="select1"
                  style={{ marginTop: "8px" }}
                >
                  <option value="select">Select Your Reason</option>
                  <option value="Query Raised">Query Raised</option>
                  <option value=" Re-Query Raised"> Re-Query Raised</option>
                  <option value="Hold ">Hold </option>
                  <option value="Completed">Completed</option>
                  <option value="Other">Others</option>
                </select>
                {this.state.selectVal ? (
                  <p
                    style={{
                      color: "red",
                      textAlign: "center",
                      fontSize: "16px",
                    }}
                  >
                    Please Select Your Reason{" "}
                  </p>
                ) : null}
              </div>
              <div className="modal-footer" style={{ marginTop: "27px" }}>
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={this.state.isLoading}
                  onClick={this.outimeGet}
                  style={{ width: "76px", fontSize: "17px" }}
                >
                  {" "}
                  {this.state.isLoading ? (
                    <i className="fa fa-spinner fa-spin"></i>
                  ) : (
                    "OK"
                  )}
                </button>
                <button
                  type="button"
                  className="btn btn-outline"
                  onClick={this.BacktoFile}
                  style={{
                    width: "107px",
                    fontSize: "14px",
                    border: "1px solid black",
                    height: "43px",
                  }}
                >
                  {" "}
                  Back To File
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* OTHER REASON MODAL */}
        <div
          className="modal hide fade in"
          id="another-reason"
          data-backdrop="static"
          data-keyboard="false"
        >
          <div className="modal-dialog ">
            <div
              className="modal-content"
              style={{ borderRadius: "10px", boxShadow: "0 0 15px #000" }}
            >
              <h5 style={{ marginTop: "31px", textAlign: "center" }}>
                Please Type a Reason To Close The File.No:{" "}
                {sessionStorage.getItem("currentfileId")}{" "}
              </h5>
              <div
                className="modal-body"
                style={{ padding: "30px 23px 29px 24px" }}
              >
                {/* <div className="col-md-5"> */}
                <div
                  className="form-group"
                  style={{ width: "98%", margin: "auto" }}
                >
                  <textarea
                    className="form-control"
                    placeholder="Type Your Reason"
                    name="outreason"
                    onChange={this.onchange}
                  />
                  {this.state.selectVal ? (
                    <p
                      style={{
                        color: "red",
                        textAlign: "center",
                        fontSize: "16px",
                      }}
                    >
                      Please Select Your Reason{" "}
                    </p>
                  ) : null}
                </div>
                {/* </div> */}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.outimeGet()}
                  style={{ width: "76px", fontSize: "17px" }}
                  disabled={this.state.isLoading}
                >
                  {" "}
                  {this.state.isLoading ? (
                    <i className="fa fa-spinner fa-spin"></i>
                  ) : (
                    "OK"
                  )}
                </button>
                <button
                  type="button"
                  className="btn btn-outline"
                  onClick={() => this.previousPop()}
                  // onClick={this.disableModalHandler}
                  style={{
                    width: "76px",
                    fontSize: "17px",
                    border: "1px solid black",
                  }}
                >
                  {" "}
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default EmployeeWise;
