import React from "react";
import * as joint from "jointjs";
import ReactDOM from "react-dom";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { nextColumnName } from "../../common/commonFunctions";
import $ from "jquery";
import "./flow_audio.css"

import "bootstrap/dist/css/bootstrap.css";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import GraphModal from "../../common/GraphModal/GraphModal";
import "../sale-deed/flowmap.css";
// import "./flowmap.css";
import CustomAudioRecorder from "../CustomAudioRecorder";
import { callApi } from "../../utils/api";
class Flowchartaudio extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            imageStr: null,
            currentAutoColumnNumber: 1,
            currentManualColumnNumber: 1,
            autoQuestionsList: [],
            manualQuestionsList: [],
            isManualViewShown: false,
            isColorCountViewShown: false,
            activeTab: "ManualView",
            colorCountData: [
                { name: "violet", totalCount: "", numbers: {} },
                { name: "orange", totalCount: "", numbers: {} },
                { name: "green", totalCount: "", numbers: {} },
                { name: "yellow", totalCount: "", numbers: {} },
                { name: "red", totalCount: "", numbers: {} },
            ],
            selectedCurrentView: null,
            isGraphModalShown: false,
            isManualDataAvailable: true,
            isManualClicked: false,
            isQuestionsExpanded: false,
            isFlowChartExpanded: true,
            activeMainTab: "",
            flowGraphData: null,
            audioList: []
        };
        // fileid1: any;
        this.manualGraph = new joint.dia.Graph();
        this.autoGraph = new joint.dia.Graph();
    }

    componentDidMount() {
        this.getFlowGraphData();
        this.getAudioList();
        // this.setInitialManualPaperView();
        // this.setQuestions();
        // this.setAutomaticPaperView();
    }
    getAudioList = () => {
        callApi("POST", "audio_get.php", {
            file_id: this.props.match.params.id,
        })
            .then((res) => res.data)
            .then((response) => {
                this.setState({ audioList: response.audioList })
            })
            .catch((err) => {
                console.log(err);
            });
    };
    getFlowGraphData = () => {
        callApi("POST", "get_flowchart.php", {
            fileId: this.props.match.params.id,
        })
            .then((res) => res.data)
            .then((response) => {
                if (response.data && response.data) {
                    this.setState({ flowGraphData: response.data });
                    console.log("this.state.flowGraphData", this.state.flowGraphData);
                    // this.setAutomaticPaperView();
                    this.setInitialManualPaperView();
                }
            })
            .catch((err) => {
                console.log(err);
            });

    }

    setQuestions = () => {
        let { autoQuestionsList, manualQuestionsList } = this.state;
        let autoQuestionList =
            this.state.flowGraphData && this.state.flowGraphData.autoQuestion;
        let manualQuestionList =
            this.state.flowGraphData && this.state.flowGraphData.manualQuestion;
        if (autoQuestionList) {
            autoQuestionsList = JSON.parse(autoQuestionList);
        }
        if (manualQuestionList) {
            manualQuestionsList = JSON.parse(manualQuestionList);
        }
        this.setState({
            autoQuestionsList,
            manualQuestionsList,
        });
    };

    setAutomaticPaperView = () => {
        var namespace = joint.shapes;
        this.autoPaper = new joint.dia.Paper({
            el: ReactDOM.findDOMNode(this.refs.autoPaperRef),
            overflowY: 'auto', 
            width: 500,
            height: 1000,
            // height:
            //     $(ReactDOM.findDOMNode(this.props.tableRef)).height() +
            //     (this.props.deedData.length / 2) * 20,
            model: this.autoGraph,
            linkPinning: false,
            restrictTranslate: true,
            //   interactive: { linkMove: true },
            gridSize: 10,
            drawGrid: true,
            background: {
                color: "white",
            },
            cellViewNamespace: namespace,
            allowLink: true,
            multiLinks: false,
            validateConnection: (
                cellViewS,
                magnetS,
                cellViewT,
                magnetT,
                end,
                linkView
            ) => {
                if (cellViewS === cellViewT) {
                    return false;
                } else if (
                    linkView.model.attributes.source.port === "out" &&
                    linkView.model.attributes.target.port === "in"
                ) {
                    return false;
                } else if (
                    linkView.model.attributes.source.port === "in" ||
                    linkView.model.attributes.target.port === "out"
                ) {
                    return false;
                } else {
                    return true;
                }
            },
            defaultLink: (cellView, magnet) => {
                var link = new joint.shapes.standard.Link();
                // var tools = new joint.dia.ToolsView({
                //   tools: [new joint.linkTools.Remove()],
                // });
                // console.log(magnet.findView(this.autoPaper));
                return link;
            },
            snapLinks: { radius: 75 },
        });

        var linkTools = new joint.dia.ToolsView({
            tools: [new joint.linkTools.Remove()],
        });

        var elementTools = new joint.dia.ToolsView({
            tools: [
                new joint.elementTools.Remove({
                    focusOpacity: 0.5,
                    rotate: true,
                    x: "50%",
                    y: "0%",
                    offset: { x: 20, y: 10 },
                    action: (evt, linkView, toolView) => {
                        linkView.model.remove({ ui: true, tool: toolView.cid });
                        let currentValue = linkView.el;
                        if (currentValue) currentValue = currentValue.textContent;
                        else currentValue = null;
                        this.removeQuestions(currentValue);
                    },
                }),
            ],
        });

        this.autoPaper.on("link:mouseenter", function (linkView) {
            linkView.addTools(linkTools);
        });

        this.autoPaper.on("link:mouseleave", function (linkView) {
            linkView.removeTools();
        });

        this.autoPaper.on("element:mouseenter", function (elementView) {
            if (
                elementView.model &&
                elementView.model.attributes &&
                elementView.model.attributes.attrs &&
                elementView.model.attributes.attrs[".label"] &&
                elementView.model.attributes.attrs[".label"].text
            ) {
                if (
                    elementView.model.attributes.attrs[".label"].text
                        .toString()
                        .includes("?")
                ) {
                    // elementView.model.attributes.attrs["isNewCircle"] || elementView.model.attributes.attrs["isNewQuestion"]
                    elementView.addTools(elementTools);
                }
            }
        });

        this.autoPaper.on("element:mouseleave", function (elementView) {
            elementView.removeTools();
        });

        this.autoPaper.on("element:pointerdblclick", (cellView, evt, x, y) => {
            this.setSelectedView(cellView);
        });

        if (
            this.state.flowGraphData &&
            this.state.flowGraphData.autoGraph &&
            this.state.flowGraphData.autoGraph !== "null"
        ) {
            let testData = this.state.flowGraphData.autoGraph;
            if (testData) {
                testData = testData.slice(1);
                testData = testData.slice(0, testData.length - 1);
                this.getDeedData("auto", JSON.parse(testData).cells);
            }
        } else this.getDeedData("auto");
    };

    setInitialManualPaperView = () => {
        console.log("this.state.flowGraphData", this.props.flowGraphData);
        if (this.state.flowGraphData && this.state.flowGraphData.manualGraph && this.state.flowGraphData.manualGraph !== "null") {
            this.setState(
                {
                    isManualDataAvailable: true,
                    isManualClicked: true,
                },
                () => {
                    this.setManualPaperView(true);
                }
            );
        }
        // else this.setManualPaperView(false);
    };

    hideGraphModal = () => {
        const element = document.createElement("a");
        element.href = "#graphModal";
        element.setAttribute("data-toggle", "modal");
        this.setState({
            isGraphModalShown: false,
        });
        element.click();
        if (!!document.getElementsByClassName("modal-backdrop").length) {
            document.getElementsByClassName("modal-backdrop")[0].remove();
        }
    };

    setSelectedView = (cellView) => {
        this.setState(
            {
                selectedCurrentView: cellView,
                isGraphModalShown: true,
            },
            () => {
                const GraphModalElement = document.getElementById("graphModal");
                GraphModalElement.classList.toggle("block");
                GraphModalElement.style.display = "show";
            }
        );
    };

    setManualPaperView = (isManualData) => {
        var namespace = joint.shapes;
        this.manualPaper = new joint.dia.Paper({
            el: ReactDOM.findDOMNode(this.refs.manualPaperRef),
            overflowY: 'auto', 
            width: 500, 
            height: 1000,
            // height: $(ReactDOM.findDOMNode(this.props.tableRef)).height() +
            // (this.props.deedData.length / 2) * 20,
            model: this.manualGraph,
            linkPinning: false,
            restrictTranslate: true,
            //   interactive: { linkMove: true },
            gridSize: 10,
            drawGrid: true,
            background: {
                color: "white",
            },
            cellViewNamespace: namespace,
            allowLink: true,
            multiLinks: false,
            validateConnection: (
                cellViewS,
                magnetS,
                cellViewT,
                magnetT,
                end,
                linkView
            ) => {
                if (cellViewS === cellViewT) {
                    return false;
                } else if (
                    linkView.model.attributes.source.port === "out" &&
                    linkView.model.attributes.target.port === "in"
                ) {
                    return false;
                } else if (
                    linkView.model.attributes.source.port === "in" ||
                    linkView.model.attributes.target.port === "out"
                ) {
                    return false;
                } else {
                    return true;
                }
            },
            defaultLink: (cellView, magnet) => {
                var link = new joint.shapes.standard.Link();
                // var tools = new joint.dia.ToolsView({
                //   tools: [new joint.linkTools.Remove()],
                // });
                // console.log(magnet.findView(this.manualPaper));
                return link;
            },
            snapLinks: { radius: 75 },
        });

        var linkTools = new joint.dia.ToolsView({
            tools: [new joint.linkTools.Remove()],
        });

        var elementTools = new joint.dia.ToolsView({
            tools: [
                new joint.elementTools.Remove({
                    focusOpacity: 0.5,
                    rotate: true,
                    x: "50%",
                    y: "0%",
                    offset: { x: 20, y: 10 },
                    action: (evt, linkView, toolView) => {
                        linkView.model.remove({ ui: true, tool: toolView.cid });
                        let currentValue = linkView.el;
                        if (currentValue) currentValue = currentValue.textContent;
                        else currentValue = null;
                        this.removeQuestions(currentValue);
                    },
                }),
            ],
        });

        this.manualPaper.on("element:pointerdblclick", (cellView, evt, x, y) => {
            this.setState({
                selectedCurrentView: cellView,
                isGraphModalShown: true,
            });
        });

        this.manualPaper.on("link:mouseenter", function (linkView) {
            linkView.addTools(linkTools);
        });

        this.manualPaper.on("link:mouseleave", function (linkView) {
            linkView.removeTools();
        });

        this.manualPaper.on("element:mouseenter", function (elementView) {
            elementView.addTools(elementTools);
        });

        this.manualPaper.on("element:mouseleave", function (elementView) {
            elementView.removeTools();
        });

        this.manualPaper.on("element:pointerdblclick", (cellView, evt, x, y) => {
            this.setSelectedView(cellView);
        });
        if (isManualData) {
            let testData = this.state.flowGraphData.manualGraph;
            if (testData) {
                testData = testData.slice(1);
                testData = testData.slice(0, testData.length - 1);
                this.getDeedData("manual", JSON.parse(testData).cells);
            }
        } else this.getDeedData("manual");
    };

    updateColorCount = (stateName, index, value, type, numberIndex) => {
        let { colorCountData } = this.state;
        if (type === "count") colorCountData[index].totalCount = Number(value);
        else if (type === "numberInput")
            colorCountData[index].numbers[numberIndex] = value;
        this.setState({
            colorCountData,
        });
    };

    generateManualFlowGraph = () => {
        const { colorCountData } = this.state;
        let x = 1,
            y = 10;
        let nodes = [],
            positionResult,
            numberList;
        for (let parent = 0; parent < colorCountData.length; parent++) {
            numberList = Object.entries(colorCountData[parent].numbers);
            for (let child1 = 0; child1 < numberList.length; child1++) {
                positionResult = this.getXYPosition(x, y);
                x = positionResult.x;
                y = positionResult.y;
                nodes.push(
                    this.addNode(
                        numberList[child1][1],
                        { x, y },
                        [""],
                        [""],
                        colorCountData[parent].name
                    )
                );
            }
        }
        this.manualGraph.addCells([...nodes]);
        this.setState({}, () => {
            //   this.setManualPaperView();
        });
    };

    getXYPosition = (x, y) => {
        if (x % 351 === 0) {
            x = 1;
            y = y + 100;
        }
        return { x: x + 70, y };
    };

    getNodeColor = (type) => {
        const NodeColorMapping = {
            mother_doc: "orange",
            title_deed: "green",
            origin_doc: "violet",
            // rev_rec_doc: '',
            // deed:''
        };

        console.log("Type", type, NodeColorMapping[type]);

        return NodeColorMapping[type] || NodeColorMapping.mother_doc;
    };

    getDeedData = (type, cells = []) => {
        const DEED_DATA = this.props.deedData;
        const currentGraph = type === "auto" ? this.autoGraph : this.manualGraph;
        let x = 1,
            y = 10;
        let positionResult,
            nodes = [];
        if (cells.length > 0) {
            for (let i = 0; i < cells.length; i++) {
                const currentElement =
                    cells[i].type === "devs.Model"
                        ? this.addNode(null, null, null, null, null, cells[i])
                        : cells[i].type === "standard.Link"
                            ? this.addLink(cells[i])
                            : null;
                currentElement && currentGraph.addCell(currentElement);
            }
        } else {
            if (DEED_DATA && DEED_DATA.length > 0) {
                for (let i = 0; i < DEED_DATA.length; i++) {
                    console.log("Deed Data", i, DEED_DATA[i]);
                    positionResult = this.getXYPosition(x, y);
                    x = positionResult.x;
                    y = positionResult.y;
                    // "#012060"
                    if (DEED_DATA[i].position) {
                        const nodeColor = this.getNodeColor(
                            Number(DEED_DATA[i].position) === 1
                                ? "origin_doc"
                                : DEED_DATA[i].node_type
                        );
                        nodes.push(this.addNode(i + 1, { x, y }, [""], [""], nodeColor));
                    }
                }
                currentGraph.addCells([...nodes]);
            }
        }
    };

    addNode(
        title,
        position,
        inPorts,
        outPorts,
        color,
        cellData,
        isNewCircle,
        isNewQuestion
    ) {
        const cellValue = cellData
            ? cellData
            : {
                markup:
                    '<g class="rotatable"><g class="scalable"><rect class="body" /></g><text class="label"/></g>',

                position: position,
                size: { width: 50, height: 50 },
                inPorts: [""],
                outPorts: [" "],
                ports: {
                    groups: {
                        in: {
                            position: "top",
                            attrs: {
                                ".port-body": {
                                    opacity: 0,
                                    // "ref-x": 45,
                                    // "ref-y": -45,
                                },
                            },
                        },
                        out: {
                            position: "bottom",
                            attrs: {
                                ".port-body": {
                                    opacity: 0,
                                    // "ref-x": -45,
                                    // "ref-y": 45,
                                },
                            },
                        },
                    },
                },
                attrs: {
                    ".label": {
                        text: title,
                        x: 0,
                        y: 20,
                        cursor: "move",
                        fill: "white",
                        fontWeight: "bold",
                    },
                    rect: {
                        fill: color,
                        rx: 50,
                        ry: 50,
                        cursor: "move",
                        "user-select": "none",
                    },
                    isNewCircle,
                    isNewQuestion,
                },
                action: () => {
                    console.log("Action");
                },
            };
        var cell = new joint.shapes.devs.Model(cellValue);
        return cell;
    }

    addLink(cell) {
        var link = new joint.shapes.standard.Link(cell);
        // link.appendLabel({
        //   attrs: {
        //     text: {
        //       text: "Hello, World!",
        //     },
        //   },
        // });
        // link.source(source);
        // link.target(target);
        return link;
    }

    removeQuestions = (value) => {
        if (value) {
            // Delete Action1
            this.props.onChanges(true);

            value = value.toString().includes("?")
                ? value.toString().replace(/Â /g, " ").split("?")[0]
                : value;
            let {
                currentAutoColumnNumber,
                currentManualColumnNumber,
                autoQuestionsList,
                manualQuestionsList,
                activeTab,
            } = this.state;
            let currentQuestionsList =
                activeTab === "AutomaticView"
                    ? [...autoQuestionsList]
                    : [...manualQuestionsList];
            const questionIndex = currentQuestionsList.findIndex((question) => {
                return question.name.replace(/ /g, "") === value.replace(/ /g, "");
            });
            if (questionIndex >= 0) currentQuestionsList.splice(questionIndex, 1);

            this.setState({
                [activeTab === "AutomaticView"
                    ? "autoQuestionsList"
                    : "manualQuestionsList"]: currentQuestionsList,
            });
        }
    };

    updateGraphNodes = (cellView, name, color) => {
        // Need to handle save Logics Action1
        this.props.onChanges(true);
        const { selectedCurrentView } = this.state;
        let { autoQuestionsList, manualQuestionsList, activeTab } = this.state;
        let currentQuestionsList =
            activeTab === "AutomaticView" ? autoQuestionsList : manualQuestionsList;
        const QuestionName = cellView.model.attr(".label/text");
        const isQuestionMarktype = QuestionName
            ? QuestionName.toString().includes("?")
            : false;
        const currentIndex = currentQuestionsList.findIndex((question) => {
            if (isQuestionMarktype) return question.name + "?" === QuestionName;
            else return question.name === QuestionName;
        });
        currentIndex >= 0 && this.handleQuestion(name, currentIndex, "name");
        selectedCurrentView.model.attr(
            ".label/text",
            isQuestionMarktype ? name + "?" : name
        );
        selectedCurrentView.model.attr("rect/fill", color);
        this.hideGraphModal();
    };

    createEmptyNode = (updateObj, addType) => {
        let {
            currentAutoColumnNumber,
            currentManualColumnNumber,
            autoQuestionsList,
            manualQuestionsList,
            activeTab,
        } = this.state;
        const currentName = updateObj ? updateObj.name : null;
        const currentColor = updateObj ? updateObj.color : null;
        let currentColumnNumber =
            activeTab === "AutomaticView"
                ? currentAutoColumnNumber
                : currentManualColumnNumber;
        let currentQuestionsList =
            activeTab === "AutomaticView" ? autoQuestionsList : manualQuestionsList;
        let isQuestion = addType === "question_add" ? true : false;
        let node = this.addNode(
            currentName
                ? currentName + (isQuestion ? "?" : "")
                : `${nextColumnName(currentColumnNumber)}?`,
            { x: 0, y: 0 },
            [""],
            [""],
            currentColor ? currentColor : "red",
            null,
            !isQuestion,
            isQuestion
        );
        this[activeTab === "AutomaticView" ? "autoGraph" : "manualGraph"].addCells(
            node
        );
        (activeTab === "AutomaticView" || addType === "question_add") &&
            currentQuestionsList.push({
                name: currentName
                    ? currentName
                    : `${nextColumnName(currentColumnNumber)}`,
                value: "",
            });
        this.props.onChanges(true);
        this.setState({
            [activeTab === "AutomaticView"
                ? "currentAutoColumnNumber"
                : "currentManualColumnNumber"]: currentColumnNumber + 1,
            [activeTab === "AutomaticView"
                ? "autoQuestionsList"
                : "manualQuestionsList"]: currentQuestionsList,
        });
        this.hideGraphModal();
    };

    downloadFlowGraph = () => {
        const { activeTab, autoQuestionsList, manualQuestionsList } = this.state;
        this.props.downloadFlowGraph(
            this.refs[
            activeTab === "AutomaticView" ? "autoPaperRef" : "manualPaperRef"
            ],
            activeTab === "AutomaticView" ? autoQuestionsList : manualQuestionsList
        );
    };
    BacktoFile = () => {
        this.props.BacktoFile();
    };
    saveFlowChart = (type) => {
        const { activeTab, autoQuestionsList, manualQuestionsList } = this.state;
        const currentReqData =
            activeTab === "AutomaticView"
                ? {
                    graphData: this.autoGraph,
                    graphType: "autoGraph",
                    questionType: "autoQuestion",
                    questionList: autoQuestionsList,
                }
                : {
                    graphData: this.manualGraph,
                    graphType: "manualGraph",
                    questionType: "manualQuestion",
                    questionList: manualQuestionsList,
                };
        const questionsList =
            currentReqData.questionList.length > 0
                ? {
                    [currentReqData.questionType]: currentReqData.questionList,
                }
                : null;
        let finalRequestData = {
            [currentReqData.graphType]: JSON.stringify(
                currentReqData.graphData.toJSON()
            ),
        };

        if (questionsList) {
            finalRequestData = {
                ...finalRequestData,
                ...questionsList,
            };
        }

        this.props.saveFlowDeed({
            ...finalRequestData,
        });
    };

    handleQuestion = (value, index, updateType) => {
        let { autoQuestionsList, manualQuestionsList, activeTab } = this.state;
        let currentQuestionsList =
            activeTab === "AutomaticView" ? autoQuestionsList : manualQuestionsList;
        currentQuestionsList[index][updateType ? "name" : "value"] = value;
        // Save logics for Questions Action1
        this.props.onChanges(true);
        this.setState({
            [activeTab === "AutomaticView"
                ? "autoQuestionsList"
                : "manualQuestionsList"]: currentQuestionsList,
        });
    };

scrollToTop = () => {
    window.scrollBy({ top: -100, behavior: 'smooth' });
  };
  scrollToBottom = () => {
    window.scrollBy({ top: 100, behavior: 'smooth' });
  };
    

    render() {
        const {
            currentAutoColumnNumber,
            currentManualColumnNumber,
            autoQuestionsList,
            manualQuestionsList,
            isManualViewShown,
            colorCountData,
            isColorCountViewShown,
            activeTab,
            selectedCurrentView,
            isGraphModalShown,
            isManualDataAvailable,
            isManualClicked,
            isQuestionsExpanded,
            isFlowChartExpanded,
            activeMainTab,
        } = this.state;
        let currentQuestionsList =
            activeTab === "AutomaticView" ? autoQuestionsList : manualQuestionsList;
        let currentColumnNumber =
            activeTab === "AutomaticView"
                ? currentAutoColumnNumber
                : currentManualColumnNumber;
        return (
            <div>
                <Accordion className="mainAccordion">
                    <Tabs
                        defaultActiveKey="FlowChartKey"
                        style={{ padding: 0 }}
                        onSelect={(name) => {
                            this.setState(
                                { activeMainTab: name }
                                // () => {
                                //   if (name === "ManualView" && !isManualClicked) {
                                //     this.setInitialManualPaperView();
                                //   }
                                // }
                            );
                        }}
                    >
                        <Tab eventKey="FlowChartKey" title="">
                            {/* <div className="row chartrow" style={{padding:"5%", margin:"0 5% 0 8%"}}> */}
                                {/* <div className="col-md-6 col-6"> */}
                                {/* <div className="col-md-6 col-sm-12">
                                    <h4>Flowchart</h4>
                                        <button className="fixed-arrow up" onClick={this.scrollToTop}>
                                         ↑
                                        </button>
                                        <button className="fixed-arrow down" onClick={this.scrollToBottom}>
                                         ↓
                                        </button>
                                    <div id="graphData1" ref="manualPaperRef" />

                                </div> */}
                                {/* <div className="col-md-6 col-6"> */}
                                {/* <div className="col-md-6 col-sm-12">
                                    <h4>Audio</h4>
                                    <div className="container">
                                        <div
                                            className="row audio_div"
                                            style={{
                                                padding: "0px 20px",
                                                display: "block",
                                                marginTop:"5%",
                                            }}
                                        >
                                            {this.state.audioList &&
                                                this.state.audioList.length > 0 ?
                                                this.state.audioList.map((audio, audioIndex) => {
                                                    return (
                                                        <div key={audio.created_by + audioIndex}>
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        fontSize: "16px",
                                                                    }}
                                                                >
                                                                    {audioIndex + 1}.
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        marginLeft: "5px",
                                                                        display: "flex",
                                                                        flexDirection: "row",
                                                                    }}
                                                                >
                                                                    <div>{audio.created_by}</div>
                                                                    <div
                                                                        style={{
                                                                            marginLeft: "15px",
                                                                        }}
                                                                    >
                                                                        {audio.created_dt}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    alignItems: "left",
                                                                }}
                                                            >
                                                                <audio controls src={audio.audio_base} /> */}
                                                                {/* <div
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        marginLeft: "10px",
                                                                    }}
                                                                    onClick={() => {
                                                                        deleteAudio(audio.audio_id);
                                                                    }}
                                                                >
                                                                    <img src={DeleteIcon} />
                                                                </div> */}
                                                            {/* </div>
                                                        </div>
                                                    );
                                                })
                                                : <div style={{
                                                    textAlign: 'left',
                                                    width: '100%',
                                                    fontSize: '14px'
                                                }}>
                                                    No Recordings found
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div> */}


<div className="row chartrow" style={{ padding: "5%", margin: "0 5% 0 8%" }}>
  {/* Using Ternary Operator for Tab Navigation */}
  {window.innerWidth <= 768 ? (
    <div className="col-12">
      {/* <Tabs defaultActiveKey="flowchart" id="flowchart-audio-tabs" className="d-block d-sm-none"> */}
      <Tabs 
  defaultActiveKey="flowchart" 
  id="flowchart-audio-tabs" 
  className="custom-tabs" // Apply custom class for styling
>
        <Tab eventKey="flowchart" title="Flowchart">
          {/* Flowchart Content */}
          <h4>Flowchart</h4>
          <button className="fixed-arrow up" onClick={this.scrollToTop}>↑</button>
          <button className="fixed-arrow down" onClick={this.scrollToBottom}>↓</button>
          <div id="graphData1" ref="manualPaperRef" />
        </Tab>
        <Tab eventKey="audio" title="Audio">
          {/* Audio Content */}
          <h4>Audio</h4>
          <div className="full-page-container">
          <div className="container">
            <div className="row audio_div" style={{ padding: "0px 20px", display: "block", marginTop: "5%" }}>
              {this.state.audioList && this.state.audioList.length > 0 ?
                this.state.audioList.map((audio, audioIndex) => (
                  <div key={audio.created_by + audioIndex}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div style={{ fontSize: "16px" }}>{audioIndex + 1}.</div>
                      <div style={{ marginLeft: "5px", display: "flex", flexDirection: "row" }}>
                        <div>{audio.created_by}</div>
                        <div style={{ marginLeft: "15px" }}>{audio.created_dt}</div>
                      </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "left" }}>
                      <audio controls src={audio.audio_base} />
                    </div>
                  </div>
                )) :
                <div style={{ textAlign: 'left', width: '100%', fontSize: '14px' }}>
                  No Recordings found
                </div>
              }
            </div>
          </div>
          </div>
        </Tab>
      </Tabs>
    </div>
  ) : (
    // Desktop View - Flowchart and Audio in separate columns
    <>
      <div className="col-md-6 col-sm-12">
        <h4>Flowchart</h4>
        <button className="fixed-arrow up" onClick={this.scrollToTop}>↑</button>
        <button className="fixed-arrow down" onClick={this.scrollToBottom}>↓</button>
        <div id="graphData1" ref="manualPaperRef" />
      </div>
      <div className="col-md-6 col-sm-12">
        <h4>Audio</h4>
        <div className="container">
          <div className="row audio_div" style={{ padding: "0px 20px", display: "block", marginTop: "5%" }}>
            {this.state.audioList && this.state.audioList.length > 0 ?
              this.state.audioList.map((audio, audioIndex) => (
                <div key={audio.created_by + audioIndex}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ fontSize: "16px" }}>{audioIndex + 1}.</div>
                    <div style={{ marginLeft: "5px", display: "flex", flexDirection: "row" }}>
                      <div>{audio.created_by}</div>
                      <div style={{ marginLeft: "15px" }}>{audio.created_dt}</div>
                    </div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "left" }}>
                    <audio controls src={audio.audio_base} />
                  </div>
                </div>
              )) :
              <div style={{ textAlign: 'left', width: '100%', fontSize: '14px' }}>
                No Recordings found
              </div>
            }
          </div>
        </div>
      </div>
    </>
  )}
</div>

                            {/* <div style={{ paddingLeft: "10px" }}>
                                {activeTab === "AutomaticView" ||
                                    (this.manualGraph &&
                                        this.manualGraph.attributes &&
                                        this.manualGraph.attributes.cells.length > 0) ||
                                    isManualDataAvailable ? (
                                    <div
                                        className="row"
                                        style={{
                                            padding: "0.5%",
                                            justifyContent: "center",
                                            marginBottom: "20px",
                                            marginTop: "20px",
                                        }}
                                    >
                                        <button
                                            className="btn btn-primary btn-sm circle-class"
                                            id="hideOnDownload"
                                            onClick={() => {
                                                this.setState({
                                                    selectedCurrentView: null,
                                                    isGraphModalShown: true,
                                                });
                                            }}
                                            data-toggle="modal"
                                            data-target="#graphModal"
                                        >
                                            Add Circle
                                        </button>
                                        {activeTab === "ManualView" && (
                                            <button
                                                className="btn btn-primary btn-sm"
                                                id="hideOnDownload"
                                                onClick={() =>
                                                    this.createEmptyNode(null, "question_add")
                                                }
                                            >
                                                Add Question
                                            </button>
                                        )}
                                        <button
                                            className="btn btn-success btn-sm"
                                            id="hideOnDownload"
                                            onClick={() => this.saveFlowChart()}
                                        >
                                            Save Flow
                                        </button>
                                        <button
                                            className="btn btn-success btn-sm"
                                            id="hideOnDownload"
                                            onClick={() => this.downloadFlowGraph()}
                                        >
                                            Download
                                        </button>
                                        <button
                                            className="btn btn-outline"
                                            style={{ border: "1px solid black" }}
                                            onClick={() => this.BacktoFile()}
                                        >
                                            Back
                                        </button>
                                    </div>
                                ) : null}
                                <div className="flowchart-tabs">
                                    <Tabs
                                        defaultActiveKey="ManualView"
                                        style={{ padding: 0 }}
                                        onSelect={(name) => {
                                            this.setState(
                                                { activeTab: name, selectedCurrentView: null }
                                            );
                                        }}
                                    >
                                        <Tab eventKey="ManualView" title="Automatic">
                                            {(this.manualGraph &&
                                                this.manualGraph.attributes &&
                                                this.manualGraph.attributes.cells.length > 0) ||
                                                isManualDataAvailable ? (
                                                <div
                                                >
                                                </div>
                                            ) : (
                                                <div className="container" style={{ paddingTop: "3%" }}>
                                                    <h6 style={{ fontWeight: "bold" }}>
                                                        Total Color Count
                                                    </h6>
                                                    <div className="row">
                                                        {colorCountData &&
                                                            colorCountData.map((colorVal, colorIndex) => {
                                                                return (
                                                                    <div
                                                                        className="col-md-4"
                                                                        style={{ marginTop: 10 }}
                                                                    >
                                                                        <div className="row">
                                                                            <div
                                                                                className="col-md-6"
                                                                                style={{ textTransform: "capitalize" }}
                                                                            >
                                                                                {colorVal.name}
                                                                            </div>
                                                                            <div
                                                                                className="col-md-6"
                                                                                style={{ paddingLeft: 0 }}
                                                                            >
                                                                                <input
                                                                                    type="number"
                                                                                    min={0}
                                                                                    value={colorVal.totalCount}
                                                                                    onChange={(e) =>
                                                                                        this.updateColorCount(
                                                                                            "colorCountData",
                                                                                            colorIndex,
                                                                                            e.target.value,
                                                                                            "count"
                                                                                        )
                                                                                    }
                                                                                    style={{ width: "100%" }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                    </div>
                                                    <div
                                                        style={{ textAlign: "center", paddingTop: "3%" }}
                                                    >
                                                        <button
                                                            className="btn btn-success btn-sm"
                                                            onClick={() => {
                                                                this.setState({
                                                                    isColorCountViewShown: true,
                                                                });
                                                            }}
                                                        >
                                                            Generate
                                                        </button>
                                                    </div>
                                                    {isColorCountViewShown ? (
                                                        <div>
                                                            <h6 style={{ fontWeight: "bold" }}>
                                                                Circle Numbers
                                                            </h6>
                                                            <div className="row">
                                                                {colorCountData.map((colorVal, colorIndex) => {
                                                                    return colorVal.totalCount ? (
                                                                        <div className="col-md-2">
                                                                            <span
                                                                                style={{
                                                                                    fontWeight: "300",
                                                                                    color: "black",
                                                                                    textTransform: "uppercase",
                                                                                }}
                                                                            >
                                                                                {colorVal.name}
                                                                            </span>
                                                                            {colorVal.totalCount &&
                                                                                Array(colorVal.totalCount).fill("")
                                                                                    .length > 0 &&
                                                                                Array(colorVal.totalCount)
                                                                                    .fill("")
                                                                                    .map(
                                                                                        (
                                                                                            colorChildVal,
                                                                                            colorChildIndex
                                                                                        ) => {
                                                                                            return (
                                                                                                <div
                                                                                                    style={{ marginTop: "2%" }}
                                                                                                >
                                                                                                    <input
                                                                                                        type="number"
                                                                                                        min={0}
                                                                                                        value={
                                                                                                            colorCountData[colorIndex]
                                                                                                                .numbers[
                                                                                                            colorChildIndex
                                                                                                            ]
                                                                                                        }
                                                                                                        onChange={(e) =>
                                                                                                            this.updateColorCount(
                                                                                                                "colorCountData",
                                                                                                                colorIndex,
                                                                                                                e.target.value,
                                                                                                                "numberInput",
                                                                                                                colorChildIndex
                                                                                                            )
                                                                                                        }
                                                                                                        style={{ width: "100%" }}
                                                                                                    />
                                                                                                </div>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                        </div>
                                                                    ) : null;
                                                                })}
                                                            </div>
                                                            <div
                                                                style={{
                                                                    textAlign: "center",
                                                                    paddingTop: "3%",
                                                                }}
                                                            >
                                                                <button
                                                                    className="btn btn-success btn-sm"
                                                                    onClick={() => {
                                                                        this.setState(
                                                                            {
                                                                                isColorCountViewShown: false,
                                                                                isManualViewShown: false,
                                                                            },
                                                                            () => {
                                                                                this.generateManualFlowGraph();
                                                                            }
                                                                        );
                                                                    }}
                                                                >
                                                                    Create Flow Chart
                                                                </button>
                                                            </div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            )}
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div> */}
                        </Tab>
                        {/* <Tab eventKey="RecordingsKey" title="Recordings">
              <div>
                <CustomAudioRecorder
                  fileId={this.props.fileId}
                  user_idfk={this.props.user_idfk}
                />
              </div>
            </Tab> */}
                    </Tabs>
                </Accordion>
                {/* {currentQuestionsList && currentQuestionsList.length > 0 ? (
                    <Accordion>
                        <Card>
                            <Accordion.Toggle
                                as={Card.Header}
                                eventKey="7"
                                onClick={() => {
                                    this.setState({ isQuestionsExpanded: !isQuestionsExpanded });
                                }}
                            >
                                <div className="row">
                                    <div className="col-md-10">
                                        <h5 style={{ margin: "2%" }}>
                                            <b>Questions</b>
                                        </h5>
                                    </div>
                                    <div className="col-md-2">
                                        <i
                                            className={`feather icon-${isQuestionsExpanded ? "minus" : "plus"
                                                }`}
                                            style={{
                                                color: "black",
                                                fontSize: "20px",
                                                position: "relative",
                                                top: "5px",
                                            }}
                                        />
                                    </div>
                                </div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="7">
                                <Card.Body>
                                    <div style={{ marginBottom: "3%" }}>
                                        {currentQuestionsList.map((question, questIndex) => {
                                            return (
                                                <div
                                                    key={questIndex}
                                                    className="row"
                                                    style={{
                                                        alignItems: "center",
                                                        textAlign: "center",
                                                        marginTop: "1.5%",
                                                    }}
                                                >
                                                    <div
                                                        className="col-md-2"
                                                        style={{ fontWeight: "bold" }}
                                                    >
                                                        {question.name}
                                                    </div>
                                                    <div className="col-md-10">
                                                        <textarea
                                                            name="qustion"
                                                            onChange={(e) =>
                                                                this.handleQuestion(e.target.value, questIndex)
                                                            }
                                                            className="form-control"
                                                            value={question.value}
                                                            placeholder="Question"
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                ) : null}

                {isGraphModalShown && (
                    <GraphModal
                        selectedNode={selectedCurrentView}
                        updateChange={this.updateGraphNodes}
                        createNode={this.createEmptyNode}
                        hideGraphModal={this.hideGraphModal}
                    />
                )} */}
            </div>
        );
    }
}

export default Flowchartaudio;
