import React, { Component } from "react";
import isEmpty from 'lodash/isEmpty';
export interface ScheduleDeed {
  schedule_id?: number;
  schedule?: string;
  alloted_to?: string;
  current_survey?: string;
  extent?: string;
}
interface AllProps {
  stateToProps: any;
  scheduleDeed: ScheduleDeed[];
  required: any;
  // selectedTemplate: any;
  // templates: any;
}
export default class ScrutinyScheduleItem extends Component<AllProps> {
  state = {
    schedule_deed: this.props.scheduleDeed,
    delete_items_schedule: [],
    required: this.props.required
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.scheduleDeed && this.state.schedule_deed.length) {
      this.setState({ schedule_deed: nextProps.scheduleDeed });
    } else if (!this.state.schedule_deed || !this.state.schedule_deed.length) {
      this.setState({ schedule_deed: [] });
      this.addItem();
    }
  }
  componentDidUpdate(prevProps: AllProps, prevState: any) {
    if (prevProps.required !== this.props.required) {
      this.setState({ required: this.props.required });
    }
    if (prevState.required !== this.state.required) {
      console.log("required has been updated:", this.state.required);
    }
  }
  addItem = (): void => {
    const schedule_deed: ScheduleDeed[] = this.state.schedule_deed ? this.state.schedule_deed : [];
    schedule_deed.push({
      schedule: "",
      alloted_to: "",
      current_survey: "",
      extent: ""
    });
    this.setState({ schedule_deed });
  };
  removeItem = (modal: ScheduleDeed, index: number): void => {
    const schedule_deed: ScheduleDeed[] = this.state.schedule_deed;
    schedule_deed.splice(index, 1);

    const delete_items_schedule: any = this.state.delete_items_schedule;
    delete_items_schedule.push(modal.schedule_id);
    this.setState({ schedule_deed, delete_items_schedule });

    this.props.stateToProps({
      name: "delete_items_schedule",
      value: this.state.delete_items_schedule
    });
  };
  handleChange = (e: any, index: number) => {
    const { name, value } = e.target;
    const schedule_deed: ScheduleDeed[] = this.state.schedule_deed;
    schedule_deed.forEach((itm: any, i) => {
      if (i === index) {
        itm[name] = value;
      }
    });
    const isRequiredFieldsFilled = schedule_deed.every((item) => {
      return (
        item.schedule?.trim() !== "" &&
        item.alloted_to?.trim() !== "" &&
        item.current_survey?.trim() !== "" &&
        item.extent?.trim() !== ""
      );
    });
    this.setState({ schedule_deed, required: !isRequiredFieldsFilled });
    this.props.stateToProps({
      name: "schedule_deed",
      value: this.state.schedule_deed
    });
  };
  render() {
    return this.state.schedule_deed && this.state.schedule_deed.length
      ? this.state.schedule_deed.map((sched: ScheduleDeed, index) => {
        return (
          <div className="col-md-12" key={index}>
            <div className="col-md-12 sec_measurement">
              <div className="row">
              {/* console.log(sched); */}
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Schedule<span style={{ color: "#FF0000" }}>*</span><span className="inputEgMsg">Eg. A [or] 1st Item </span></label>
                    <input
                      className={this.state.required && isEmpty(sched.schedule?.trim()) ? 'form-control is-invalid' : 'form-control'}
                      name="schedule"
                      type="text"
                      value={sched.schedule}
                      onChange={e => this.handleChange(e, index)}
                      required
                    />
                    {this.state.required && isEmpty(sched.schedule?.trim()) && (
                      <div className="invalid-feedback">This is a required field, kindly add schedule here.</div>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label id="select_east">Alloted To <span style={{ color: "#FF0000" }}>*</span><span className="inputEgMsg">Eg. Mr.Sam [or] Smt.Naveena </span></label>
                    <input
                      className={this.state.required && isEmpty(sched.alloted_to?.trim()) ? 'form-control is-invalid' : 'form-control'}
                      name="alloted_to"
                      value={sched.alloted_to}
                      type="text"
                      onChange={e => this.handleChange(e, index)}
                      required
                    />
                    {this.state.required && isEmpty(sched.alloted_to?.trim()) && (
                      <div className="invalid-feedback">This is a required field, kindly add alloted to here.</div>
                    )}
                  </div>
                </div>
                <div className="col-md-2" style={{ top: "28px" }}>
                  <button
                    type="button"
                    className="btn btn-icon btn-rounded btn-primary"
                    onClick={this.addItem}
                  >
                    <i className="feather icon-plus" />
                  </button>
                </div>
                <div className="col-md-2" style={{ top: "28px" }}>
                  <button
                    type="button"
                    className="btn btn-icon btn-rounded btn-danger"
                    disabled={this.state.schedule_deed.length === 1}
                    onClick={() => this.removeItem(sched, index)}
                  >
                    <i className="feather icon-minus" />
                  </button>
                </div>
                {/* {this.props.selectedTemplate && this.props.selectedTemplate == true && (
                  <div className="col-md-4">
                    <div className="form-group">
                      <label id="select_east">Relationship</label>
                      <input
                        className="form-control"
                        name="relationship"
                        type="text"
                        onChange={(e) => this.handleChange(e, index)}
                        required
                      />
                    </div>
                  </div>
                )} */}
                </div>
                <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label id="select_west">Survey No.<span style={{ color: "#FF0000" }}>*</span> <span className="inputEgMsg">2/1A / T.S.No-1/2/ Natham S.No-3/5 & Some Other Properties  </span></label>
                    <input
                      className={this.state.required && isEmpty(sched.current_survey?.trim()) ? 'form-control is-invalid' : 'form-control'}
                      name="current_survey"
                      type="text"
                      value={sched.current_survey}
                      onChange={e => this.handleChange(e, index)}
                      required
                    />
                    {this.state.required && isEmpty(sched.current_survey?.trim()) && (
                      <div className="invalid-feedback">This is a required field, kindly add survey no here.</div>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label id="select_north">Extent<span style={{ color: "#FF0000" }}>*</span> <span className="inputEgMsg">600 Sq.ft/0.50 Acres/25 Cents, etc., </span></label>
                    <input
                      className={this.state.required && isEmpty(sched.extent?.trim()) ? 'form-control is-invalid' : 'form-control'}
                      name="extent"
                      type="text"
                      value={sched.extent}
                      onChange={e => this.handleChange(e, index)}
                      required
                    />
                    {this.state.required && isEmpty(sched.extent?.trim()) && (
                      <div className="invalid-feedback">This is a required field, kindly add extent here.</div>
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="col-md-12 text-center">
                  <div className="row">
                    <div className="col-md-12">
                      <button
                        type="button"
                        className="btn btn-icon btn-rounded btn-primary"
                        onClick={this.addItem}
                      >
                        <i className="feather icon-plus" />
                      </button>
                      <button
                        type="button"
                        className="btn btn-icon btn-rounded btn-danger"
                        disabled={this.state.schedule_deed.length === 1}
                        onClick={() => this.removeItem(sched, index)}
                      >
                        <i className="feather icon-minus" />
                      </button>
                    </div>
                  </div>
                </div> */}
            </div>
          </div>
        );
      })
      : "";
  }
}
